import { HttpParams } from '@angular/common/http';
import { Store } from 'app/store/store';
import { Company } from '../company/company';
import { Status } from '../status/status';

export class PartOrderFilter {
	dtype: string;
	id: number;
	company: Company;
	store: Store;
	statusPartOrder: Status;

	constructor(filter?: PartOrderFilter) {
		if (filter) {
			this.id = filter.id;
			this.dtype = filter.dtype;
			this.company = filter.company;
			this.store = filter.store;
			this.statusPartOrder = filter.statusPartOrder;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.id) {
			httpParams = httpParams.set('id', this.id.toString());
		}
		if (this.company && this.company.id) {
			httpParams = httpParams.set('companyId', this.company.id.toString());
		}
		if (this.store && this.store.id) {
			httpParams = httpParams.set('storeId', this.store.id.toString());
		}
		if (this.statusPartOrder && this.statusPartOrder.id) {
			httpParams = httpParams.set('statusPartOrderId', this.statusPartOrder.id.toString());
		}
		return httpParams;
	}
}
