import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { PartModel } from './partModel';
import { PartModelService } from './partModel.service';
import { NotificationService} from 'app/shared/notification/notification.service';

@Component({
	template: `
		<h3 class="title" i18n="@@create-partModel">Create Part Model</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="create()">
			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-partModel">Part Model data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="code" i18n="@@code">Code</label>
						<input formControlName="code" type="text" autocomplete="off">
						<validator [control]="form.controls['code']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="name" i18n="@@name">Name</label>
						<input formControlName="name" type="text" autocomplete="off">
						<validator [control]="form.controls['name']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="stock" i18n="@@stock">Stock</label>
						<input formControlName="stock" type="text" autocomplete="off">
						<validator [control]="form.controls['stock']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="price" i18n="@@price">Price</label>
						<input formControlName="price" type="text" autocomplete="off">
						<validator [control]="form.controls['price']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="price2" i18n="@@price2">Second price</label>
						<input formControlName="price2" type="text" autocomplete="off">
						<validator [control]="form.controls['price2']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" i18n="@@description">Description</label>
						<textarea formControlName="description"></textarea>
					</div>
				</div>
			</fieldset>
			
			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	`
})
export class PartModelCreateComponent implements OnInit {
	form: FormGroup;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private partModelService: PartModelService,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		this.form = this.partModelService.toFormGroup(new PartModel());
	}

	create() {
		this.partModelService.create(<PartModel> this.form.value).subscribe(partModel => {
			this.notificationService.sucessInsert(partModel.code);
			this.router.navigate([`../${partModel.id}`], {relativeTo: this.activatedRoute});
		}, err => this.notificationService.error(err));
	}
}
