import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BaseService } from 'app/core/base.service';
import { ServiceTask } from './serviceTask';
import { Media } from '../media/media';
import { map, catchError } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable()
export class ServiceTaskService {
	private static readonly BASE_URL: string = BaseService.HOST + '/serviceTasks';

	constructor(
		private http: HttpClient,
		private fb: FormBuilder
	) {
	}

	getById(id: number, params?: HttpParams): Observable<ServiceTask> {
		return this.http.get(ServiceTaskService.BASE_URL + '/' + id, {params: params})
		.pipe(
			map(BaseService.extractData),
			catchError(BaseService.handleError)
		);
	}

	getAll(params: HttpParams): Observable<any> {
		return this.http.get(ServiceTaskService.BASE_URL, {params: params})
			.pipe(catchError(BaseService.handleError));
	}

	create(serviceTask: ServiceTask): Observable<ServiceTask> {
		return this.http.post(ServiceTaskService.BASE_URL, serviceTask)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	creates(serviceTasks: ServiceTask[]): Observable<ServiceTask[]> {
		return this.http.post(ServiceTaskService.BASE_URL, serviceTasks)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	createMedias(id: number, medias: Media[]): Observable<Media[]> {
		return this.http.post(ServiceTaskService.BASE_URL + '/' + id + '/medias', medias)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	update(serviceTask: ServiceTask): Observable<ServiceTask> {
		return this.http.put(ServiceTaskService.BASE_URL + '/' + serviceTask.id, serviceTask)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	updateStatusServiceTask(serviceTask: ServiceTask): Observable<ServiceTask> {
		return this.http.patch(`${ServiceTaskService.BASE_URL}/${serviceTask.id}/statusServiceTask`, serviceTask)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	updateStatusServiceTasks(serviceTasks: ServiceTask[]): Observable<ServiceTask[]> {
		return this.http.patch(ServiceTaskService.BASE_URL + '/statusServiceTask', serviceTasks)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	updateUserEmployee(serviceTask: ServiceTask): Observable<ServiceTask> {
		return this.http.patch(`${ServiceTaskService.BASE_URL}/${serviceTask.id}/userEmployee`, serviceTask)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	delete(id: number): Observable<any> {
		return this.http.delete(ServiceTaskService.BASE_URL + '/' + id)
			.pipe(catchError(BaseService.handleError));
	}

	deletes(serviceTasks: ServiceTask[]): Observable<any> {
		return this.http.post(ServiceTaskService.BASE_URL + '/delete', serviceTasks)
			.pipe(catchError(BaseService.handleError));
	}

	toFormGroup (serviceTask: ServiceTask): FormGroup {
		let formGroup = this.fb.group({
			id: new FormControl(serviceTask.id),
			startDateTime: new FormControl(
				serviceTask.startDateTime ? moment(serviceTask.startDateTime) : undefined,
				Validators.required
			),
			observation: new FormControl(serviceTask.observation),
			serviceRequest: new FormControl(serviceTask.serviceRequest ? {id: serviceTask.serviceRequest.id} : undefined),
			userEmployee: new FormControl(serviceTask.userEmployee),
			statusServiceTask: new FormControl(serviceTask.statusServiceTask, Validators.required)
		});
		return formGroup;
	}
}
