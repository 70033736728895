import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MachineModel } from './machineModel';
import { MachineModelService } from './machineModel.service';
import { NotificationService} from 'app/shared/notification/notification.service';
import { Media } from 'app/media/media';
import { MediaService } from 'app/media/media.service';
import { HttpParams } from '@angular/common/http';

@Component({
	template: `
		<h3 class="title" i18n="@@detail-machineModel">Machine Model Detail</h3>

		<div class="tool-bar-wrap both-side">
			<div class="right row">
				<button class="btn-icon" i18n-title="@@option-update" title="Update" type="button" (click)="update()" *hasPermission="['MachineBrands.update']">
					<i class="material-icons">edit</i>
				</button>
				<button class="btn-icon" i18n-title="@@option-update-images" title="Update images" type="button" (click)="updateImages()" *hasPermission="['MediaController.createMachineModels']">
					<i class="material-icons">collections</i>
				</button>
				<button class="btn-icon" i18n-title="@@option-update-pdfs" title="Update PDF files" type="button" (click)="updatePdfs()" *hasPermission="['MachineModels.update']">
					<i class="material-icons">picture_as_pdf</i>
				</button>
				<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false" *hasPermission="['MachineModels.delete']">
					<i class="material-icons">delete</i>
				</button>
			</div>
		</div>

		<div class="fieldset-wrap">
			<div class="fieldset">
				<div class="legend" i18n="@@data-machineModel">Machine Model data</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@code">Code</span>
						<span>{{machineModel.name || '-'}}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@name">Name</span>
						<span>{{machineModel.comercialName || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@price">Price</span>
						<span>{{machineModel.price || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@description">Description</span>
						<span>{{machineModel.description || '-'}}</span>
					</div>
				</div>
			</div>

			<div class="fieldset">
				<div class="legend" i18n="@@data-machineType">Machine Type data</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@name">Name</span>
						<span>{{machineModel.machineType?.name || '-'}}</span>
					</div>
				</div>
			</div>

			<div class="fieldset">
				<div class="legend" i18n="@@data-machineBrand">Machine Brand data</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@name">Name</span>
						<span>{{machineModel.machineBrand?.name || '-'}}</span>
					</div>
				</div>
			</div>

			<div class="fieldset">
				<div class="legend" i18n="@@images">Images</div>
				<gallery-image-preview [items]="images"></gallery-image-preview>
			</div>

			<div class="fieldset">
				<div class="legend" i18n="@@pdfs">PDF documents</div>
				<div class="field-row">
					<media-pdf-list [items]="pdfs"></media-pdf-list>
				</div>
			</div>
		</div>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">
						Are you sure you want to delete the record?
					</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	`,
	providers: [MediaService]
})

export class MachineModelReadComponent implements OnInit {
	confirmDelete = true;
	machineModel = new MachineModel();
	images: Media[];
	pdfs: Media[];

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private location: Location,
		private machineModelService: MachineModelService,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		this.activatedRoute.params.subscribe(params => {
			let httpParams = new HttpParams()
				.set('collection', '*, machineBrand(id, name), machineType(id, name), machineType(id, name), medias(id, multimediaCDN(*)');

			this.machineModelService.getById(params['machineModelId'], httpParams).subscribe(machineModel => {
				this.machineModel = machineModel;
				this.images = this.machineModel.medias.filter(item => item.multimediaCDN.mimeType.match(/^image.*/));
				this.pdfs = this.machineModel.medias.filter(item => item.multimediaCDN.mimeType === 'application/pdf');
			});
		});
	}

	update() {
		this.router.navigate(['./update'], {relativeTo: this.activatedRoute});
	}

	updateImages() {
		this.router.navigate(['./image/update'], {relativeTo: this.activatedRoute});
	}

	updatePdfs() {
		this.router.navigate(['./pdf/update'], {relativeTo: this.activatedRoute});
	}

	delete() {
		this.machineModelService.delete(this.machineModel.id).subscribe(data => {
			this.notificationService.sucessDelete();
			let url = this.location.path();
			this.router.navigate([url.substring(0, url.lastIndexOf('/'))]);
		}, err => this.notificationService.error(err));
	}
}
