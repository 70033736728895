import { BaseModel } from 'app/core/models/base.model';
import { Status } from 'app/status/status';
import { UserClient } from 'app/userClient/userClient';
import { Contact } from 'app/core/models/contact';
import { CompanyContract } from 'app/companyContract/companyContract';
import { Store } from 'app/store/store';

export class Company extends BaseModel {
	name: string;
	description: string;
	contact: Contact = new Contact();
	statusFinancial: Status;
	userClients: UserClient[];
	companyContracts: CompanyContract[];
	stores: Store[];
}
