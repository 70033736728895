
		<div #calendar style="position: absolute; top: 0; bottom: 0; right: 0; left: 0; margin: 30px;">
			<calendar-ns (getEventsPerMonth)="getEventsPerMonth($event)">
				<ng-template *ngFor="let time of objectKeys(events)" calendarEvent [time]="time">
					<ng-container *ngFor="let userEmployeeId of objectKeys(events[time]); let i = index;">
						<div *ngIf="i <= eventsPerCell-2 || (i === eventsPerCell-1 && objectKeys(events[time]).length === eventsPerCell)" class="event" (click)="seeDetail($event, userEmployeeId, time)">
							<div>{{events[time][userEmployeeId].userEmployee.firstName}} {{events[time][userEmployeeId].userEmployee.lastName[0]}}.</div>
							<div>+{{events[time][userEmployeeId].serviceTasksLength}}</div>
						</div>
					</ng-container>
					<div *ngIf="eventsPerCell > 0 && objectKeys(events[time]).length > eventsPerCell" class="event read-more" (click)="seeMore($event, time)">{{objectKeys(events[time]).length - eventsPerCell + 1}} more</div>
					<div *ngIf="eventsPerCell === 0" class="event read-more" (click)="seeMore($event, time)">{{objectKeys(events[time]).length}} more</div>
				</ng-template>
			</calendar-ns>

			<div #cellModal2 id="more-info" style="width: 180px;" [ngStyle]="{'visibility': cellModal.style.visibility, 'top': cellModal.style.top + 'px', 'left': cellModal.style.left + 'px'}" (clickOutside)="cellModal.style.visibility = 'hidden'">
				<ng-container *ngIf="cellModal.time">
					<div class="bar" style="margin-bottom: 10px;">
						<div style="font-size: 16px;">{{dayNames[cellModal.date.getDay()]}} {{cellModal.date.getDate()}}</div>
						<div>
							<button class="btn-icon button-close" i18n-title="@@close" title="Close" type="button" (click)="cellModal.style.visibility = 'hidden'">
								<i class="material-icons">close</i>
							</button>
						</div>
					</div>
					
					<div *ngFor="let userEmployeeId of objectKeys(events[cellModal.time])" class="event" (click)="seeDetail($event, userEmployeeId, cellModal.time)">
						<div>{{events[cellModal.time][userEmployeeId].userEmployee.firstName}} {{events[cellModal.time][userEmployeeId].userEmployee.lastName[0]}}.</div>
						<div>+{{events[cellModal.time][userEmployeeId].serviceTasksLength}}</div>
					</div>
				</ng-container>
			</div>

			<div #detailModal2 id="detail" style="width: 400px;" [ngStyle]="{'visibility': detailModal.style.visibility, 'top': detailModal.style.top + 'px', 'left': detailModal.style.left + 'px'}" (clickOutside)="detailModal.style.visibility = 'hidden'">
				<ng-container *ngIf="detailModal.serviceTasks">
					<div class="bar">
						<div style="font-size: 16px;">{{dayNames[detailModal.date.getDay()]}} {{detailModal.date.getDate()}}</div>
						<div>
							<button class="btn-icon button-close" i18n-title="@@close" title="Close" type="button" (click)="detailModal.style.visibility = 'hidden'">
								<i class="material-icons">close</i>
							</button>
						</div>
					</div>

					<div style="font-size: 24px; margin-bottom: 10px;">{{detailModal.serviceTasks[0].userEmployee.firstName}} {{detailModal.serviceTasks[0].userEmployee.lastName}}</div>
					
					<div style="margin-bottom: 5px; font-size: 12px; color: #a4a4a4; text-align: right;">Total {{detailModal.serviceTasks.length}}</div>

					<div *ngFor="let serviceTask of detailModal.serviceTasks" class="table-item-row" (click)="getServiceTask(serviceTask)">
						<div style="flex: 0 1 30px;">#{{serviceTask.id}}</div>
						<div style="flex-grow: 2;">{{serviceTask.serviceRequest.serviceOrder.store.name}}</div>
						<div style="flex-grow: 1; text-align: right;">{{serviceTask.serviceRequest.serviceType.name}}</div>
						<div style="flex-grow: 1; text-align: right;" translate>{{serviceTask.statusServiceTask.name}}</div>
					</div>
				</ng-container>
			</div>
		</div>
	