import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { ServiceOrderComponent } from './serviceOrder.component';
import { ServiceOrderListComponent } from './serviceOrder-list.component';
import { ServiceOrderReadComponent } from './serviceOrder-read.component';
import { ServiceOrderCreateComponent } from './serviceOrder-create.component';
import { ServiceOrderService } from './serviceOrder.service';
import { dashboardRouting } from '../dashboard/dashboard.routes';
import { ContextMenuModule } from 'ngx-contextmenu';
import { ServiceRequestModule } from 'app/serviceRequest/serviceRequest.module';
import { ServiceOrderCalendarComponent } from './serviceOrder-calendar.component';
import { CalendarComponent } from 'app/core/calendar-ns/calendar-ns.component';
import { CalendarEventDirective } from 'app/core/calendar-ns/calendarEventDirective';
import { MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule, MatExpansionModule, MatSelectModule } from '@angular/material';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		dashboardRouting,
		ReactiveFormsModule,
		ServiceRequestModule,
		ContextMenuModule,
		MatTableModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule,
		MatExpansionModule,
		MatSelectModule
	],
	declarations: [
		ServiceOrderComponent,
		ServiceOrderListComponent,
		ServiceOrderReadComponent,
		ServiceOrderCreateComponent,
		ServiceOrderCalendarComponent,
		CalendarComponent,
		CalendarEventDirective
	],
	exports: [
		CalendarComponent,
		CalendarEventDirective
	],
	providers: [
		ServiceOrderService
	],
})
export class ServiceOrderModule { }
