import './polyfills.ts';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode, TRANSLATIONS, TRANSLATIONS_FORMAT, MissingTranslationStrategy } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app';

if (environment.production) {
	enableProdMode();
}

// platformBrowserDynamic().bootstrapModule(AppModule);

/*
* reference i18n in JIT compiler (Note: Angular 5): https://angular.io/guide/i18n#merge-with-the-jit-compiler
*/
// use the require method provided by webpack
declare const require;
// we use the webpack raw-loader to return the content as a string
const translations = require(`raw-loader!./locale/messages.es.xlf`);

platformBrowserDynamic().bootstrapModule(AppModule, {
	// missingTranslation: MissingTranslationStrategy.Error, // when a translation is missing -> AOT fail build / JIT fail to load
	providers: [
		{provide: TRANSLATIONS, useValue: translations},
		{provide: TRANSLATIONS_FORMAT, useValue: 'xlf'}
	]
});
