import { Directive, ElementRef, Input, TemplateRef } from '@angular/core';

@Directive({
	selector: '[calendarEvent]'
})
export class CalendarEventDirective {
	@Input() time: number;

	constructor(public template: TemplateRef<any>, public elementRef: ElementRef) {}
}
