import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { PartRequestComponent } from './partRequest.component';
import { PartRequestListComponent } from './partRequest-list.component';
import { PartRequestReadComponent } from './partRequest-read.component';
import { PartRequestCreateComponent } from './partRequest-create.component';
import { PartRequestService } from './partRequest.service';
import { dashboardRouting } from '../dashboard/dashboard.routes';
import { ContextMenuModule } from 'ngx-contextmenu';
import { PartRequestUpdateComponent } from './partRequest-update.component';
import { MatTableModule, MatSortModule, MatCheckboxModule } from '@angular/material';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		dashboardRouting,
		FormsModule,
		ReactiveFormsModule,
		ContextMenuModule,
		MatTableModule,
		MatSortModule,
		MatCheckboxModule
	],
	declarations: [
		PartRequestComponent,
		PartRequestListComponent,
		PartRequestReadComponent,
		PartRequestCreateComponent,
		PartRequestUpdateComponent
	],
	exports: [
		PartRequestListComponent
	],
	providers: [
		PartRequestService
	]
})
export class PartRequestModule { }
