import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ServiceType } from './serviceType';
import { ServiceTypeService } from './serviceType.service';
import { NotificationService} from 'app/shared/notification/notification.service';
import { MachineTypeService } from 'app/machineType/machineType.service';
import { MachineType } from 'app/machineType/machineType';
import { HttpParams } from '@angular/common/http';

@Component({
	template: `
		<h3 class="title" i18n="@@update-serviceType">Update Service Type</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="update()">
			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-serviceType">Service Type data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="name" i18n="@@name">Name</label>
						<input formControlName="name" type="text" autocomplete="off">
						<validator [control]="form.controls['name']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="price" i18n="@@price">Price</label>
						<input formControlName="price" type="text" autocomplete="off">
						<validator [control]="form.controls['price']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="name" i18n="@@workedTime">Worked time</label>
						<input formControlName="workedTime" type="text" autocomplete="off">
						<validator [control]="form.controls['workedTime']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="machineTypes" i18n="@@machineTypes">Machine Models</label>
						<dropdown-multi-ns [(control)]="form.controls['machineTypes']">
							<ng-template *ngFor="let object of machineTypes" dropdownOption [value]="{id: object.id}">{{object.name || '-'}}</ng-template>
						</dropdown-multi-ns>
						<validator [control]="form.controls['machineTypes']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" i18n="@@description">Description</label>
						<textarea formControlName="description"></textarea>
					</div>
				</div>
			</fieldset>

			<div class="options row">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	`
})
export class ServiceTypeUpdateComponent implements OnInit {
	form: FormGroup;
	machineTypes: MachineType[];

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private serviceTypeService: ServiceTypeService,
		private machineTypeService: MachineTypeService,
		private notificationService: NotificationService,
	) {}

	ngOnInit() {
		this.activatedRoute.parent.params.subscribe(params => {
			this.serviceTypeService.getById(params['serviceTypeId']).subscribe(serviceType => {

				this.machineTypeService.getAll(
					new HttpParams()
						.set('collection', 'id')
						.set('serviceTypeId', params['serviceTypeId'])
				).subscribe(result => {
					serviceType.machineTypes = result['result'];
					this.form = this.serviceTypeService.toFormGroup(serviceType);
				});
			});
		});

		this.machineTypeService.getAll(
			new HttpParams().set('sort', 'name').set('collection', 'id, name')
		).subscribe(data => {
			this.machineTypes = data['result'];
		});
	}

	update() {
		this.serviceTypeService.update(<ServiceType> this.form.value)
			.subscribe(serviceType => {
				this.notificationService.sucessUpdate(serviceType.name);
				this.router.navigate([`../`], {relativeTo: this.activatedRoute});
			}, err => this.notificationService.error(err));
	}
}