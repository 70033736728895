import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Shipment } from './shipment';
import { ShipmentService } from './shipment.service';
import { NotificationService} from 'app/shared/notification/notification.service';
import { HttpParams } from '@angular/common/http';

@Component({
	template: `
		<h3 class="title" i18n="@@detail-shipment">Shipment Detail</h3>

		<div class="tool-bar-wrap both-side">
			<ng-template [hasPermission]="['Shipments.delete']">
				<div *ngIf="shipment && (shipment.current === null || shipment.current === true)" class="right row">
					<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false">
						<i class="material-icons">delete</i>
					</button>
				</div>
			</ng-template>
		</div>

		<div class="fieldset-wrap" *ngIf="shipment">
			<div class="fieldset">
				<div class="legend" i18n="@@data-shipment">Shipment data</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@tracking">Tracking</span>
						<span>{{shipment.tracking || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@description">Description</span>
						<span>{{shipment.description || '-'}}</span>
					</div>
				</div>
			</div>
		</div>

		<shipmentStage-list *ngIf="shipment" [shipment]="shipment"></shipmentStage-list>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">
						Are you sure you want to delete the record?
					</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	`
})
export class ShipmentReadComponent implements OnInit {
	confirmDelete = true;
	shipment: Shipment;

	constructor(
		private activatedRoute: ActivatedRoute,
		private location: Location,
		private shipmentService: ShipmentService,
		private notificationService: NotificationService,
	) {}

	ngOnInit() {
		this.activatedRoute.params.subscribe(params => {
			this.shipmentService.getById(
				params['shipmentId'],
				new HttpParams().set('collection', 'id, tracking, current, description')
			).subscribe(shipment => {
				this.shipment = shipment;
			});
		});
	}

	delete(this) {
		this.shipmentService.delete(this.shipment.id).subscribe(data => {
			this.notificationService.sucessDelete();
			this.location.back();
		}, err => this.notificationService.error(err));

	}
}
