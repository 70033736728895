
		<div class="fieldset" style="margin-bottom: 30px">
			<div class="tool-bar-wrap both-side">
				<div class="legend" style="margin-bottom:10px" i18n="@@shipments-toDo" >Shipments pending</div>
				<div class="right">
					<ng-template [hasPermission]="['Shipments.create']">
						<button *ngIf="partOrder.statusPartOrder.id === 'po_approved' && tableService.selection.hasValue()" class="btn-icon" type="button" (click)="dialogShipmentCreate = false;" i18n-title="@@option-ship" title="ship">
							<i class="material-icons">local_shipping</i>
						</button>
					</ng-template>
				</div>
			</div>

			<div class="mat-elevation-z8e" *ngIf="dataSourcePR && dataSourcePR.data && dataSourcePR.data.length > 0; else empty">
				<table mat-table [dataSource]="dataSourcePR" matSort (matSortChange)="tableService.sortData($event); getPartOrders()">
					<ng-container matColumnDef="select">
						<th mat-header-cell *matHeaderCellDef>
							<div *ngIf="partOrder.statusPartOrder.id === 'po_approved'">
								<mat-checkbox (change)="tableService.masterToggle(dataSourcePR.data)"
									[checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSourcePR.data.length)"
									[indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSourcePR.data.length)">
								</mat-checkbox>
							</div>
						</th>
						<td mat-cell *matCellDef="let row">
							<div *ngIf="partOrder.statusPartOrder.id === 'po_approved'">
								<mat-checkbox (click)="$event.stopPropagation()"
									(change)="tableService.selection.toggle(row)"
									[checked]="tableService.selection.isSelected(row)">
								</mat-checkbox>
							</div>
						</td>
					</ng-container>

					<ng-container matColumnDef="id">
						<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@id">Id</th>
						<td mat-cell *matCellDef="let element">{{element.id}}</td>
					</ng-container>

					<ng-container matColumnDef="partModel">
						<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@partModel-name">Part Model - name</th>
						<td mat-cell *matCellDef="let element">{{element.partModel?.name}}</td>
					</ng-container>

					<ng-container matColumnDef="quantity">
						<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@quantity">Quantity</th>
						<td mat-cell *matCellDef="let element">{{element.quantity}}</td>
					</ng-container>

					<ng-container matColumnDef="statusPartOrder">
						<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@status">Status</th>
						<td mat-cell *matCellDef="let element" translate>{{element.statusShipmentId}}</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns;"
					(contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row">
					</tr>
				</table>
			</div>
		</div>

		<ng-template #empty>
			<div class="table-empty" i18n="@@no-records">Records not found</div>
		</ng-template>

		<context-menu #basicMenu>
			<ng-template contextMenuItem (execute)="dialogShipmentCreate = false;">
				<div class="item">
					<i class="material-icons">local_shipping</i>
					<span i18n="@@option-ship">Ship</span>
				</div>
			</ng-template>
		</context-menu>

		<modal-ns [(closed)]="dialogShipmentCreate">
			<ng-template modalContentDirective>
				<div class="dialog-content" style="width:500px;">
					<shipment-create
						[partRequests]="tableService.selection.selected"
						(list)="list()">
					</shipment-create>
				</div>
			</ng-template>
		</modal-ns>

		<div class="fieldset">
			<div class="legend" style="margin-bottom:10px" i18n="@@shipments">Shipments</div>

			<div class="shipment-wrap" *ngIf="shipments && shipments.length > 0; else empty">
				<div *ngFor="let item of shipments" class="shipment-container" (click)="read(item)">
					<div class="body both-side">
						<div class="tracking-container">
							<span class="label" i18n="@@tracking">Tracking number</span>
							<span>{{item.tracking}}</span>
						</div>

						<div class="right row" *hasPermission="['ShipmentStages.create']">
							<dropdown-ns
								[value]="{id: item.statusShipmentId}"
								(changed)="updateStatusShipment($event)"
								(click)="$event.stopPropagation(); shipmentsSelected = item"
								[disabled]="!(partOrder.statusPartOrder.id === 'po_approved' && (item.current === null || item.current === true))">
								<ng-template *ngIf="shipment_draft(item)" dropdownOption [value]="{id: 'shipment_draft'}">
									<span i18n="@@shipment_draft">Draft</span>
								</ng-template>
								<ng-template *ngIf="shipment_in_transit(item, 'selector')" dropdownOption [value]="{id: 'shipment_in_transit'}">
									<span i18n="@@shipment_in_transit">In transit</span>
								</ng-template>
								<ng-template *ngIf="shipment_delivered(item, 'selector')" dropdownOption [value]="{id: 'shipment_delivered'}">
									<span i18n="@@shipment_delivered">Delivered</span>
								</ng-template>
								<ng-template *ngIf="shipment_in_return(item)" dropdownOption [value]="{id: 'shipment_in_return'}">
									<span i18n="@@shipment_in_return">In return</span>
								</ng-template>
								<ng-template *ngIf="shipment_returned(item, 'selector')" dropdownOption [value]="{id: 'shipment_returned'}">
									<span i18n="@@shipment_returned">Returned</span>
								</ng-template>
								<ng-template *ngIf="shipment_lost(item)" dropdownOption [value]="{id: 'shipment_lost'}">
									<span i18n="@@shipment_lost">Lost</span>
								</ng-template>
							</dropdown-ns>

							<button *ngIf="shipment_in_transit(item, 'button')"
								class="btn-status in_transit"
								(click)="$event.stopPropagation(); this.shipmentsSelected = item; updateStatusShipment({id: 'shipment_in_transit'})"
								[disabled]="!(partOrder.statusPartOrder.id === 'po_approved' && (item.current === null || item.current === true))"
								i18n="@@shipment_in_transit">In transit</button>
							<button *ngIf="shipment_delivered(item, 'button')"
								class="btn-status delivered"
								(click)="$event.stopPropagation(); this.shipmentsSelected = item; updateStatusShipment({id: 'shipment_delivered'})"
								[disabled]="!(partOrder.statusPartOrder.id === 'po_approved' && (item.current === null || item.current === true))"
								i18n="@@shipment_delivery">Delivery</button>
							<button *ngIf="shipment_returned(item, 'button')"
								class="btn-status returned"
								(click)="$event.stopPropagation(); this.shipmentsSelected = item; updateStatusShipment({id: 'shipment_returned'})"
								[disabled]="!(partOrder.statusPartOrder.id === 'po_approved' && (item.current === null || item.current === true))"
								i18n="@@shipment_returned">returned</button>

							<ng-template [hasPermission]="['Shipments.delete']">
								<button *ngIf="!item.shipmentItems || item.shipmentItems.length === 0 || (item.current === true && partOrder.statusPartOrder.id === 'po_approved')" class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="$event.stopPropagation(); confirmDelete = false; shipmentsSelected = item">
									<i class="material-icons">delete</i>
								</button>
							</ng-template>
						</div>
					</div>

					<table mat-table [dataSource]="item.shipmentItems">
						<ng-container matColumnDef="id">
							<th mat-header-cell *matHeaderCellDef i18n="@@id">Id</th>
							<td mat-cell *matCellDef="let element">{{element.partRequest.id}}</td>
						</ng-container>

						<ng-container matColumnDef="partModel">
							<th mat-header-cell *matHeaderCellDef i18n="@@partModel-name">Part Model - name</th>
							<td mat-cell *matCellDef="let element">{{element.partRequest?.partModel.name}}</td>
						</ng-container>

						<ng-container matColumnDef="quantity">
							<th mat-header-cell *matHeaderCellDef i18n="@@quantity">Quantity</th>
							<td mat-cell *matCellDef="let element">{{element.partRequest.quantity}}</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="displayedColumnsSI"></tr>
						<tr mat-row *matRowDef="let row; columns: displayedColumnsSI;"></tr>
					</table>
				</div>
			</div>
		</div>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">Are you sure you want to delete the record?</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	