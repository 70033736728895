import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from 'app/core/base.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ServiceRecord } from './serviceRecord';
import { map, catchError } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable()
export class ServiceRecordService {
	private static readonly BASE_URL: string = BaseService.HOST + '/serviceRecords';

	constructor(
		private http: HttpClient,
		private fb: FormBuilder
	) {}

	getById(id: number): Observable<ServiceRecord> {
		return this.http.get(ServiceRecordService.BASE_URL + '/' + id)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	getAll(params: HttpParams): Observable<any> {
		return this.http.get(ServiceRecordService.BASE_URL, {params: params})
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	create(serviceRecord: ServiceRecord): Observable<ServiceRecord> {
		return this.http.post(ServiceRecordService.BASE_URL, serviceRecord)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	update(serviceRecord: ServiceRecord): Observable<ServiceRecord> {
		return this.http.put(ServiceRecordService.BASE_URL + '/' + serviceRecord.id, serviceRecord)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	delete(id: number): Observable<any> {
		return this.http.delete(ServiceRecordService.BASE_URL + '/' + id)
			.pipe(catchError(BaseService.handleError));
	}

	deletes(serviceRecords: ServiceRecord[]): Observable<any> {
		return this.http.post(ServiceRecordService.BASE_URL + '/delete', serviceRecords)
			.pipe(catchError(BaseService.handleError));
	}

	toFormGroup(serviceRecord: ServiceRecord): FormGroup {
		let formGroup = this.fb.group({
			id: new FormControl(serviceRecord.id),
			startDateTime: new FormControl(
				serviceRecord.startDateTime ? moment(serviceRecord.startDateTime) : undefined,
				Validators.required
			),
			finishDateTime: new FormControl(
				serviceRecord.finishDateTime ? moment(serviceRecord.finishDateTime) : undefined,
				Validators.required
			),
			description: new FormControl(serviceRecord.description, Validators.required),
			serviceTask: new FormControl(serviceRecord.serviceTask ? {id: serviceRecord.serviceTask.id} : undefined, Validators.required)
		});
		return formGroup;
	}
}
