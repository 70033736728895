import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { ServiceRecordModule } from '../serviceRecord/serviceRecord.module';
import { ServiceTaskComponent } from './serviceTask.component';
import { ServiceTaskListComponent } from './serviceTask-list.component';
import { ServiceTaskCreateComponent } from './serviceTask-create.component';
import { ServiceTaskService } from './serviceTask.service';
import { MachineService } from '../machine/machine.service';
import { StatusServiceTaskService } from '../status/statusServiceTask.service';
import { dashboardRouting } from '../dashboard/dashboard.routes';
import { ServiceTaskReadComponent } from 'app/serviceTask/serviceTask-read.component';
import { ServiceTaskMediaImageComponent } from './serviceTask-media-image.component';
import { ServiceTaskUpdateComponent } from 'app/serviceTask/serviceTask-update.component';
import { MatDatepickerModule, MatNativeDateModule, MatSelectModule } from '@angular/material';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		ServiceRecordModule,
		dashboardRouting,
		FormsModule,
		ReactiveFormsModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatSelectModule
	],
	declarations: [
		ServiceTaskComponent,
		ServiceTaskListComponent,
		ServiceTaskCreateComponent,
		ServiceTaskUpdateComponent,
		ServiceTaskReadComponent,
		ServiceTaskMediaImageComponent
	],
	exports: [
		ServiceTaskListComponent,
		ServiceTaskCreateComponent,
		ServiceTaskUpdateComponent
	],
	providers: [
		ServiceTaskService,
		MachineService,
		StatusServiceTaskService
	],
})
export class ServiceTaskModule {}
