import { HttpParams } from '@angular/common/http';
import { Store } from '../store/store';
import { Status } from 'app/status/status';
import { MachineModel } from '../machineModel/machineModel';
import { MachineBrand } from '../machineBrand/machineBrand';
import { MachineType } from '../machineType/machineType';

export class MachineFilter {
	serial: string;
	store: Store;
	machineModel: MachineModel;
	machineType: MachineType;
	machineBrand: MachineBrand;
	statusMachine: Status;

	constructor(filter?: MachineFilter) {
		if (filter) {
			this.serial = filter.serial;
			this.store = filter.store;
			this.machineModel = filter.machineModel;
			this.machineType = filter.machineType;
			this.machineBrand = filter.machineBrand;
			this.statusMachine = filter.statusMachine;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.serial) {
			httpParams = httpParams.set('serial', this.serial);
		}
		if (this.store && this.store.id) {
			httpParams = httpParams.set('storeId', this.store.id.toString());
		}
		if (this.machineModel && this.machineModel.id) {
			httpParams = httpParams.set('machineModelId', this.machineModel.id.toString());
		}
		if (this.machineType && this.machineType.id) {
			httpParams = httpParams.set('machineTypeId', this.machineType.id.toString());
		}
		if (this.machineBrand && this.machineBrand.id) {
			httpParams = httpParams.set('machineBrandId', this.machineBrand.id.toString());
		}
		if (this.statusMachine && this.statusMachine.id) {
			httpParams = httpParams.set('statusMachineId', this.statusMachine.id.toString());
		}
		return httpParams;
	}
}
