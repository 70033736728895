export class Status {
	id: string;
	name: string;
	description: string;

	constructor();
	constructor(id: string);
	constructor(id?: string) {
		if (id) {
			this.id = id;
			this.name = id;
		}
	}
}
