import { Component, OnInit, Input } from '@angular/core';
import { ShipmentStage } from './shipmentStage';
import { ShipmentStageService } from './shipmentStage.service';
import { NotificationService} from 'app/shared/notification/notification.service';
import { HttpParams } from '@angular/common/http';
import { Shipment } from 'app/shipment/shipment';
import { PageEvent, MatTableDataSource } from '@angular/material';

@Component({
	selector: 'shipmentStage-list',
	template: `
		<h2 class="subtitle" style="margin: 30px 0" i18n="@@history">History</h2>

		<div class="mat-elevation-z8e" *ngIf="dataSource">
			<table mat-table [dataSource]="dataSource">
				<ng-container matColumnDef="statusShipment">
					<th mat-header-cell *matHeaderCellDef i18n="@@status">Status</th>
					<td mat-cell *matCellDef="let element">{{element.statusShipment?.name}}</td>
				</ng-container>

				<ng-container matColumnDef="createdAt">
					<th mat-header-cell *matHeaderCellDef i18n="@@createdAt">Created at</th>
					<td mat-cell *matCellDef="let element">{{element.createdAt}}</td>
				</ng-container>

				<ng-container matColumnDef="action">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let element">
						<ng-template [hasPermission]="['ShipmentStages.delete']">
							<button *ngIf="element.current === null || element.current === true && element.currentShipment" class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="shipmentStage = element; confirmDelete = false">
								<i class="material-icons">delete</i>
							</button>
						</ng-template>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
			</table>
		</div>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">
						Are you sure you want to delete the record?
					</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	`,
})
export class ShipmentStageListComponent implements OnInit {
	@Input() shipment: Shipment;
	dataSource: MatTableDataSource<ShipmentStage>;
	displayedColumns: string[] = ['statusShipment', 'createdAt', 'action'];
	confirmDelete = true;
	shipmentStage: ShipmentStage;

	constructor(
		private shipmentStageService: ShipmentStageService,
		private notificationService: NotificationService,
	) {}

	ngOnInit() {
		this.list();
	}

	list(event?: PageEvent) {
		this.shipmentStageService.getAll(
			new HttpParams()
			.set('shipmentId', this.shipment.id.toString())
			.set('sort', '-updatedAt')
			.set('collection', 'id, description, createdAt, currentShipment, current, statusShipment(id, name)')
		).subscribe(data => {
			this.dataSource = new MatTableDataSource<ShipmentStage>(data['result']);
		});
	}

	delete() {
		this.shipmentStageService.delete(this.shipmentStage.id).subscribe(data => {
			this.notificationService.sucessDelete();
			this.list();
		}, err => this.notificationService.error(err));
	}
}
