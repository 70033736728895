import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from 'app/core/base.service';
import { Store } from './store';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'app/shared/form/custom.validators';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class StoreService {
	public static readonly BASE_URL: string = BaseService.HOST + '/stores';

	constructor(
		private http: HttpClient,
		private fb: FormBuilder
	) {}

	getById(id: number): Observable<Store> {
		return this.http.get(StoreService.BASE_URL + '/' + id)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	getAll(params: HttpParams): Observable<any> {
		return this.http.get(StoreService.BASE_URL, {params: params})
			.pipe(catchError(BaseService.handleError));
	}

	create(store: Store): Observable<Store> {
		return this.http.post(StoreService.BASE_URL, store)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	update(store: Store): Observable<Store> {
		return this.http.put(StoreService.BASE_URL + '/' + store.id, store)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	delete(id: number): Observable<any> {
		return this.http.delete(StoreService.BASE_URL + '/' + id)
			.pipe(catchError(BaseService.handleError));
	}

	deletes(stores: Store[]): Observable<any> {
		return this.http.post(StoreService.BASE_URL + '/delete', stores)
			.pipe(catchError(BaseService.handleError));
	}

	toFormGroup(store: Store): FormGroup {
		return this.fb.group({
			id: new FormControl(store.id),
			name: new FormControl(store.name, [Validators.required, Validators.maxLength(100)]),
			contactName: new FormControl(store.contactName, Validators.maxLength(100)),
			contactPhone: new FormControl(store.contactPhone, Validators.maxLength(100)),
			maintenenceFrecuency: new FormControl(store.maintenenceFrecuency, [CustomValidators.integerRegex, CustomValidators.min(1)]),
			notificationSlack: new FormControl(store.notificationSlack, [CustomValidators.integerRegex, CustomValidators.min(0)]),
			description: new FormControl(store.description),
			contact: this.fb.group({
				phone: new FormControl(store.contact.phone, Validators.maxLength(100)),
				phone2: new FormControl(store.contact.phone2, Validators.maxLength(100)),
				latitude: new FormControl(store.contact.latitude, CustomValidators.numberRegex),
				longitude: new FormControl(store.contact.longitude, CustomValidators.numberRegex),
				address: new FormControl(store.contact.address)
			}),
			company: new FormControl(store.company, Validators.required)
		});
	}
}
