import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from 'app/core/base.service';
import { MachineModel } from './machineModel';
import { Media } from 'app/media/media';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'app/shared/form/custom.validators';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class MachineModelService {
	private static readonly BASE_URL: string = BaseService.HOST + '/machineModels';

	constructor(
		private http: HttpClient,
		private fb: FormBuilder,
	) {}

	getById(id: number, params?: HttpParams): Observable<MachineModel> {
		return this.http.get(MachineModelService.BASE_URL + '/' + id, {params: params})
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	getAll(params: HttpParams): Observable<any> {
		return this.http.get(MachineModelService.BASE_URL, {params: params})
			.pipe(catchError(BaseService.handleError));
	}

	create(machineModel: MachineModel): Observable<MachineModel> {
		return this.http.post(MachineModelService.BASE_URL, machineModel)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	createMedias(id: number, medias: Media[]): Observable<Media[]> {
		return this.http.post(MachineModelService.BASE_URL + '/' + id + '/medias', medias)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	update(machineModel: MachineModel): Observable<MachineModel> {
		return this.http.put(MachineModelService.BASE_URL + '/' + machineModel.id, machineModel)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	delete(id: number): Observable<any> {
		return this.http.delete(MachineModelService.BASE_URL + '/' + id)
			.pipe(catchError(BaseService.handleError));
	}

	deletes(machineModels: MachineModel[]): Observable<any> {
		return this.http.post(MachineModelService.BASE_URL + '/delete', machineModels)
			.pipe(catchError(BaseService.handleError));
	}

	toFormGroup(machineModel: MachineModel): FormGroup {
		return this.fb.group({
			id: new FormControl(machineModel.id),
			name: new FormControl(machineModel.name, [Validators.required, Validators.maxLength(100)]),
			comercialName: new FormControl(machineModel.comercialName, [Validators.required, Validators.maxLength(100)]),
			price: new FormControl(machineModel.price, [CustomValidators.numberRegex, Validators.min(0)]),
			description: new FormControl(machineModel.description),
			machineType: new FormControl(machineModel.machineType, Validators.required),
			machineBrand: new FormControl(machineModel.machineBrand, Validators.required)
		});
	}
}
