import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from 'app/core/base.service';
import { MachineBrand } from './machineBrand';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class MachineBrandService {
	private static readonly BASE_URL: string = BaseService.HOST + '/machineBrands';

	constructor(
		private http: HttpClient,
		private fb: FormBuilder
	) {}

	getById(id: number): Observable<MachineBrand> {
		return this.http.get(MachineBrandService.BASE_URL + '/' + id)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	getAll(params: HttpParams): Observable<any> {
		return this.http.get(MachineBrandService.BASE_URL, {params: params})
			.pipe(catchError(BaseService.handleError));
	}

	create(machineBrand: MachineBrand): Observable<MachineBrand> {
		return this.http.post(MachineBrandService.BASE_URL, machineBrand)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	update(machineBrand: MachineBrand): Observable<MachineBrand> {
		return this.http.put(MachineBrandService.BASE_URL + '/' + machineBrand.id, machineBrand)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	delete(id: number): Observable<any> {
		return this.http.delete(MachineBrandService.BASE_URL + '/' + id)
			.pipe(catchError(BaseService.handleError));
	}

	deletes(machineBrands: MachineBrand[]): Observable<any> {
		return this.http.post(MachineBrandService.BASE_URL + '/delete', machineBrands)
			.pipe(catchError(BaseService.handleError));
	}

	toFormGroup(machineBrand: MachineBrand): FormGroup {
		return this.fb.group({
			id: new FormControl(machineBrand.id),
			name: new FormControl(machineBrand.name, [Validators.required, Validators.maxLength(100)]),
			description: new FormControl(machineBrand.description)
		});
	}
}
