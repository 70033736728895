import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Shipment } from './shipment';
import { ShipmentService } from './shipment.service';
import { NotificationService} from 'app/shared/notification/notification.service';
import { PartModel } from 'app/partModel/partModel';
import { Status } from '../status/status';
import { ShipmentItem } from '../shipmentItem/shipmentItem';
import { ShipmentItemService } from '../shipmentItem/shipmentItem.service';
import { PartOrder } from 'app/partOrder/partOrder';
import { Observable, forkJoin } from 'rxjs';
import { PartRequest } from 'app/partRequest/partRequest';

@Component({
	selector: 'shipment-create',
	template: `
		<h3 class="title" i18n="@@create-shipment">Create Shipment</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="create()">
			<fieldset class="fieldset">
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="tracking" i18n="@@tracking">tracking</label>
						<input formControlName="tracking" type="text" autocomplete="off">
						<validator [control]="form.controls['tracking']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" i18n="@@description">Description</label>
						<textarea formControlName="description"></textarea>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	`
})
export class ShipmentCreateComponent implements OnInit {
	@Input() partRequests: PartRequest[];
	@Output() list = new EventEmitter();
	form: FormGroup;
	partModels: PartModel[];

	constructor(
		private activatedRoute: ActivatedRoute,
		private shipmentService: ShipmentService,
		private shipmentItemService: ShipmentItemService,
		private notificationService: NotificationService,
	) {}

	ngOnInit() {
		this.activatedRoute.parent.params.subscribe(params => {
			if (params.hasOwnProperty('partOrderId')) {
				let shipment = new Shipment();
				shipment.statusShipment = new Status('shipment_draft');
				shipment.partOrder = new PartOrder();
				shipment.partOrder.id = params['partOrderId'];
				this.form = this.shipmentService.toFormGroup(shipment);
			}
		});
	}

	create() {
		this.shipmentService.create(<Shipment> this.form.value).subscribe(shipment => {
			this.notificationService.sucessInsert(shipment.tracking);

			let observables: Observable<ShipmentItem>[] = this.partRequests.map((partRequest: PartRequest) => {
				let shipmentItem = new ShipmentItem();
				shipmentItem.partRequest = partRequest;
				shipmentItem.shipment = shipment;
				return this.shipmentItemService.create(shipmentItem);
			});

			if (observables.length > 0) {
				forkJoin(observables).subscribe(shipmentItems => {
					this.list.emit();
				}, err => {
					this.list.emit();
					this.notificationService.error(err)
				});
			} else {
				this.list.emit();
			}

		}, err => this.notificationService.error(err));
	}
}
