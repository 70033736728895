import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Shipment} from './shipment';
import { NotificationService} from 'app/shared/notification/notification.service';
import { ShipmentService } from './shipment.service';
import { PartRequestService } from 'app/partRequest/partRequest.service';
import { Status } from '../status/status';
import { ShipmentStageService } from '../shipmentStage/shipmentStage.service';
import { ShipmentStage } from '../shipmentStage/shipmentStage';
import { PartOrder } from 'app/partOrder/partOrder';
import { HttpParams } from '@angular/common/http';
import { PageEvent, MatTableDataSource } from '@angular/material';
import { TableService } from '../core/models/pager';

@Component({
	selector: 'shipment-list',
	template: `
		<div class="fieldset" style="margin-bottom: 30px">
			<div class="tool-bar-wrap both-side">
				<div class="legend" style="margin-bottom:10px" i18n="@@shipments-toDo" >Shipments pending</div>
				<div class="right">
					<ng-template [hasPermission]="['Shipments.create']">
						<button *ngIf="partOrder.statusPartOrder.id === 'po_approved' && tableService.selection.hasValue()" class="btn-icon" type="button" (click)="dialogShipmentCreate = false;" i18n-title="@@option-ship" title="ship">
							<i class="material-icons">local_shipping</i>
						</button>
					</ng-template>
				</div>
			</div>

			<div class="mat-elevation-z8e" *ngIf="dataSourcePR && dataSourcePR.data && dataSourcePR.data.length > 0; else empty">
				<table mat-table [dataSource]="dataSourcePR" matSort (matSortChange)="tableService.sortData($event); getPartOrders()">
					<ng-container matColumnDef="select">
						<th mat-header-cell *matHeaderCellDef>
							<div *ngIf="partOrder.statusPartOrder.id === 'po_approved'">
								<mat-checkbox (change)="tableService.masterToggle(dataSourcePR.data)"
									[checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSourcePR.data.length)"
									[indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSourcePR.data.length)">
								</mat-checkbox>
							</div>
						</th>
						<td mat-cell *matCellDef="let row">
							<div *ngIf="partOrder.statusPartOrder.id === 'po_approved'">
								<mat-checkbox (click)="$event.stopPropagation()"
									(change)="tableService.selection.toggle(row)"
									[checked]="tableService.selection.isSelected(row)">
								</mat-checkbox>
							</div>
						</td>
					</ng-container>

					<ng-container matColumnDef="id">
						<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@id">Id</th>
						<td mat-cell *matCellDef="let element">{{element.id}}</td>
					</ng-container>

					<ng-container matColumnDef="partModel">
						<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@partModel-name">Part Model - name</th>
						<td mat-cell *matCellDef="let element">{{element.partModel?.name}}</td>
					</ng-container>

					<ng-container matColumnDef="quantity">
						<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@quantity">Quantity</th>
						<td mat-cell *matCellDef="let element">{{element.quantity}}</td>
					</ng-container>

					<ng-container matColumnDef="statusPartOrder">
						<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@status">Status</th>
						<td mat-cell *matCellDef="let element" translate>{{element.statusShipmentId}}</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns;"
					(contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row">
					</tr>
				</table>
			</div>
		</div>

		<ng-template #empty>
			<div class="table-empty" i18n="@@no-records">Records not found</div>
		</ng-template>

		<context-menu #basicMenu>
			<ng-template contextMenuItem (execute)="dialogShipmentCreate = false;">
				<div class="item">
					<i class="material-icons">local_shipping</i>
					<span i18n="@@option-ship">Ship</span>
				</div>
			</ng-template>
		</context-menu>

		<modal-ns [(closed)]="dialogShipmentCreate">
			<ng-template modalContentDirective>
				<div class="dialog-content" style="width:500px;">
					<shipment-create
						[partRequests]="tableService.selection.selected"
						(list)="list()">
					</shipment-create>
				</div>
			</ng-template>
		</modal-ns>

		<div class="fieldset">
			<div class="legend" style="margin-bottom:10px" i18n="@@shipments">Shipments</div>

			<div class="shipment-wrap" *ngIf="shipments && shipments.length > 0; else empty">
				<div *ngFor="let item of shipments" class="shipment-container" (click)="read(item)">
					<div class="body both-side">
						<div class="tracking-container">
							<span class="label" i18n="@@tracking">Tracking number</span>
							<span>{{item.tracking}}</span>
						</div>

						<div class="right row" *hasPermission="['ShipmentStages.create']">
							<dropdown-ns
								[value]="{id: item.statusShipmentId}"
								(changed)="updateStatusShipment($event)"
								(click)="$event.stopPropagation(); shipmentsSelected = item"
								[disabled]="!(partOrder.statusPartOrder.id === 'po_approved' && (item.current === null || item.current === true))">
								<ng-template *ngIf="shipment_draft(item)" dropdownOption [value]="{id: 'shipment_draft'}">
									<span i18n="@@shipment_draft">Draft</span>
								</ng-template>
								<ng-template *ngIf="shipment_in_transit(item, 'selector')" dropdownOption [value]="{id: 'shipment_in_transit'}">
									<span i18n="@@shipment_in_transit">In transit</span>
								</ng-template>
								<ng-template *ngIf="shipment_delivered(item, 'selector')" dropdownOption [value]="{id: 'shipment_delivered'}">
									<span i18n="@@shipment_delivered">Delivered</span>
								</ng-template>
								<ng-template *ngIf="shipment_in_return(item)" dropdownOption [value]="{id: 'shipment_in_return'}">
									<span i18n="@@shipment_in_return">In return</span>
								</ng-template>
								<ng-template *ngIf="shipment_returned(item, 'selector')" dropdownOption [value]="{id: 'shipment_returned'}">
									<span i18n="@@shipment_returned">Returned</span>
								</ng-template>
								<ng-template *ngIf="shipment_lost(item)" dropdownOption [value]="{id: 'shipment_lost'}">
									<span i18n="@@shipment_lost">Lost</span>
								</ng-template>
							</dropdown-ns>

							<button *ngIf="shipment_in_transit(item, 'button')"
								class="btn-status in_transit"
								(click)="$event.stopPropagation(); this.shipmentsSelected = item; updateStatusShipment({id: 'shipment_in_transit'})"
								[disabled]="!(partOrder.statusPartOrder.id === 'po_approved' && (item.current === null || item.current === true))"
								i18n="@@shipment_in_transit">In transit</button>
							<button *ngIf="shipment_delivered(item, 'button')"
								class="btn-status delivered"
								(click)="$event.stopPropagation(); this.shipmentsSelected = item; updateStatusShipment({id: 'shipment_delivered'})"
								[disabled]="!(partOrder.statusPartOrder.id === 'po_approved' && (item.current === null || item.current === true))"
								i18n="@@shipment_delivery">Delivery</button>
							<button *ngIf="shipment_returned(item, 'button')"
								class="btn-status returned"
								(click)="$event.stopPropagation(); this.shipmentsSelected = item; updateStatusShipment({id: 'shipment_returned'})"
								[disabled]="!(partOrder.statusPartOrder.id === 'po_approved' && (item.current === null || item.current === true))"
								i18n="@@shipment_returned">returned</button>

							<ng-template [hasPermission]="['Shipments.delete']">
								<button *ngIf="!item.shipmentItems || item.shipmentItems.length === 0 || (item.current === true && partOrder.statusPartOrder.id === 'po_approved')" class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="$event.stopPropagation(); confirmDelete = false; shipmentsSelected = item">
									<i class="material-icons">delete</i>
								</button>
							</ng-template>
						</div>
					</div>

					<table mat-table [dataSource]="item.shipmentItems">
						<ng-container matColumnDef="id">
							<th mat-header-cell *matHeaderCellDef i18n="@@id">Id</th>
							<td mat-cell *matCellDef="let element">{{element.partRequest.id}}</td>
						</ng-container>

						<ng-container matColumnDef="partModel">
							<th mat-header-cell *matHeaderCellDef i18n="@@partModel-name">Part Model - name</th>
							<td mat-cell *matCellDef="let element">{{element.partRequest?.partModel.name}}</td>
						</ng-container>

						<ng-container matColumnDef="quantity">
							<th mat-header-cell *matHeaderCellDef i18n="@@quantity">Quantity</th>
							<td mat-cell *matCellDef="let element">{{element.partRequest.quantity}}</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="displayedColumnsSI"></tr>
						<tr mat-row *matRowDef="let row; columns: displayedColumnsSI;"></tr>
					</table>
				</div>
			</div>
		</div>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">Are you sure you want to delete the record?</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	`,
	styles: [`
		.shipment-container{
			padding: 10px;
			background-color: #f7f7f7;
			cursor: pointer;
		}
		.shipment-container:not(:last-child){
			margin-bottom: 20px;
		}PartOrder

		.tracking-container{
			font-size: 14px;
			color: #333;
		}
		.tracking-container .label{
			font-size: 14px;
			font-weight: bold;
			color: #333;
			text-transform: uppercase;
		}
	`]
})
export class ShipmentListComponent implements OnInit {
	@Input() partOrder: PartOrder;
	@Input() hasShipmentInTransit: boolean;
	@Output() hasShipmentInTransitChange = new EventEmitter();
	@Input() hasShipmentInReturn: boolean;
	@Output() hasShipmentInReturnChange = new EventEmitter();
	@Input() hasShipmentDelivered: boolean;
	@Output() hasShipmentDeliveredChange = new EventEmitter();
	shipments: Shipment[];
	dataSourcePR: MatTableDataSource<PartOrder>;
	displayedColumns: string[] = ['select', 'id', 'partModel', 'quantity', 'statusPartOrder'];
	displayedColumnsSI: string[] = ['id', 'partModel', 'quantity'];
	shipmentsSelected: Shipment;
	tableService = new TableService<PartOrder>();
	confirmDelete = true;
	dialogShipmentCreate = true;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private shipmentService: ShipmentService,
		private shipmentStageService: ShipmentStageService,
		private partRequestService: PartRequestService,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		this.tableService.sort = 'createdAt';
		this.list();
	}

	list() {
		this.getPartOrders();
		this.getShipments();
	}

	getShipments(event?: PageEvent) {
		let httpParams = new HttpParams()
			.set('sort', '-createdAt')
			.set('collection', 'id, tracking, statusShipmentId, current, shipmentItems(id, partRequest(id, quantity, partModel(id, name)))')
			.set('partOrderId', this.partOrder.id.toString());
		this.shipmentService.getAll(httpParams).subscribe(data => {
			this.shipments = data['result'];

			this.hasShipmentInTransit = this.shipments.some(shipment => shipment.statusShipmentId === 'shipment_in_transit');
			this.hasShipmentInTransitChange.emit(this.hasShipmentInTransit);

			this.hasShipmentInReturn = this.shipments.some(shipment => shipment.statusShipmentId === 'shipment_in_return');
			this.hasShipmentInReturnChange.emit(this.hasShipmentInReturn);

			this.hasShipmentDelivered = this.shipments.some(shipment => shipment.statusShipmentId === 'shipment_delivered');
			this.hasShipmentDeliveredChange.emit(this.hasShipmentDelivered);
		});

		this.dialogShipmentCreate = true;
	}

	getPartOrders(event?: PageEvent) {
		let httpParams = new HttpParams()
			.set('sort', this.tableService.sort)
			.set('collection', 'id, quantity, statusShipmentId, partModel(id, name)')
			.set('partOrderId', this.partOrder.id.toString());
		this.partRequestService.getAll(httpParams).subscribe(data => {
			let partRequests = data['result'].filter(partRequest =>
				!partRequest.statusShipmentId ||
				partRequest.statusShipmentId === 'shipment_lost' ||
				partRequest.statusShipmentId === 'shipment_returned'
			);
			this.dataSourcePR = new MatTableDataSource<PartOrder>(partRequests);
			this.tableService.selection.clear();
		});
	}

	read(item: Shipment) {
		this.router.navigate(['./shipments/' + item.id], {relativeTo: this.activatedRoute});
	}

	updateStatusShipment(status: Status) {
		if (status.id !== this.shipmentsSelected.statusShipmentId) {
			let shipmentStage = new ShipmentStage();
			shipmentStage.statusShipment = status;
			shipmentStage.shipment = new Shipment();
			shipmentStage.shipment.id = this.shipmentsSelected.id;

			this.shipmentStageService.create(shipmentStage).subscribe(() => {
				this.list();
			}, err => this.notificationService.error(err));
		}
	}

	delete() {
		this.shipmentService.delete(this.shipmentsSelected.id).subscribe(data => {
			this.list();
			this.notificationService.sucessDelete();
		}, err => this.notificationService.error(err));
	}

	shipment_draft(item) {
		return item.statusShipmentId === 'shipment_draft';
	}

	shipment_in_transit(item, type: string) {
		if (type === 'selector') {
			return item.statusShipmentId === 'shipment_draft' || item.statusShipmentId === 'shipment_in_transit';
		} else if (type === 'button') {
			return item.statusShipmentId === 'shipment_draft';
		}
	}

	shipment_delivered(item, type: string) {
		if (type === 'selector') {
			return item.statusShipmentId === 'shipment_delivered' || item.statusShipmentId === 'shipment_in_transit';
		} else if (type === 'button') {
			return item.statusShipmentId === 'shipment_in_transit';
		}
	}

	shipment_in_return(item) {
		return item.statusShipmentId === 'shipment_in_return' || item.statusShipmentId === 'shipment_in_transit';
	}

	shipment_returned(item, type: string) {
		if (type === 'selector') {
			return item.statusShipmentId === 'shipment_returned' || item.statusShipmentId === 'shipment_in_return';
		} else if (type === 'button') {
			return item.statusShipmentId === 'shipment_in_return';
		}
	}

	shipment_lost(item) {
		return item.statusShipmentId === 'shipment_lost' ||
			item.statusShipmentId === 'shipment_in_transit' ||
			item.statusShipmentId === 'shipment_in_return';
	}
}
