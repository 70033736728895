import { HttpParams } from '@angular/common/http';
import { Status } from 'app/status/status';
import { UserClient } from '../userClient/userClient';

export class CompanyFilter {
	name: string;
	statusFinancial: Status;
	userClient: UserClient;

	constructor(filter?: CompanyFilter) {
		if (filter) {
			this.name = filter.name;
			this.statusFinancial = filter.statusFinancial;
			this.userClient = filter.userClient;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.name) {
			httpParams = httpParams.set('name', this.name);
		}
		if (this.statusFinancial && this.statusFinancial.id) {
			httpParams = httpParams.set('statusFinancialId', this.statusFinancial.id);
		}
		if (this.userClient && this.userClient.id) {
			httpParams = httpParams.set('userClientId', this.userClient.id.toString());
		}
		return httpParams;
	}
}