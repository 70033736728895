import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PartModelService } from './partModel.service';
import { Media } from 'app/media/media';
import { MediaService } from 'app/media/media.service';
import { NotificationService} from 'app/shared/notification/notification.service';
import { PartModel } from 'app/partModel/partModel';
import { Global } from 'app/core/global';
import { TableService } from 'app/core/models/pager';
import { PageEvent } from '@angular/material';
import { HttpParams } from '@angular/common/http';

@Component({
	template: `
		<h3 class="title" i18n="@@update-partModel-images">Update Part Model images</h3>

		<div class="tool-bar-wrap both-side">
			<div class="row">
				<div><span i18n="@@total">Total</span> {{items.length}}</div>
				<div><span i18n="@@total">Total</span> selecteds {{tableService.selection.selected.length}}</div>
			</div>
			<div class="right row">
				<ng-template [hasPermission]="['MediaController.createPartModels']">
					<input id="file-upload" type="file" accept="image/png, image/jpeg" (change)="create($event)" multiple>
					<label class="btn-file" for="file-upload">
						<i class="material-icons">add</i>
					</label>
				</ng-template>

				<ng-template [hasPermission]="['MediaController.delete']">
					<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false" *ngIf="tableService.selection.hasValue()">
						<i class="material-icons">delete</i>
					</button>
				</ng-template>
			</div>
		</div>

		<gallery-image [items]="items"></gallery-image>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-deletes">Are you sure you want to delete the selected items?</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="deletes(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	`,
	providers: [MediaService]
})
export class PartModelMediaImageComponent implements OnInit {
	items: Media[] = new Array();
	partModel: PartModel;
	valueKey = 'id';
	confirmDelete = true;

	constructor(
		private activatedRoute: ActivatedRoute,
		private partModelService: PartModelService,
		private mediaService: MediaService,
		public tableService: TableService<Media>,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		this.activatedRoute.parent.params.subscribe(params => {
			this.partModel = new PartModel();
			this.partModel.id = params['partModelId'];
			this.list();
		});
	}

	list(event?: PageEvent) {
		let httpParams = new HttpParams()
			.set('collection', '*, medias(id, multimediaCDN(*))');

		this.partModelService.getById(this.partModel.id, httpParams).subscribe(partModel => {
			this.partModel = partModel;
			this.items = this.partModel.medias.filter(item => item.multimediaCDN.mimeType.match(/^image.*/));
			this.tableService.selection.clear();
		});
	}

	create(event) {
		if (event.target.files && event.target.files.length > 0) {
			let files: FileList = event.target.files;
			let promises = [];

			for (let i = 0; i < files.length; ++i) {
				let file = files.item(i);
				promises.push(new Promise((resolve, reject) => {
					MediaService.getBase64(file).then(data => {
						let media = new Media();
						media.name = file.name.substring(0, file.name.lastIndexOf('.'));
						media.multimediaCDN.mediaBase64 = data.toString();
						resolve(media);
					});
				}));
			}

			Promise.all(promises).then(items => {
				this.partModelService.createMedias(this.partModel.id, items).subscribe(medias => {
					this.notificationService.sucessInsert();
					this.items.push(...medias);
				});
			}, err => this.notificationService.error(err));
		}
	}

	deletes() {
		if (this.tableService.selection.selected.length === 1) {
			let item = this.tableService.selection.selected[0];
			this.mediaService.delete(item.id).subscribe(data => {
				this.notificationService.sucessDelete();
				this.list();
			}, err => this.notificationService.error(err));

		} else if (this.tableService.selection.selected.length > 1) {
			let keys = Global.getProperties(this.tableService.selection.selected, 'id');
			this.mediaService.deletes({'ids': keys}).subscribe(data => {
				this.notificationService.sucessDelete();
				this.list();
			}, err => this.notificationService.error(err));
		}
	}
}
