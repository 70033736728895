
		<h3 class="title" i18n="@@detail-partOrder">Part Order Detail</h3>

		<ng-container *ngIf="partOrder">
			<div class="tool-bar-wrap both-side">
				<div class="right row">
					<ng-template [hasPermission]="['PartOrders.findAll']">
						<button class="btn-icon-text" [ngClass]="{selected: !shipmentListView}" type="button" (click)="shipmentListView = false">
							<span i18n="@@partRequests">Parts</span>
						</button>
					</ng-template>
					<ng-template [hasPermission]="['Shipments.findAll']">
						<button class="btn-icon-text" [ngClass]="{selected: shipmentListView}" type="button" (click)="shipmentListView = true">
							<span i18n="@@shipments">Shipments</span>
						</button>
					</ng-template>
					<ng-template [hasPermission]="['PartOrders.delete']">
						<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false">
							<i class="material-icons">delete</i>
						</button>
					</ng-template>
				</div>
			</div>

			<div class="fieldset-wrap both-side">
				<div class="fieldset">
					<div class="legend" i18n="@@data-partOrder">Part Order data</div>
					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@id">Id</span>
							<span>{{partOrder.id || '-'}}</span>
						</div>
					</div>
					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@company">Company</span>
							<span>{{partOrder.store?.company?.name || '-'}}</span>
						</div>
						<div class="field-row-item">
							<span class="label" i18n="@@store">Branch</span>
							<span>{{partOrder.store?.name || '-'}}</span>
						</div>
					</div>
					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@createdAt">Created date</span>
							<span>{{partOrder.createdAt | date:'medium' || '-'}}</span>
						</div>
					</div>
					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@description">Description</span>
							<span>{{partOrder.description || '-'}}</span>
						</div>
					</div>
				</div>

				<div class="right row" *hasPermission="['PartOrders.updateStatusPartOrder']">
					<dropdown-ns
						[value]="{id: partOrder.statusPartOrder.id}"
						(changed)="updateStatusPartOrder($event)">
						<ng-template *ngIf="po_requested()" dropdownOption [value]="{id: 'po_requested'}">
							<span i18n="@@po_requested">Requested</span>
						</ng-template>
						<ng-template *ngIf="po_revision()" dropdownOption [value]="{id: 'po_revision'}">
							<span i18n="@@po_revision">Revision</span>
						</ng-template>
						<ng-template *ngIf="po_approved('selector')" dropdownOption [value]="{id: 'po_approved'}">
							<span i18n="@@po_approved">Approved</span>
						</ng-template>
						<ng-template *ngIf="po_closed('selector')" dropdownOption [value]="{id: 'po_closed'}">
							<span i18n="@@po_closed">Closed</span>
						</ng-template>
						<ng-template *ngIf="po_cancelled()" dropdownOption [value]="{id: 'po_cancelled'}">
							<span i18n="@@po_cancelled">Cancelled</span>
						</ng-template>
					</dropdown-ns>

					<button *ngIf="po_approved('button')"
						class="btn-status approved"
						(click)="updateStatusPartOrder({id: 'po_approved'});"
						i18n="@@po_approve">Approve</button>
					<button *ngIf="po_closed('button')"
						class="btn-status closed"
						(click)="updateStatusPartOrder({id: 'po_closed'})"
						i18n="@@po_close">Close</button>
				</div>
			</div>

			<shipment-list *ngIf="shipmentListView else partRequestList"
				[partOrder]="partOrder"
				[(hasShipmentInTransit)]="hasShipmentInTransit"
				[(hasShipmentInReturn)]="hasShipmentInReturn"
				[(hasShipmentDelivered)]="hasShipmentDelivered">
			</shipment-list>

			<ng-template #partRequestList>
				<partRequest-list [partOrder]="partOrder"></partRequest-list>
			</ng-template>
		</ng-container>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">
						Are you sure you want to delete the record?
					</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	