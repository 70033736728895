import { HttpParams } from '@angular/common/http';
import { Status } from 'app/status/status';
import { Company } from '../company/company';

export class StoreFilter {
	name: string;
	company: Company;

	constructor(filter?: StoreFilter) {
		if (filter) {
			this.name = filter.name;
			this.company = filter.company;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.name) {
			httpParams = httpParams.set('name', this.name);
		}
		if (this.company && this.company.id) {
			httpParams = httpParams.set('companyId', this.company.id.toString());
		}
		return httpParams;
	}
}