import { Component } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from 'app/shared/notification/notification.service';
import { CustomValidators } from 'app/shared/form/custom.validators';
import { UserService } from 'app/user/user.service';

@Component({
	template: `
		<div i18n="@@recoverPass-reset">
			<div class="dialog-title">Reset your password</div>
			<div class="dialog-message">The email has been sent successfully, use the pin we sent you in the mail to reset your password</div>
		</div>

		<form [formGroup]="form" (ngSubmit)="responsePin()">
			<fieldset class="fieldset">
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="pin">Pin</label>
						<input formControlName="pin" type="text" autocomplete="off">
						<validator [control]="form.controls['pin']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="newPassword" i18n="@@password">Password</label>
						<input formControlName="newPassword" type="password" autocomplete="off">
						<validator [control]="form.controls['newPassword']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="newPasswordConfirm" i18n="@@passwordConfirm">Password confirm</label>
						<input formControlName="newPasswordConfirm" type="password" autocomplete="off">
						<validator [control]="form.controls['newPasswordConfirm']"></validator>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-reset-pass">Reset password</span>
				</button>
			</div>
		</form>

		<a class="clickable-item link" routerLink="../" i18n="@@forgot-pass">Back to <u>Forgot password</u></a>
	`,
	styleUrls: ['../account.css']
})
export class RecoverPassResetComponent {
	form: FormGroup;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private userService: UserService,
		private notificationService: NotificationService,
		private fb: FormBuilder
	) {
		this.form = this.toForm();
	}

	responsePin(): void {
		this.userService.recoverPassReset(this.form.value).subscribe(() => {
			this.router.navigate(['../done'], {relativeTo: this.activatedRoute});
		}, err => this.notificationService.error(err));
	}

	private toForm(): FormGroup {
		return this.fb.group({
			pin: new FormControl('', Validators.required),
			newPassword: new FormControl('', Validators.required),
			newPasswordConfirm: new FormControl('', [Validators.required, CustomValidators.match('newPassword')])
		});
	}
}
