
		<h3 class="title" i18n="@@detail-machine">Machine Detail</h3>

		<div class="tool-bar-wrap both-side">
			<div class="right row">
				<button class="btn-icon" i18n-title="@@option-update" title="Update" type="button" (click)="update()">
					<i class="material-icons">edit</i>
				</button>
				<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false">
					<i class="material-icons">delete</i>
				</button>
			</div>
		</div>

		<div class="fieldset-wrap">
			<div class="fieldset">
				<div class="legend" i18n="@@data-machine">Machine data</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@serial">Serial</span>
						<span>{{machine.serial || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@status">Status</span>
						<span translate>{{machine.statusMachine?.id || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@swVersion">Software version</span>
						<span>{{machine.swVersion || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@purchaseDate">purchase date</span>
						<span>{{machine.purchaseDate | date:'mediumDate' || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@factoryWarrantyExpiration">factory warranty expiration date</span>
						<span>{{machine.factoryWarrantyExpiration | date:'mediumDate' || '-'}}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@localWarrantyTime">local warranty time (days)</span>
						<span>{{machine.localWarrantyTime || '-'}}</span>
					</div>
				</div>
			</div>

			<div class="fieldset">
				<legend class="legend" i18n="@@data-maintenence">Maintenence data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@maintenenceFrecuency">Maintenence recuency</span>
						<span>{{machine.maintenenceFrecuency || '-'}}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@lastMaintenenceFrecuency">Last maintenence frecuency</span>
						<span>{{machine.lastMaintenenceFrecuency | date:'mediumDate' || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@usedDistance">Used distance (kms)</span>
						<span>{{machine.usedDistance || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@maintenencePerDistance">Maintenence per distance (kms)</span>
						<span>{{machine.maintenencePerDistance || '-'}}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@lastMaintenenceDistance">Last maintenence distance</span>
						<span>{{machine.lastMaintenenceDistance || '-'}}</span>
					</div>
				</div>
			</div>
			<div class="fieldset">
				<div class="legend" i18n="@@data-machineModel">Machine Model data</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@code">Code</span>
						<span>{{machine.machineModel?.name || '-'}}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@name">Name</span>
						<span>{{machine.machineModel?.comercialName || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@machineType">Machine Type</span>
						<span>{{machine.machineModel?.machineType?.name || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@machineBrand">Machine Brand</span>
						<span>{{machine.machineModel?.machineBrand?.name || '-'}}</span>
					</div>
				</div>
			</div>
			<div class="fieldset">
				<div class="legend" i18n="@@data-store">Branch data</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@name">Name</span>
						<span>{{machine.store?.name || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@company">Company</span>
						<span>{{machine.store?.company?.name || '-'}}</span>
					</div>
				</div>
			</div>
		</div>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">
						Are you sure you want to delete the record?
					</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	