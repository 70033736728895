import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { BaseService } from 'app/core/base.service';
import { AccessTokenResponse } from './oauth/accessTokenResponse';
import { Router } from '@angular/router';
import { distinctUntilChanged, catchError } from 'rxjs/operators';
import { User } from '../../user/user';
import { JwtHelperService } from '@auth0/angular-jwt';

// reference: https://tools.ietf.org/html/rfc6749
@Injectable()
export class AuthService  {
	private static readonly BASE_URL: string = BaseService.HOST + '/oauth';
	private headers: HttpHeaders;
	private jwtHelper = new JwtHelperService();

	private currentUserSubject = new BehaviorSubject<any>({});
	public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

	constructor(
		private http: HttpClient,
		public router: Router,
	) {
		this.headers = new HttpHeaders();
		this.headers.set('Content-Type', 'application/x-www-form-urlencoded');
	}

	setCurrentUser(user: User) {
		this.currentUserSubject.next(user);
	}

	isAuthenticated(): boolean {
		const token = localStorage.getItem('access_token');
		return token ? true : false;
	}

	isTokenExpired(token: string): boolean {
		return this.jwtHelper.isTokenExpired(token);
	}

	getDecodeToken(token): any {
		if (token) {
			return this.jwtHelper.decodeToken(token);
		}
		return null;
	}

	getAccessToken(): string {
		return localStorage.getItem('access_token');
	}

	getRefreshToken(): string {
		return localStorage.getItem('refresh_token');
	}

	hasRefreshToken(): boolean {
		return localStorage.getItem('refresh_token') ? true : false;
	}

	setToken(accessTokenResponse: AccessTokenResponse): void {
		/*const tokenPayload = this.getDecodeToken(accessTokenResponse.access_token);
		if (tokenPayload && tokenPayload.id) {
			this.userService.getByAuthUserId(tokenPayload.id).subscribe(user => {
				this.setCurrentUser(user);
			});
		}*/

		localStorage.setItem('access_token', accessTokenResponse.access_token);
		if (accessTokenResponse.refresh_token) {
			localStorage.setItem('refresh_token', accessTokenResponse.refresh_token);
		}
	}

	clearToken(): void {
		localStorage.clear();
		this.setCurrentUser(undefined);
		this.router.navigate(['login']);
	}

	/*
	* oauth server
	*/
	token(username: string, password: string): Observable<AccessTokenResponse> {
		const body = new HttpParams()
			.set('grant_type', 'password')
			.set('client_id', 'web_site')
			.set('username', username)
			.set('password', password);

		return this.http.post<AccessTokenResponse>(AuthService.BASE_URL + '/token', body, {headers: this.headers})
			.pipe(catchError(BaseService.handleError));
	}

	refreshToken(): Observable<AccessTokenResponse> {
		const body = new HttpParams()
			.set('grant_type', 'refresh_token')
			.set('client_id', 'web_site')
			.set('refresh_token', this.getRefreshToken());

		return this.http.post<AccessTokenResponse>(AuthService.BASE_URL + '/token', body, {headers: this.headers})
			.pipe(catchError(BaseService.handleError));
	}

	revokeToken(): Observable<any> {
		const body = new HttpParams()
			.set('token', this.getRefreshToken())
			.set('token_type_hint', 'refresh_token');

		return this.http.post(AuthService.BASE_URL + '/revoke', body, {headers: this.headers})
			.pipe(catchError(BaseService.handleError));
	}
}
