import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { BaseService } from 'app/core/base.service';
import { PartOrder } from './partOrder';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class PartOrderService {
	private static readonly BASE_URL: string = BaseService.HOST + '/partOrders';

	constructor(
		private http: HttpClient,
		private fb: FormBuilder
	) {}

	getById(id: number, params?: HttpParams): Observable<PartOrder> {
		return this.http.get(PartOrderService.BASE_URL + '/' + id, {params: params})
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	getAll(params: HttpParams): Observable<any> {
		return this.http.get(PartOrderService.BASE_URL, {params: params})
			.pipe(catchError(BaseService.handleError));
	}

	create(partOrder: PartOrder): Observable<PartOrder> {
		return this.http.post(PartOrderService.BASE_URL, partOrder)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	update(partOrder: PartOrder): Observable<PartOrder> {
		return this.http.put(PartOrderService.BASE_URL + '/' + partOrder.id, partOrder)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	updateStatusPartOrder(partOrder: PartOrder): Observable<PartOrder> {
		return this.http.patch(`${PartOrderService.BASE_URL}/${partOrder.id }/statusPartOrder`, partOrder)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	delete(id: number): Observable<any> {
		return this.http.delete(PartOrderService.BASE_URL + '/' + id)
			.pipe(catchError(BaseService.handleError));
	}

	deletes(partOrders: PartOrder[]): Observable<any> {
		return this.http.post(PartOrderService.BASE_URL + '/delete', partOrders)
			.pipe(catchError(BaseService.handleError));
	}

	toFormGroup(partOrder: PartOrder): FormGroup {
		return this.fb.group({
			id: new FormControl(partOrder.id),
			description: new FormControl(partOrder.description),
			store: new FormControl(partOrder.store, Validators.required),
			statusPartOrder: new FormControl(partOrder.statusPartOrder ? {id: partOrder.statusPartOrder.id} : undefined, Validators.required),
		});
	}
}
