import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { UserUpdatePasswordComponent } from './user-update-password.component';
import { UserReadComponent } from './user-read.component';
import { UserComponent } from './user.component';
import { UserService } from './user.service';
import { dashboardRouting } from 'app/dashboard/dashboard.routes';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		dashboardRouting,
		ReactiveFormsModule,
	],
	declarations: [
		UserComponent,
		UserReadComponent,
		UserUpdatePasswordComponent
	],
	providers: [
		UserService,
	]
})
export class UserModule { }
