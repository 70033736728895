import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { BaseService } from 'app/core/base.service';
import { ShipmentItem } from './shipmentItem';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ShipmentItemService {
	private static readonly BASE_URL: string = BaseService.HOST + '/shipmentItems';

	constructor(
		private http: HttpClient,
		private fb: FormBuilder
	) {}

	getById(id: number): Observable<ShipmentItem> {
		return this.http.get(ShipmentItemService.BASE_URL + '/' + id)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	getAll(params: HttpParams): Observable<any> {
		return this.http.get(ShipmentItemService.BASE_URL, {params: params})
			.pipe(catchError(BaseService.handleError));
	}

	create(shipmentItem: ShipmentItem): Observable<ShipmentItem> {
		return this.http.post(ShipmentItemService.BASE_URL, shipmentItem)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	update(shipmentItem: ShipmentItem): Observable<ShipmentItem> {
		return this.http.put(ShipmentItemService.BASE_URL + '/' + shipmentItem.id, shipmentItem)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	delete(id: number): Observable<any> {
		return this.http.delete(ShipmentItemService.BASE_URL + '/' + id)
			.pipe(catchError(BaseService.handleError));
	}

	toFormGroup(shipmentItem: ShipmentItem): FormGroup {
		return this.fb.group({
			id: new FormControl(shipmentItem.id),
			partRequest: new FormControl(shipmentItem.partRequest, Validators.required),
			shipment: new FormControl(shipmentItem.shipment, Validators.required),
		});
	}
}
