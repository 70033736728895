
		<h3 class="title" i18n="@@create-serviceType">Create Service Type</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="create()">
			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-serviceType">Service Type data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="name" i18n="@@name">Name</label>
						<input formControlName="name" type="text" autocomplete="off">
						<validator [control]="form.controls['name']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="name" i18n="@@workedTime">Worked time</label>
						<input formControlName="workedTime" type="text" autocomplete="off">
						<validator [control]="form.controls['workedTime']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="price" i18n="@@price">Price</label>
						<input formControlName="price" type="text" autocomplete="off">
						<validator [control]="form.controls['price']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="machineTypes" i18n="@@machineTypes">Machine Models</label>
						<dropdown-multi-ns [(control)]="form.controls['machineTypes']">
							<ng-template *ngFor="let object of machineTypes" dropdownOption [value]="{id: object.id}">{{object.name || '-'}}</ng-template>
						</dropdown-multi-ns>
						<validator [control]="form.controls['machineTypes']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" i18n="@@description">Description</label>
						<textarea formControlName="description"></textarea>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	