import { HttpParams } from '@angular/common/http';

export class UserEmployeeFilter {
	email: string;
	firstName: string;
	lastName: string;

	constructor(filter?: UserEmployeeFilter) {
		if (filter) {
			this.email = filter.email;
			this.firstName = filter.firstName;
			this.lastName = filter.lastName;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.firstName) {
			httpParams = httpParams.set('firstName', this.firstName);
		}
		if (this.lastName) {
			httpParams = httpParams.set('lastName', this.lastName);
		}
		if (this.email) {
			httpParams = httpParams.set('email', this.email);
		}
		return httpParams;
	}
}
