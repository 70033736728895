import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { CompanyContractModule } from '../companyContract/companyContract.module';
import { UserGenericComponent } from './userGeneric.component';
import { UserGenericListComponent } from './userGeneric-list.component';
import { UserGenericReadComponent } from './userGeneric-read.component';
import { UserGenericCreateComponent } from './userGeneric-create.component';
import { UserGenericUpdateComponent } from './userGeneric-update.component';
import { UserGenericUpdatePasswordComponent } from './userGeneric-update-password.component';
import { UserGenericService } from './userGeneric.service';
import { UserService } from '../user/user.service';
import { AuthRoleService } from '../auth/authRole.service';
import { dashboardRouting } from '../dashboard/dashboard.routes';
import { ContextMenuModule } from 'ngx-contextmenu';
import { AuthGroupService } from 'app/auth/authGroup.service';
import { MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule, MatExpansionModule, MatSelectModule } from '@angular/material';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		CompanyContractModule,
		dashboardRouting,
		FormsModule,
		ReactiveFormsModule,
		ContextMenuModule,
		MatTableModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule,
		MatExpansionModule,
		MatSelectModule
	],
	declarations: [
		UserGenericComponent,
		UserGenericListComponent,
		UserGenericReadComponent,
		UserGenericCreateComponent,
		UserGenericUpdateComponent,
		UserGenericUpdatePasswordComponent
	],
	providers: [
		UserGenericService,
		UserService,
		AuthRoleService,
		AuthGroupService
	]
})
export class UserGenericModule { }
