
		<h3 class="title" i18n="@@create-partOrder">Create Part Order</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="create()">
			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-partOrder">Part Order data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="store" i18n="@@store">Branch</label>
						<mat-select [formControl]="form.controls['store']" [compareWith]="compareFn" disableRipple>
							<mat-option i18n="@@select-none">None</mat-option>
							<mat-option *ngFor="let opt of stores" [value]="opt">{{opt.name}}</mat-option>
						</mat-select>
						<validator [control]="form.controls['store']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" i18n="@@description">Description</label>
						<textarea formControlName="description"></textarea>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	