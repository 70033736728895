import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { CompanyService } from './company.service';
import { NotificationService} from 'app/shared/notification/notification.service';
import { StatusFinancialService } from 'app/status/statusFinancial.service';
import { Status } from 'app/status/status';
import { Company } from 'app/company/company';
import { Contact } from 'app/core/models/contact';
import { HttpParams } from '@angular/common/http';

@Component({
	template: `
		<h3 class="title" i18n="@@create-company">Create Company</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="create()">
			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-company">Company data</legend>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="name" i18n="@@name">Name</label>
						<input formControlName="name" type="text" autocomplete="off">
						<validator [control]="form.controls['name']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="statusFinancial" i18n="@@statusFinancial">Financial Status</label>
						<mat-select [formControl]="form.controls['statusFinancial']" [compareWith]="compareFn" disableRipple>
							<mat-option i18n="@@select-none">None</mat-option>
							<mat-option *ngFor="let opt of statusFinancials" [value]="opt">{{opt.name}}</mat-option>
						</mat-select>
						<validator [control]="form.controls['statusFinancial']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" i18n="@@description">Description</label>
						<textarea formControlName="description"></textarea>
					</div>
				</div>
			</fieldset>

			<fieldset class="fieldset" formGroupName="contact">
				<legend class="legend" i18n="@@data-contact">Contact data</legend>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="phone" i18n="@@phone">Phone</label>
						<input formControlName="phone" type="text" autocomplete="off">
						<validator [control]="form.controls['contact'].controls['phone']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="phone2" i18n="@@phone2">Second phone</label>
						<input formControlName="phone2" type="text" autocomplete="off">
						<validator [control]="form.controls['contact'].controls['phone2']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="latitude" i18n="@@latitude">Latitude</label>
						<input formControlName="latitude" type="text" autocomplete="off">
						<validator [control]="form.controls['contact'].controls['latitude']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="longitude" i18n="@@longitude">Longitude</label>
						<input formControlName="longitude" type="text" autocomplete="off">
						<validator [control]="form.controls['contact'].controls['longitude']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="address" i18n="@@address">Address</label>
						<textarea formControlName="address"></textarea>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	`,
})
export class CompanyCreateComponent implements OnInit {
	form: FormGroup;
	statusFinancials: Status[];

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private companyService: CompanyService,
		private statusFinancialService: StatusFinancialService,
		private notificationService: NotificationService
	) {
		let company = new Company();
		company.statusFinancial = new Status('financial_done');
		company.contact = new Contact();
		this.form = this.companyService.toFormGroup(company);
	}

	ngOnInit() {
		this.statusFinancialService.getAll(
			new HttpParams().set('sort', 'name').set('collection', 'id, name')
		).subscribe(data => {
			this.statusFinancials = data['result'];
		});
	}

	create() {
		this.companyService.getByName(this.form.value['name']).subscribe(object => {
			if (object && object.id !== this.form.value['id']) {
				this.notificationService.errorDuplicated();
			} else {
				this.companyService.create(this.form.value).subscribe(company => {
					this.notificationService.sucessInsert(company.name);
					this.router.navigate([`../${company.id}`], {relativeTo: this.activatedRoute});
				}, err => this.notificationService.error(err));
			}
		}, err => this.notificationService.error(err));
	}

	changeStatusFinancial(status: Status) {
		this.form.controls['statusFinancial'].patchValue(status);
	}

	compareFn(c1: any, c2: any): boolean {
		return c1 && c2 ? c1.id === c2.id : c1 === c2;
	}
}
