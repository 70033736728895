import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Store } from './store';
import { StoreService } from './store.service';
import { NotificationService} from 'app/shared/notification/notification.service';
import { Company } from 'app/company/company';
import { CompanyService } from 'app/company/company.service';
import { Contact } from 'app/core/models/contact';
import { HttpParams } from '@angular/common/http';

@Component({
	template: `
		<h3 class="title" i18n="@@update-store">Update Branch</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="update()">
			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-store">Branch data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="name" i18n="@@name">Name</label>
						<input formControlName="name" type="text" autocomplete="off">
						<validator [control]="form.controls['name']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="company" i18n="@@company">Company</label>
						<mat-select [formControl]="form.controls['company']" [compareWith]="compareFn" disableRipple>
							<mat-option i18n="@@select-none">None</mat-option>
							<mat-option *ngFor="let opt of companies" [value]="opt">{{opt.name}}</mat-option>
						</mat-select>
						<validator [control]="form.controls['company']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="maintenenceFrecuency" i18n="@@maintenenceFrecuency">Maintenence frecuency (months)</label>
						<input formControlName="maintenenceFrecuency" type="text" autocomplete="off">
						<validator [control]="form.controls['maintenenceFrecuency']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="notificationSlack" i18n="@@notificationSlack">Notification slack</label>
						<input formControlName="notificationSlack" type="text" autocomplete="off">
						<validator [control]="form.controls['notificationSlack']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" i18n="@@description">Description</label>
						<textarea formControlName="description"></textarea>
					</div>
				</div>
			</fieldset>

			<fieldset class="fieldset" formGroupName="contact">
				<legend class="legend" i18n="@@data-contact">Contact data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="phone" i18n="@@phone">Phone</label>
						<input formControlName="phone" type="text" autocomplete="off">
						<validator [control]="form.controls['contact'].controls['phone']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="phone2" i18n="@@phone2">Second phone</label>
						<input formControlName="phone2" type="text" autocomplete="off">
						<validator [control]="form.controls['contact'].controls['phone2']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="latitude" i18n="@@latitude">Latitude</label>
						<input formControlName="latitude" type="text" autocomplete="off">
						<validator [control]="form.controls['contact'].controls['latitude']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="longitude" i18n="@@longitude">Longitude</label>
						<input formControlName="longitude" type="text" autocomplete="off">
						<validator [control]="form.controls['contact'].controls['longitude']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="address" i18n="@@address">Address</label>
						<textarea formControlName="address"></textarea>
					</div>
				</div>
			</fieldset>

			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-inCharge">Person in charge data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="name" i18n="@@name">Name</label>
						<input formControlName="contactName" type="text" autocomplete="off">
						<validator [control]="form.controls['contactName']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="name" i18n="@@phone">Phone</label>
						<input formControlName="contactPhone" type="text" autocomplete="off">
						<validator [control]="form.controls['contactPhone']"></validator>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	`,
})
export class StoreUpdateComponent implements OnInit {
	[x: string]: any;
	form: FormGroup;
	companies: Company[] = [];

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private storeService: StoreService,
		private companyService: CompanyService,
		private notificationService: NotificationService,
	) {}

	ngOnInit() {
		this.activatedRoute.parent.params.subscribe(params => {
			this.storeService.getById(params['storeId'])
				.subscribe(store => {
					if (!store.contact) {
						store.contact = new Contact();
					}
					this.form = this.storeService.toFormGroup(store);
				});
		});

		this.companyService.getAll(
			new HttpParams().set('sort', 'name').set('collection', 'id, name')
		).subscribe(data => {
			this.companies = data['result'];
		});
	}

	update() {
		this.storeService.update(<Store> this.form.value).subscribe(store => {
			this.notificationService.sucessUpdate(store.name);
			this.router.navigate([`../`], {relativeTo: this.activatedRoute});
		}, err => this.notificationService.error(err));
	}

	compareFn(c1: any, c2: any): boolean {
		return c1 && c2 ? c1.id === c2.id : c1 === c2;
	}
}
