import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceOrder } from '../serviceOrder/serviceOrder';
import { NotificationService} from 'app/shared/notification/notification.service';
import { HttpParams } from '@angular/common/http';
import { MatTableDataSource, PageEvent } from '@angular/material';
import { TableService } from 'app/core/models/pager';
import { ServiceRequest } from 'app/serviceRequest/serviceRequest';
import { RequestServiceItemService } from './requestServiceItem.service';

@Component({
	selector: 'requestServiceItem-list',
	template: `
		<div class="legend" i18n="@@serviceRequests">Services</div>

		<div class="tool-bar-wrap both-side">
			<ng-template [hasPermission]="['RequestServiceItems.delete']">
				<div *ngIf="serviceOrder.statusServiceOrder.id === 'so_draft' && items?.length > 0" style="padding: 10px;">
					<mat-checkbox (change)="tableService.masterToggle(items)"
						[checked]="tableService.selection.hasValue() && tableService.isAllSelected(items.length)"
						[indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(items.length)">
					</mat-checkbox>
				</div>
			</ng-template>

			<div class="right row tool-bar-wrap">
				<ng-template [hasPermission]="['RequestServiceItems.create']">
					<button *ngIf="serviceOrder.statusServiceOrder.id === 'so_draft'"
						class="btn-icon" i18n-title="@@option-create" title="Create" type="button" (click)="create()">
						<i class="material-icons">add</i>
					</button>
				</ng-template>

				<ng-template [hasPermission]="['RequestServiceItems.delete']">
					<button *ngIf="serviceOrder.statusServiceOrder.id === 'so_draft' && tableService.selection.hasValue()"
						class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false">
						<i class="material-icons">delete</i>
					</button>
				</ng-template>
			</div>
		</div>

		<div class="mat-elevation-z8e" *ngIf="dataSource">
			<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
				<ng-container matColumnDef="select">
					<th mat-header-cell *matHeaderCellDef>
						<mat-checkbox (change)="tableService.masterToggle(dataSource.data)"
							[checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSource.data.length)"
							[indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSource.data.length)"
							*ngIf="serviceOrder.statusServiceOrder.id === 'so_draft'">
						</mat-checkbox>
					</th>
					<td mat-cell *matCellDef="let row">
						<mat-checkbox (click)="$event.stopPropagation()"
							(change)="tableService.selection.toggle(row)"
							[checked]="tableService.selection.isSelected(row)"
							*ngIf="serviceOrder.statusServiceOrder.id === 'so_draft'">
						</mat-checkbox>
					</td>
				</ng-container>

				<ng-container matColumnDef="id">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@id">Id</th>
					<td mat-cell *matCellDef="let element" translate>{{element.id}}</td>
				</ng-container>

				<ng-container matColumnDef="machine.serial">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@machine-serial">Machine - serial</th>
					<td mat-cell *matCellDef="let element" translate>{{element.machine.serial}}</td>
				</ng-container>

				<ng-container matColumnDef="serviceType.name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@serviceType">Service type</th>
					<td mat-cell *matCellDef="let element">{{element.serviceType.name}}</td>
				</ng-container>

				<ng-container matColumnDef="partRequests">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@partRequests">Part Requests</th>
					<td mat-cell *matCellDef="let element">
						<div *ngFor="let item of element.partRequests" class="field-row">
							<div class="field-row-item">
								<span class="label" i18n="@@partOrder-id">Part Order - id</span><span>{{item.partOrder.id}}</span>
							</div>
							<div class="field-row-item">
								<span class="label" i18n="@@partRequest-id">Part Request - id</span><span>{{item.id}}</span>
							</div>
							<div class="field-row-item">
								<span class="label" i18n="@@partModel-name">Part Model - name</span><span>{{item.partModel.name}}</span>
							</div>
						</div>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"
					(contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row"
					(click)="read(row)">
				</tr>
			</table>

			<mat-paginator showFirstLastButtons
				[length]="tableService.pager.totalEntities"
				[pageIndex]="tableService.pager.pageIndex"
				[pageSize]="tableService.pager.pageSize"
				[pageSizeOptions]="[20, 50, 100]"
				(page)="list($event)">
			</mat-paginator>
		</div>

		<context-menu #basicMenu>
			<ng-container *ngIf="serviceOrder.statusServiceOrder.id === 'so_draft'">
				<ng-template contextMenuItem divider="true"></ng-template>
				<ng-template contextMenuItem (execute)="confirmDelete = false">
					<div class="item">
						<i class="material-icons">delete</i>
						<span i18n="@@option-delete">Delete</span>
					</div>
				</ng-template>
			</ng-container>
		</context-menu>


		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">
						Are you sure you want to delete the record?
					</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="deletes(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	`
})
export class RequestServiceItemListComponent implements OnInit {
	@Input() serviceOrder: ServiceOrder;
	@Output() readRequestService = new EventEmitter();
	dataSource: MatTableDataSource<ServiceRequest>;
	displayedColumns: string[] = ['select', 'id', 'machine.serial', 'serviceType.name', 'partRequests'];
	confirmDelete = true;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private requestServiceItemService: RequestServiceItemService,
		public tableService: TableService<ServiceRequest>,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		this.list();
	}

	list(event?: PageEvent) {
		let httpParams = new HttpParams()
			.set('sort', 'createdAt')
			.set('collection', `id, observation,
			serviceType(id, name),
			machine(id, serial),
			partRequests(id, quantity, statusShipmentId, partOrder(id), partModel(id, name))`)
			.set('sort', 'createdAt')
			.set('serviceOrderId', this.serviceOrder.id.toString());

		this.requestServiceItemService.getAll(httpParams).subscribe(data => {
			this.dataSource = new MatTableDataSource<ServiceRequest>(data['result']);
			this.tableService.pager = data['pager'];
			this.tableService.selection.clear();
			this.readRequestService.emit();
			console.log(data['result']);
		});
	}

	create() {
		this.router.navigate(['./requestServiceItems/create'], {relativeTo: this.activatedRoute});
	}

	read(item: ServiceRequest) {
		this.router.navigate(['./requestServiceItems/' + item.id], {relativeTo: this.activatedRoute});
	}

	deletes() {
		const length = this.tableService.selection.selected.length;
		this.tableService.pager.pageIndex = (this.tableService.pager.pageIndex > 0 && this.tableService.pager.totalEntitiesPerPage === length) ?
			--this.tableService.pager.pageIndex : this.tableService.pager.pageIndex;

		if (length === 1) {
			this.requestServiceItemService.delete(this.tableService.selection.selected[0].id).subscribe(() => {
				this.notificationService.sucessDelete();
				this.list();
			}, err => this.notificationService.error(err));
		} else if (length > 1) {
			this.requestServiceItemService.deletes(this.tableService.selection.selected).subscribe(() => {
				this.notificationService.sucessDelete();
				this.list();
			}, err => this.notificationService.error(err));
		}
	}
}
