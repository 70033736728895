import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ServiceRecord } from './serviceRecord';
import { ServiceRecordService } from './serviceRecord.service';
import { NotificationService} from 'app/shared/notification/notification.service';

@Component({
	template: `
		<h3 class="title" i18n="@@detail-serviceRecord">Order Record Detail</h3>

		<div class="tool-bar-wrap both-side">
			<div class="right row">
				<button class="btn-icon" i18n-title="@@option-update" title="Update" type="button" (click)="update()" *hasPermission="['ServiceRecords.delete']">
					<i class="material-icons">edit</i>
				</button>
				<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false" *hasPermission="['ServiceRecords.delete']">
					<i class="material-icons">delete</i>
				</button>
			</div>
		</div>

		<div class="fieldset-wrap">
			<div class="fieldset">
				<div class="legend" i18n="@@data-serviceRecord">order record data</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@startedDateTime">Started</span>
						<span>{{serviceRecord.startDateTime || '-'}}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@finishedDateTime">Finished</span>
						<span>{{serviceRecord.finishDateTime || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@description">Description</span>
						<span>{{serviceRecord.description || '-'}}</span>
					</div>
				</div>
			</div>
		</div>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">
						Are you sure you want to delete the record?
					</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	`
})
export class ServiceRecordReadComponent implements OnInit {
	confirmDelete = true;
	serviceRecord = new ServiceRecord();

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private location: Location,
		private serviceRecordService: ServiceRecordService,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		this.activatedRoute.params.subscribe(params => {
			this.serviceRecordService.getById(params['serviceRecordId']).subscribe(serviceRecord => {
				this.serviceRecord = serviceRecord;
			});
		});
	}

	update() {
		this.router.navigate(['./update'], {relativeTo: this.activatedRoute});
	}

	delete(this) {
		this.serviceRecordService.delete(this.serviceRecord.id).subscribe(data => {
			this.notificationService.sucessDelete();
			let url = this.location.path();
			url = url.substring(0, url.lastIndexOf('/'));
			url = url.substring(0, url.lastIndexOf('/'));
			this.router.navigate([url]);
		}, err => this.notificationService.error(err));
	}
}
