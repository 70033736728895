<div id="privacy">
    <h1>Términos y Condiciones.</h1>
    <p>La privacidad es un principio que valoramos muchos y ponemos nuestros mejores esfuerzos para que garantizarlo en todo momento. Esta aplicación esta enfocado al área corporativo, no es una aplicación pública que puede ser usado por cualquier persona, con lo cual la integridad de la información esta bien asegurada. En nuestros términos del Servicio y Política de privacidad encontraras lo siguientes:</p>
    <ul class="privacy-container">
        <li>Comprehensión: Nuestros términos del servicio son sencillos de comprender y reflejan nuestro objetivo, como lo son ofrecer la gestión de servicio y soporte sobre las maquinas LifeFitness.</li>
        <li>Acceso: Solo podrán acceder a la aplicación los técnicos que trabajan en la compañía y en donde se le dan los privilegios de acceso y navegación en la aplicación.</li>
        <li>Información: Hemos desarrollado funciones y procedimientos para proteger la privacidad y seguridad de la información que se genera a través de la aplicación.</li>
        <li>Cero anuncios: No se permite publicidad por parte de terceros.</li>
        <li>Dispositivos y Software: Debes usar ciertos dispositivos, software y conexiones de datos para poder operar con la aplicación, durante el tiempo de uso de nuestros servicios aceptas descargar e instalar actualizaciones del software.</li>
        <li>Privacidad: Nos preocupamos por tu privacidad, nuestras políticas describen las practicas de información que llevamos a cabo, así como también la forma como recibimos, recopilamos y procesamos los datos.</li>
        <li>Uso Legal: Debes usar nuestros servicios en conformidad con nuestros Términos y políticas publicadas.</li>
    </ul>
</div>