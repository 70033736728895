
	<h3 class="title" i18n="@@create-machine">Create Machine</h3>

	<form *ngIf="form" [formGroup]="form" (ngSubmit)="create()">
		<fieldset class="fieldset">
			<legend class="legend" i18n="@@data-machine">Machine data</legend>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="store" i18n="@@store">Branch</label>
					<mat-select [formControl]="form.controls['store']" [compareWith]="compareFn" disableRipple>
						<mat-option i18n="@@select-none">None</mat-option>
						<mat-option *ngFor="let opt of stores" [value]="opt">{{opt.name}}</mat-option>
					</mat-select>
					<validator [control]="form.controls['store']"></validator>
				</div>
			</div>
			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="machineModel" i18n="@@machineModel">Machine Model</label>
					<dropdown-ns [(control)]="form.controls['machineModel']">
						<ng-template *ngFor="let object of machineModels" dropdownOption [value]="{id: object.id}">
							<div class="extend">
								<div class="extend-main">{{object.name || '-'}}</div>
								<div class="field-row">
									<div class="field-row-item">
										<span class="label" i18n="@@name">name</span>
										<span>{{object.comercialName || '-'}}</span>
									</div>
								</div>
								<div class="field-row">
									<div class="field-row-item">
										<span class="label" i18n="@@machineType">Machine Type</span>
										<span>{{object.machineType?.name || '-'}}</span>
									</div>
								</div>
								<div class="field-row">
									<div class="field-row-item">
										<span class="label" i18n="@@machineBrand">Machine Brand</span>
										<span>{{object.machineBrand?.name || '-'}}</span>
									</div>
								</div>
							</div>
						</ng-template>
					</dropdown-ns>
					<validator [control]="form.controls['machineModel']"></validator>
				</div>
			</div>
			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="serial" i18n="@@serial">Serial</label>
					<input formControlName="serial" type="text" autocomplete="off">
					<validator [control]="form.controls['serial']"></validator>
				</div>
			</div>
			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="statusMachine" i18n="@@status">Status</label>
					<mat-select [formControl]="form.controls['statusMachine']" [compareWith]="compareFn" disableRipple>
						<mat-option i18n="@@select-none">None</mat-option>
						<mat-option *ngFor="let opt of statusMachines" [value]="opt">{{opt.name}}</mat-option>
					</mat-select>
					<validator [control]="form.controls['statusMachine']"></validator>
				</div>
			</div>
			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="swVersion" i18n="@@swVersion">Sotfware version</label>
					<input formControlName="swVersion" type="text" autocomplete="off">
					<validator [control]="form.controls['swVersion']"></validator>
				</div>
			</div>
			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="purchaseDate" i18n="@@purchaseDate">Purchase date</label>
					<div class="datepicker">
						<input matInput [matDatepicker]="picker" [formControl]="form.controls['purchaseDate']">
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					</div>
					<mat-datepicker #picker></mat-datepicker>
					<validator [control]="form.controls['purchaseDate']"></validator>
				</div>
				<div class="field-row-item">
					<label class="label" for="factoryWarrantyExpiration" i18n="@@factoryWarrantyExpiration">Factory warranty expiration</label>
					<div class="datepicker">
						<input matInput [matDatepicker]="picker2" [formControl]="form.controls['factoryWarrantyExpiration']">
						<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
					</div>
					<mat-datepicker #picker2></mat-datepicker>
					<validator [control]="form.controls['factoryWarrantyExpiration']"></validator>
				</div>
			</div>
			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="localWarrantyTime" i18n="@@localWarrantyTime">Local warranty time</label>
					<input formControlName="localWarrantyTime" type="text" autocomplete="off">
					<validator [control]="form.controls['localWarrantyTime']"></validator>
				</div>
			</div>
		</fieldset>

		<fieldset class="fieldset">
			<legend class="legend" i18n="@@data-maintenence">Maintenence data</legend>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="usedDistance" i18n="@@usedDistance">Used distance</label>
					<input formControlName="usedDistance" type="text" autocomplete="off">
					<validator [control]="form.controls['usedDistance']"></validator>
				</div>
			</div>
			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="maintenenceFrecuency" i18n="@@maintenenceFrecuency">Maintenence frecuency</label>
					<input formControlName="maintenenceFrecuency" type="text" autocomplete="off">
					<validator [control]="form.controls['maintenenceFrecuency']"></validator>
				</div>
				<div class="field-row-item">
					<label class="label" for="maintenencePerDistance" i18n="@@maintenencePerDistance">Maintenence per distance</label>
					<input formControlName="maintenencePerDistance" type="text" autocomplete="off">
					<validator [control]="form.controls['maintenencePerDistance']"></validator>
				</div>
			</div>
		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!form.valid">
				<span i18n="@@option-save">Save</span>
			</button>
		</div>
	</form>
	