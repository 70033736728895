import { Component } from '@angular/core';
import { NotificationService } from 'app/shared/notification/notification.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '../shared/form/custom.validators';
import { UserService } from './user.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
	template: `
		<h3 class="title" i18n="@@update-pass">Update password</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="update()">
			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-user">User data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="currentPassword" i18n="@@currentPassword">Current password</label>
						<input formControlName="currentPassword" type="password" autocomplete="off">
						<validator [control]="form.controls['currentPassword']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="newPassword" i18n="@@newPassword">New password</label>
						<input formControlName="newPassword" type="password" autocomplete="off">
						<validator [control]="form.controls['newPassword']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="newPasswordConfirm" i18n="@@newPasswordConfirm">Confirm new password</label>
						<input formControlName="newPasswordConfirm" type="password" autocomplete="off">
						<validator [control]="form.controls['newPasswordConfirm']"></validator>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	`,
})

export class UserUpdatePasswordComponent {
	form: FormGroup;

	constructor(
		private activatedRoute: ActivatedRoute,
		private location: Location,
		private notificationService: NotificationService,
		private fb: FormBuilder,
		private userService: UserService,
	) {
		this.form = this.toForm();
	}

	update() {
		this.activatedRoute.params.subscribe(params => {
			this.userService.resetPassword(params['userId'], this.form.value).subscribe(() => {
				this.notificationService.sucessUpdate();
				this.location.back();
			}, err => this.notificationService.error(err));
		});
	}

	toForm(): FormGroup {
		return this.fb.group({
			currentPassword: new FormControl('', Validators.required),
			newPassword: new FormControl('', Validators.required),
			newPasswordConfirm: new FormControl('', [Validators.required, CustomValidators.match('newPassword')])
		});
	}
}
