import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { CompanyContractService } from './companyContract.service';
import { CompanyService } from 'app/company/company.service';
import { Company } from 'app/company/company';
import { NotificationService} from 'app/shared/notification/notification.service';
import { HttpParams } from '@angular/common/http';

@Component({
	template: `
		<h3 class="title" i18n="@@update-companyContract">Update Company Contract</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="update()">
			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-companyContract">Company Contract data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="company" i18n="@@company">Company</label>
						<mat-select [formControl]="form.controls['company']" [compareWith]="compareFn" disableRipple>
							<mat-option i18n="@@select-none">None</mat-option>
							<mat-option *ngFor="let opt of companies" [value]="opt">{{opt.name}}</mat-option>
						</mat-select>
						<validator [control]="form.controls['company']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="name" i18n="@@name">Name</label>
						<input formControlName="name" type="text" autocomplete="off">
						<validator [control]="form.controls['name']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" i18n="@@description">Description</label>
						<textarea formControlName="description"></textarea>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	`,
})
export class CompanyContractUpdateComponent implements OnInit {
	form: FormGroup;
	companies: Company[];

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private companyContractService: CompanyContractService,
		private companyService: CompanyService,
		private notificationService: NotificationService,
	) {}

	ngOnInit() {
		this.activatedRoute.parent.params.subscribe(params => {
			this.companyContractService.getById(params['companyContractId']).subscribe(companyContract => {
				this.form = this.companyContractService.toFormGroup(companyContract);
			});
		});

		this.companyService.getAll(
			new HttpParams().set('sort', 'name').set('collection', 'id, name')
		).subscribe(data => {
			this.companies = data['result'];
		});
	}

	update() {
		this.companyContractService.update(this.form.value)
			.subscribe(companyContract => {
				this.notificationService.sucessUpdate(companyContract.name);
				this.router.navigate([`../`], {relativeTo: this.activatedRoute});
			}, err => this.notificationService.error(err));
	}

	compareFn(c1: any, c2: any): boolean {
		return c1 && c2 ? c1.id === c2.id : c1 === c2;
	}
}
