import { Component, Output, EventEmitter, OnInit, ContentChildren, QueryList, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CalendarEventDirective } from './calendarEventDirective';

@Component({
	selector: 'calendar-ns',
	template: `
		<div class="grid-v" style="height: 100%">
			<div class="options" style="margin-bottom: 10px;">
				<div class="options-current">
					{{monthNames[current.getMonth()]}} {{current.getFullYear()}}
				</div>
				<div class="options-change-date">
					<div style="padding: 5px; cursor: pointer;" (click)="setToday()" i18n="@@calendar-today">TODAY</div>
					<button class="btn-icon" i18n-title="@@calendar-previous" title="Previous month" type="button" (click)="previousMonth()">
						<i class="material-icons">keyboard_arrow_left</i>
					</button>
					<button class="btn-icon" i18n-title="@@calendar-next" title="Next month" type="button" (click)="nextMonth()">
						<i class="material-icons">keyboard_arrow_right</i>
					</button>
				</div>
			</div>
			<div class="calendar grid-v">
				<div class="calendar-header calendar-row">
					<div *ngFor="let dayName of dayNames" class="calendar-row-item">{{dayName}}</div>
				</div>
				<div class="calendar-body grid-v">
					<div *ngFor="let row of calendar" class="calendar-row">
						<div *ngFor="let cell of row" class="calendar-row-item" [id]="cell.date.getTime()" [ngClass]="{pasive: cell.date.getMonth() != current.getMonth(), today: cell.date.getTime() === today.getTime()}">
							<div class="container-cell">
								<div class="dayOfMonth">{{cell.date.getDate()}}</div>
								<div class="events">
									<ng-template *ngIf="eventsMap[cell.date.getTime()]" [ngTemplateOutlet]="eventsMap[cell.date.getTime()].template"></ng-template>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	`,
	styleUrls: ['./calendar-ns.component.css']
})
export class CalendarComponent implements OnInit, AfterViewInit {
	today: Date;
	current: Date;
	monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
	dayNames = ['Monday', 'Tuesday', 'Wednesday', 'Thrusday', 'Friday', 'Saturday', 'Sunday'];
	calendar: {}[][];
	@Output() getEventsPerMonth = new EventEmitter();
	@ContentChildren(CalendarEventDirective) public events: QueryList<CalendarEventDirective>;
	eventsMap = {};

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {
		this.today = this.current = new Date();
		this.today.setHours(0, 0, 0, 0);
	}

	ngOnInit() {
		this.setToday();
	}

	ngAfterViewInit() {
		setTimeout(() => { // https://blog.angular-university.io/angular-debugging/
			this.events.changes.subscribe(events => {
				this.eventsMap = {};
				events.forEach(event => this.eventsMap[event.time] = event);
			});
		});
	}

	previousMonth() {
		this.current.setMonth(this.current.getMonth() - 1);
		this.calendar = this.getCalendarArray(this.current.getMonth() + 1, this.current.getFullYear());
		const firstDateOfArr = this.calendar[0][0]['date'];
		const lastDateOfArr = this.calendar[this.calendar.length - 1][this.calendar[0].length - 1]['date'];
		this.getEventsPerMonth.emit({from: firstDateOfArr, to: lastDateOfArr});
	}

	nextMonth() {
		this.current.setMonth(this.current.getMonth() + 1);
		this.calendar = this.getCalendarArray(this.current.getMonth() + 1, this.current.getFullYear());
		const firstDateOfArr = this.calendar[0][0]['date'];
		const lastDateOfArr = this.calendar[this.calendar.length - 1][this.calendar[0].length - 1]['date'];
		this.getEventsPerMonth.emit({from: firstDateOfArr, to: lastDateOfArr});
	}

	setToday() {
		this.current = new Date();
		this.calendar = this.getCalendarArray(this.current.getMonth() + 1, this.current.getFullYear());
		const firstDateOfArr = this.calendar[0][0]['date'];
		const lastDateOfArr = this.calendar[this.calendar.length - 1][this.calendar[0].length - 1]['date'];
		this.getEventsPerMonth.emit({from: firstDateOfArr, to: lastDateOfArr});
	}

	// month_number is in the range 1..12
	private getWeekPerMonth(month: number, year: number) {
		const firstOfMonth = new Date(year, month - 1, 1);
		const lastOfMonth = new Date(year, month, 0);
		// const used = lastOfMonth.getDate() + (firstOfMonth.getDay() === 0 ? 6 : firstOfMonth.getDay()); // start sunday
		const used = lastOfMonth.getDate() + (firstOfMonth.getDay() === 0 ? 6 : firstOfMonth.getDay()) - 1; // start monday
		return Math.ceil(used / 7); // start monday
	}

	// month_number is in the range 1..12
	private getCalendarArray(month: number, year: number) {
		let arr: {}[][] = [];
		const weekOfMonth = this.getWeekPerMonth(month, year);
		let date = new Date(year, month - 1, 1);
		// date.setDate(date.getDate() - (date.getDay() === 0 ? 6 : date.getDay())); // start sunday
		date.setDate(date.getDate() - (date.getDay() === 0 ? 6 : date.getDay()) + 1); // start monday

		for (let i = 0; i < weekOfMonth; i++) {
			arr[i] = [];
			for (let j = 0; j < 7; j++) {
				arr[i][j] = {
					date: new Date(date),
					event: []
				};
				date.setDate(date.getDate() + 1);
			}
		}
		return arr;
	}
}
