import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { dashboardRouting } from '../dashboard/dashboard.routes';
import { ContextMenuModule } from 'ngx-contextmenu';
import { ServiceRequestModule } from 'app/serviceRequest/serviceRequest.module';
import { RequestServiceItemComponent } from './requestServiceItem.component';
import { RequestServiceItemListComponent } from './requestServiceItem-list.component';
import { RequestServiceItemCreateComponent } from './requestServiceItem-create.component';
import { RequestServiceItemService } from './requestServiceItem.service';
import { RequestServiceItemReadComponent } from './requestServiceItem-read.component';
import { MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule, MatExpansionModule, MatSelectModule } from '@angular/material';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		dashboardRouting,
		ReactiveFormsModule,
		ServiceRequestModule,
		ContextMenuModule,
		MatTableModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule,
		MatExpansionModule,
		MatSelectModule
	],
	declarations: [
		RequestServiceItemComponent,
		RequestServiceItemListComponent,
		RequestServiceItemCreateComponent,
		RequestServiceItemReadComponent
	],
	exports: [
		RequestServiceItemListComponent
	],
	providers: [
		RequestServiceItemService
	],
})
export class RequestServiceItemModule { }
