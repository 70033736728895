import { BaseModel } from 'app/core/models/base.model';
import { Machine } from '../machine/machine';
import { ServiceType } from '../serviceType/serviceType';
import { ServiceTask } from 'app/serviceTask/serviceTask';
import { ServiceOrder } from 'app/serviceOrder/serviceOrder';
import { User } from 'app/user/user';
import { PartRequest } from 'app/partRequest/partRequest';

export class ServiceRequest extends BaseModel {
	progress: number;
	price: number;
	totalPrice: number;
	observation: string;
	serviceType: ServiceType;
	machine: Machine;
	serviceOrder: ServiceOrder;
	updatedBy: User;
	serviceTasks: ServiceTask[] = new Array<ServiceTask>();
	partRequests: PartRequest[] = new Array<PartRequest>();
	serviceTasksLength: number;
	serviceTasksDraftLength: number;
	serviceTasksApprovedLength: number;
	serviceTasksDoneLength: number;
	serviceTasksCancelledLength: number;
	serviceTasksMissedLength: number;
	serviceTasksExpiredLength: number;
	partRequestsLength: number;
}
