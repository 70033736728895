import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PartRequest} from './partRequest';
import { PartRequestService } from './partRequest.service';
import { NotificationService} from 'app/shared/notification/notification.service';
import { PartOrder } from 'app/partOrder/partOrder';
import { HttpParams } from '@angular/common/http';
import { ServiceOrder } from 'app/serviceOrder/serviceOrder';
import { MatTableDataSource, PageEvent } from '@angular/material';
import { TableService } from 'app/core/models/pager';
import { PartOrderService } from 'app/partOrder/partOrder.service';

@Component({
	selector: 'partRequest-list',
	template: `
		<div class="legend" i18n="@@partRequests">Parts</div>

		<div class="tool-bar-wrap both-side">
			<div class="right row">
				<ng-template [hasPermission]="['PartRequests.create']">
					<button *ngIf="partOrder.statusPartOrder.id === 'po_revision'" class="btn-icon" i18n-title="@@option-create" title="Create" type="button" (click)="create()">
						<i class="material-icons">add</i>
					</button>
				</ng-template>
				<ng-template [hasPermission]="['PartRequests.delete']">
					<button *ngIf="partOrder.statusPartOrder.id === 'po_revision' && tableService.selection.hasValue()" class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false">
						<i class="material-icons">delete</i>
					</button>
				</ng-template>
			</div>
		</div>

		<div class="mat-elevation-z8e" *ngIf="dataSource">
			<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
				<ng-container matColumnDef="select">
					<th mat-header-cell *matHeaderCellDef>
						<mat-checkbox (change)="tableService.masterToggle(dataSource.data)"
							[checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSource.data.length)"
							[indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSource.data.length)"
							*ngIf="partOrder.statusPartOrder.id === 'po_revision'">
						</mat-checkbox>
					</th>
					<td mat-cell *matCellDef="let row">
						<mat-checkbox (click)="$event.stopPropagation()"
							(change)="tableService.selection.toggle(row)"
							[checked]="tableService.selection.isSelected(row)"
							*ngIf="partOrder.statusPartOrder.id === 'po_revision'">
						</mat-checkbox>
					</td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>

				<ng-container matColumnDef="id">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@id">Id</th>
					<td mat-cell *matCellDef="let element">{{element.id}}</td>
					<td mat-footer-cell *matFooterCellDef i18n="@@total">Total</td>
				</ng-container>

				<ng-container matColumnDef="partModel">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@partModel-name">Part Model - name</th>
					<td mat-cell *matCellDef="let element">
						{{element.partModel?.name}}

						<div *ngIf="element.machine && !element.serviceRequest" class="extend">
							<div class="legend" i18n="@@serviceRequest">Service</div>
							<div class="field-row">
								<div class="field-row-item">
									<span class="label" i18n="@@machine-serial">Machine - serial</span>
									<span class="clickable-item">{{element.machine?.serial}}</span>
								</div>
							</div>
						</div>
						<div *ngIf="element.serviceRequest" class="extend">
							<div class="legend" i18n="@@serviceRequest">Service</div>
							<div class="field-row">
								<div class="field-row-item">
									<span class="label" i18n="@@serviceOrder">Order Service - id</span>
									<span class="clickable-item" (click)="getServiceOrder(element.serviceRequest.serviceOrder)">{{element.serviceRequest.serviceOrder?.id}}</span>
								</div>
								<div class="field-row-item">
									<span class="label" i18n="@@serviceRequest">Service - id</span>
									<span>{{element.serviceRequest.id}}</span>
								</div>
								<div class="field-row">
									<div class="field-row-item">
										<span class="label" i18n="@@machine-serial">Machine - serial</span>
										<span class="clickable-item">{{element.serviceRequest.machine?.serial}}</span>
									</div>
								</div>
							</div>
						</div>
					</td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>

				<ng-container matColumnDef="quantity">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@quantity">Quantity</th>
					<td mat-cell *matCellDef="let element">{{element.quantity}}</td>
					<td mat-footer-cell *matFooterCellDef>{{partOrder.totalQuantity}}</td>
				</ng-container>

				<ng-container matColumnDef="price">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@unitPrice">Unit price</th>
					<td mat-cell *matCellDef="let element">{{element.price}}</td>
					<td mat-footer-cell *matFooterCellDef></td>
				</ng-container>

				<ng-container matColumnDef="totalPrice">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@price">Price</th>
					<td mat-cell *matCellDef="let element">{{element.totalPrice}}</td>
					<td mat-footer-cell *matFooterCellDef>{{partOrder.totalPrice}}</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<ng-container *ngIf="partOrder.statusPartOrder.id === 'po_revision'; else withContextMenu">
					<tr mat-row *matRowDef="let row; columns: displayedColumns;"
					(contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row"
						(click)="read(row)">
					</tr>
				</ng-container>
				<ng-template #withContextMenu>
					<tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="read(row)"></tr>
				</ng-template>
				<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
			</table>
		</div>

		<context-menu #basicMenu>
			<ng-template contextMenuItem (execute)="update($event.item)">
				<div class="item">
					<i class="material-icons">edit</i>
					<span i18n="@@option-update">Update</span>
				</div>
			</ng-template>
			<ng-template contextMenuItem divider="true"></ng-template>
			<ng-template contextMenuItem (execute)="confirmDelete = false">
				<div class="item">
					<i class="material-icons">delete</i>
					<span i18n="@@option-delete">Delete</span>
				</div>
			</ng-template>
		</context-menu>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-deletes">Are you sure you want to delete the selected items?</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="deletes(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	`
})
export class PartRequestListComponent implements OnInit {
	@Input() partOrder: PartOrder;
	dataSource: MatTableDataSource<PartRequest>;
	displayedColumns: string[] = ['select', 'id', 'partModel', 'quantity', 'price', 'totalPrice'];
	partRequests: PartRequest[];
	confirmDelete = true;
	tableService = new TableService<PartRequest>();

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private partOrderService: PartOrderService,
		private partRequestService: PartRequestService,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		this.tableService.sort = 'createdAt';
		this.list();
	}

	list(event?: PageEvent) {
		let httpParams = new HttpParams()
			.set('sort', this.tableService.sort)
			.set('collection', 'id, quantity, partModel(id, name), serviceRequest(id, serviceOrder(id), machine(id, serial, machineModel(id, name, comercialName, machineType(id, name), machineBrand(id, name)))), machine(id, serial, machineModel(id, name, comercialName, machineType(id, name), machineBrand(id, name)))')
			.set('partOrderId', this.partOrder.id.toString());

		this.partRequestService.getAll(httpParams).subscribe(data => {
			this.dataSource = new MatTableDataSource<PartRequest>(data['result']);
			this.partRequests = data['result'];
		});
	}

	create() {
		this.router.navigate(['./partRequests/create'], {relativeTo: this.activatedRoute});
	}

	read(item: PartRequest) {
		this.router.navigate([`./partRequests/${item.id}`], {relativeTo: this.activatedRoute});
	}

	getServiceOrder(serviceOrder: ServiceOrder) {
		this.router.navigate([`./dashboard/serviceOrders/${serviceOrder.id}`]);
	}

	update(item: PartRequest) {
		this.router.navigate([`./partRequests/${item.id}/update`], {relativeTo: this.activatedRoute});
	}

	getPartRequest() {
		this.partOrderService.getById(this.partOrder.id).subscribe(partOrder => {
			this.partOrder = partOrder;
		});
	}

	deletes() {
		const length = this.tableService.selection.selected.length;

		if (length === 1) {
			this.partRequestService.delete(this.tableService.selection.selected[0].id).subscribe(() => {
				this.notificationService.sucessDelete();
				this.tableService.selection.clear();
				this.list();
				this.getPartRequest();
			}, err => this.notificationService.error(err));
		} else if (length > 1) {
			this.partRequestService.deletes(this.tableService.selection.selected).subscribe(() => {
				this.notificationService.sucessDelete();
				this.tableService.selection.clear();
				this.list();
				this.getPartRequest();
			}, err => this.notificationService.error(err));
		}
	}
}
