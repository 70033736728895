
		<h3 class="title" i18n="@@detail-partModel">Part Model Detail</h3>

		<div class="tool-bar-wrap both-side">
			<div class="right row">
				<button class="btn-icon" i18n-title="@@option-update" title="Update" type="button" (click)="update()" *hasPermission="['PartModels.update']">
					<i class="material-icons">edit</i>
				</button>
				<button class="btn-icon" i18n-title="@@option-update-images" title="Update images" type="button" (click)="updateImages()" *hasPermission="['MediaController.createPartModels']">
					<i class="material-icons">collections</i>
				</button>
				<button class="btn-icon" i18n-title="@@option-update-pdfs" title="Update PDF files" type="button" (click)="updatePdfs()" *hasPermission="['PartModels.update']">
					<i class="material-icons">picture_as_pdf</i>
				</button>
				<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false" *hasPermission="['PartModels.delete']">
					<i class="material-icons">delete</i>
				</button>
			</div>
		</div>

		<div class="fieldset-wrap">
			<div class="fieldset">
				<div class="legend" i18n="@@data-partModel">Part Model data</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@code">Code</span>
						<span>{{partModel.code || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@name">Name</span>
						<span>{{partModel.name || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@stock">Stock</span>
						<span>{{partModel.stock || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@price">Price</span>
						<span>{{partModel.price || '-'}}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@price2">Second price</span>
						<span>{{partModel.price2 || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@description">Description</span>
						<span>{{partModel.description || '-'}}</span>
					</div>
				</div>
			</div>
			<div class="fieldset">
				<div class="legend" i18n="@@images">Images</div>
				<gallery-image-preview [items]="images"></gallery-image-preview>
			</div>
			<div class="fieldset">
				<div class="legend" i18n="@@pdfs">PDF documents</div>
				<div class="field-row">
					<media-pdf-list [items]="pdfs"></media-pdf-list>
				</div>
			</div>
		</div>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">Are you sure you want to delete the record?</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	