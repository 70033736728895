import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { MachineBrandService } from './machineBrand.service';
import { NotificationService} from 'app/shared/notification/notification.service';

@Component({
	template: `
		<h3 class="title" i18n="@@update-machineBrand">Update Machine Brand</h3>
		
		<form *ngIf="form" [formGroup]="form" (ngSubmit)="update()">
			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-machine">Machine data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="name" i18n="@@name">Name</label>
						<input formControlName="name" type="text" autocomplete="off">
						<validator [control]="form.controls['name']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" i18n="@@description">Description</label>
						<textarea formControlName="description"></textarea>
					</div>
				</div>
			</fieldset>
			
			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	`
})
export class MachineBrandUpdateComponent implements OnInit {
	form: FormGroup;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private machineBrandService: MachineBrandService,
		private notificationService: NotificationService,
	) {}

	ngOnInit() {
		this.activatedRoute.parent.params.subscribe(params => {
			this.machineBrandService.getById(params['machineBrandId']).subscribe(machineBrand => {
				this.form = this.machineBrandService.toFormGroup(machineBrand);
			});
		});
	}

	update() {
		this.machineBrandService.update(this.form.value).subscribe(machineBrand => {
			this.notificationService.sucessUpdate(machineBrand.name);
			this.router.navigate([`../`], {relativeTo: this.activatedRoute});
		}, err => this.notificationService.error(err));
	}
}
