import { Component, HostListener, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from 'app/shared/notification/notification.service';
import { ServiceTaskService } from 'app/serviceTask/serviceTask.service';
import { Global } from 'app/core/global';
import { ServiceTask } from 'app/serviceTask/serviceTask';
import { HttpParams } from '@angular/common/http';

@Component({
	template: `
		<div #calendar style="position: absolute; top: 0; bottom: 0; right: 0; left: 0; margin: 30px;">
			<calendar-ns (getEventsPerMonth)="getEventsPerMonth($event)">
				<ng-template *ngFor="let time of objectKeys(events)" calendarEvent [time]="time">
					<ng-container *ngFor="let userEmployeeId of objectKeys(events[time]); let i = index;">
						<div *ngIf="i <= eventsPerCell-2 || (i === eventsPerCell-1 && objectKeys(events[time]).length === eventsPerCell)" class="event" (click)="seeDetail($event, userEmployeeId, time)">
							<div>{{events[time][userEmployeeId].userEmployee.firstName}} {{events[time][userEmployeeId].userEmployee.lastName[0]}}.</div>
							<div>+{{events[time][userEmployeeId].serviceTasksLength}}</div>
						</div>
					</ng-container>
					<div *ngIf="eventsPerCell > 0 && objectKeys(events[time]).length > eventsPerCell" class="event read-more" (click)="seeMore($event, time)">{{objectKeys(events[time]).length - eventsPerCell + 1}} more</div>
					<div *ngIf="eventsPerCell === 0" class="event read-more" (click)="seeMore($event, time)">{{objectKeys(events[time]).length}} more</div>
				</ng-template>
			</calendar-ns>

			<div #cellModal2 id="more-info" style="width: 180px;" [ngStyle]="{'visibility': cellModal.style.visibility, 'top': cellModal.style.top + 'px', 'left': cellModal.style.left + 'px'}" (clickOutside)="cellModal.style.visibility = 'hidden'">
				<ng-container *ngIf="cellModal.time">
					<div class="bar" style="margin-bottom: 10px;">
						<div style="font-size: 16px;">{{dayNames[cellModal.date.getDay()]}} {{cellModal.date.getDate()}}</div>
						<div>
							<button class="btn-icon button-close" i18n-title="@@close" title="Close" type="button" (click)="cellModal.style.visibility = 'hidden'">
								<i class="material-icons">close</i>
							</button>
						</div>
					</div>
					
					<div *ngFor="let userEmployeeId of objectKeys(events[cellModal.time])" class="event" (click)="seeDetail($event, userEmployeeId, cellModal.time)">
						<div>{{events[cellModal.time][userEmployeeId].userEmployee.firstName}} {{events[cellModal.time][userEmployeeId].userEmployee.lastName[0]}}.</div>
						<div>+{{events[cellModal.time][userEmployeeId].serviceTasksLength}}</div>
					</div>
				</ng-container>
			</div>

			<div #detailModal2 id="detail" style="width: 400px;" [ngStyle]="{'visibility': detailModal.style.visibility, 'top': detailModal.style.top + 'px', 'left': detailModal.style.left + 'px'}" (clickOutside)="detailModal.style.visibility = 'hidden'">
				<ng-container *ngIf="detailModal.serviceTasks">
					<div class="bar">
						<div style="font-size: 16px;">{{dayNames[detailModal.date.getDay()]}} {{detailModal.date.getDate()}}</div>
						<div>
							<button class="btn-icon button-close" i18n-title="@@close" title="Close" type="button" (click)="detailModal.style.visibility = 'hidden'">
								<i class="material-icons">close</i>
							</button>
						</div>
					</div>

					<div style="font-size: 24px; margin-bottom: 10px;">{{detailModal.serviceTasks[0].userEmployee.firstName}} {{detailModal.serviceTasks[0].userEmployee.lastName}}</div>
					
					<div style="margin-bottom: 5px; font-size: 12px; color: #a4a4a4; text-align: right;">Total {{detailModal.serviceTasks.length}}</div>

					<div *ngFor="let serviceTask of detailModal.serviceTasks" class="table-item-row" (click)="getServiceTask(serviceTask)">
						<div style="flex: 0 1 30px;">#{{serviceTask.id}}</div>
						<div style="flex-grow: 2;">{{serviceTask.serviceRequest.serviceOrder.store.name}}</div>
						<div style="flex-grow: 1; text-align: right;">{{serviceTask.serviceRequest.serviceType.name}}</div>
						<div style="flex-grow: 1; text-align: right;" translate>{{serviceTask.statusServiceTask.name}}</div>
					</div>
				</ng-container>
			</div>
		</div>
	`,
	styles: [`
		.event{
			background-color: #f2f2f2;
			width: 100%;
			padding: 3px;
			border-radius: 2px;
			display: flex;
			justify-content: space-between;
			font-size: 12px;
			cursor: pointer;
		}
		.event:not(last-child){
			margin-bottom: 5px;
		}
		.event div{
			text-overflow: ellipsis;
			white-space: nowrap;
			flex: 0 0 auto;
		}
		.event.read-more{
			font-weight: bold;
			background-color: #fff;
		}

		#more-info,
		#detail{
			position: absolute;
			padding: 10px;
			background-color: #fff;
			border: 1px solid #e0e0e0;
			box-shadow: 0px 0px 20px #e5e5e5;
			overflow-y: auto;
			overflow-x: hidden:
		}
		.bar{
			display: flex;
			justify-content: space-between;
		}
		#detail .table-item-row{
			font-size: 14px;
			padding: 3px;
			display: flex;
			justify-content: space-between;
			cursor: pointer;
			transition: all .2s linear;
		}
		#detail .table-item-row:hover{
			background-color: #f2f2f2;
		}
		#detail .table-item-row div{
			min-width: 0px;
			flex-grow: 1;
			flex-basis: 0;
			word-break: break-word;
			overflow: hidden;
			/* reference: https:// stackoverflow.com/questions/12022288/how-to-keep-a-flex-item-from-overflowing-due-to-its-text*/
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		#detail .table-item-row div:not(:last-child){
			margin-right: 10px;
		}
	`]
})
export class ServiceOrderCalendarComponent implements AfterViewInit {
	objectKeys = Object.keys;
	dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thrusday', 'Friday', 'Saturday'];
	events = {};
	eventsPerCell = 0;
	cellModal = {
		style: {visibility: 'hidden', top: 0, left: 0},
		time: 0,
		date: new Date()
	};
	detailModal = {
		style: {visibility: 'hidden', top: 0, left: 0},
		time: 0,
		date: new Date(),
		serviceTasks: undefined,
	};
	@ViewChild('calendar') calendarEl: ElementRef;
	@ViewChild('cellModal2') cellModalEl: ElementRef;
	@ViewChild('detailModal2') detailModalEl: ElementRef;
	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.getEventsPerCell();
	}

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private serviceTaskService: ServiceTaskService,
		private notificationService: NotificationService
	) {}

	ngAfterViewInit() {
		this.getEventsPerCell();
	}

	seeDetail($event, userEmployeeId: number, time: number) {
		$event.preventDefault();
		$event.stopPropagation();

		this.detailModal.time = time;
		this.detailModal.date.setTime(time);
		const dateTime = {
			from: this.detailModal.date.setHours(0, 0, 0, 0),
			to: this.detailModal.date.setHours(23, 59, 59, 999)
		};

		let httpParams = new HttpParams()
			.set('collection', 'id, startDateTime, statusServiceTask(id, name), userEmployee(id, firstName, lastName), serviceRequest(id, serviceType(id, name), serviceOrder(id, store(id, name)))')
			.set('sort', 'startDateTime')
			.set('userEmployeeId', userEmployeeId.toString())
			.set('dateTime.from', Global.toFormatDateFromTime(dateTime.from))
			.set('dateTime.to', Global.toFormatDateFromTime(dateTime.to));

		this.serviceTaskService.getAll(httpParams).subscribe(data => {
			this.detailModal.serviceTasks = data['result'];

			setTimeout(() => {
				const calendar = this.calendarEl.nativeElement;
				const detailModal = this.detailModalEl.nativeElement;
				const event = $event.target;

				const initX = event.getBoundingClientRect().left - calendar.getBoundingClientRect().left;
				const initY = event.getBoundingClientRect().top - calendar.getBoundingClientRect().top;
				const offsetX = detailModal.clientWidth + 20;
				const offsetY = 0;
				this.detailModal.style.left = initX - offsetX;
				this.detailModal.style.top = initY - offsetY;
				this.detailModal.style.visibility = 'visible';
			});
		});

		if (this.cellModal.time !== this.detailModal.time) {
			this.cellModal.style.visibility = 'hidden';
		}
	}

	seeMore($event: MouseEvent, time: number) {
		$event.preventDefault();
		$event.stopPropagation();

		this.detailModal.style.visibility = 'hidden';

		this.cellModal.time = time;
		this.cellModal.date.setTime(time);

		setTimeout(() => {
			const cell = document.getElementById(time.toString());
			const calendar = this.calendarEl.nativeElement;
			const cellModal = this.cellModalEl.nativeElement;

			const initX = cell.getBoundingClientRect().left - calendar.getBoundingClientRect().left;
			const initY = cell.getBoundingClientRect().top - calendar.getBoundingClientRect().top;
			const offsetX = (cellModal.clientWidth - cell.clientWidth) / 2;
			const offsetY = (cellModal.clientHeight - cell.clientHeight) / 2;
			this.cellModal.style.left = initX - offsetX;
			this.cellModal.style.top = initY - offsetY;
			this.cellModal.style.visibility = 'visible';
		});
	}

	getEventsPerCell() {
		setTimeout(() => {
			const rows = document.getElementsByClassName('calendar-row');
			const eventHeight = 28;
			if (rows) {
				const row = rows[1];
				this.eventsPerCell = Math.floor((row.clientHeight - 20) / eventHeight);
				//console.log('eventsPerCell', this.eventsPerCell);
			}
		});
	}

	getEventsPerMonth(date: {from: Date, to: Date}) {
		let httpParams = new HttpParams()
			.set('collection', 'id, startDateTime, userEmployee(id, firstName, lastName), serviceRequest(id, serviceOrder(id, store(id, name)))')
			.set('sort', 'startDateTime')
			.set('dateTime.from', Global.toFormatDate(date.from))
			.set('dateTime.to', Global.toFormatDate(date.to));

		this.serviceTaskService.getAll(httpParams).subscribe(data => {
			this.events = {};

			data['result'].forEach(serviceTask => {
				let startDateTime = new Date(serviceTask.startDateTime);
				startDateTime.setHours(0, 0, 0, 0);
				const time = startDateTime.getTime();

				if (!this.events[time]) {
					this.events[time] = {};
				}

				let userEmployeeId = serviceTask.userEmployee.id;
				if (!this.events[time][userEmployeeId]) {
					this.events[time][userEmployeeId] = {
						serviceOrder: serviceTask.serviceRequest.serviceOrder,
						userEmployee: serviceTask.userEmployee,
						serviceTasksLength: 0
					};
				}
				this.events[time][userEmployeeId].serviceTasksLength++;
			});
		});
	}

	getServiceTask(serviceTask: ServiceTask) {
		this.router.navigate([`./serviceOrders/${serviceTask.serviceRequest.serviceOrder.id}/serviceTasks/${serviceTask.id}`], {relativeTo: this.activatedRoute});
	}
}
