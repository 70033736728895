
		<h3 class="title" i18n="@@detail-store">Branch Detail</h3>

		<div class="tool-bar-wrap both-side">
			<div class="right row">
				<button class="btn-icon-text" type="button" (click)="getMachines()" *hasPermission="['Machines.findAll']">
					<span i18n="@@machines">Machines</span>
				</button>
				<button class="btn-icon" i18n-title="@@option-update" title="Update" type="button" (click)="update()" *hasPermission="['Stores.update']">
					<i class="material-icons">edit</i>
				</button>
				<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false" *hasPermission="['Stores.delete']">
					<i class="material-icons">delete</i>
				</button>
			</div>
		</div>

		<div class="fieldset-wrap">
			<div class="fieldset">
				<div class="legend" i18n="@@data-store">Branch data</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@name">Name</span>
						<span>{{store.name || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@maintenenceFrecuency">Maintenence frecuency (months)</span>
						<span>{{store.maintenenceFrecuency || '-'}}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@notificationSlack">Notification slack (days)</span>
						<span>{{store.notificationSlack || '-'}}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@lastMaintenenceFrecuency">Last Maintenence frecuency</span>
						<span>{{store.lastMaintenenceFrecuency | date:'mediumDate' || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@description">Description</span>
						<span>{{store.description || '-'}}</span>
					</div>
				</div>
			</div>

			<div class="fieldset">
				<div class="legend" i18n="@@data-company">Company data</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@name">Name</span>
						<span>{{store.company?.name || '-'}}</span>
					</div>
				</div>
			</div>

			<div class="fieldset">
				<div class="legend" i18n="@@data-contact">Contact data</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@phone">Phone</span>
						<span>{{store.contact?.phone || '-'}}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@phone2">Second phone</span>
						<span>{{store.contact?.phone2 || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@latitude">Latitude</span>
						<span>{{store.contact?.latitude || '-'}}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@longitude">Longitude</span>
						<span>{{store.contact?.longitude || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@address">Address</span>
						<span>{{store.contact?.address || '-'}}</span>
					</div>
				</div>
			</div>

			<div class="fieldset">
				<div class="legend" i18n="@@data-inCharge">Person in charge data</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@name">Name</span>
						<span>{{store.contactName || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@phone">Phone</span>
						<span>{{store.contactPhone || '-'}}</span>
					</div>
				</div>
			</div>
		</div>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">
						Are you sure you want to delete the record?
					</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	