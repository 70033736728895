import { BaseModel } from 'app/core/models/base.model';
import { Contact } from 'app/core/models/contact';
import { Company } from '../company/company';

export class Store extends BaseModel {
	name: string;
	contactName: string;
	contactPhone: string;
	maintenenceFrecuency: number;
	lastMaintenenceFrecuency: string;
	notificationSlack: number;
	description: string;
	contact: Contact = new Contact();
	company: Company;
}
