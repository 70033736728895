
		<h2 class="subtitle" style="margin: 30px 0" i18n="@@history">History</h2>

		<div class="mat-elevation-z8e" *ngIf="dataSource">
			<table mat-table [dataSource]="dataSource">
				<ng-container matColumnDef="statusShipment">
					<th mat-header-cell *matHeaderCellDef i18n="@@status">Status</th>
					<td mat-cell *matCellDef="let element">{{element.statusShipment?.name}}</td>
				</ng-container>

				<ng-container matColumnDef="createdAt">
					<th mat-header-cell *matHeaderCellDef i18n="@@createdAt">Created at</th>
					<td mat-cell *matCellDef="let element">{{element.createdAt}}</td>
				</ng-container>

				<ng-container matColumnDef="action">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let element">
						<ng-template [hasPermission]="['ShipmentStages.delete']">
							<button *ngIf="element.current === null || element.current === true && element.currentShipment" class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="shipmentStage = element; confirmDelete = false">
								<i class="material-icons">delete</i>
							</button>
						</ng-template>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
			</table>
		</div>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">
						Are you sure you want to delete the record?
					</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	