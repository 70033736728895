import { BaseModel } from 'app/core/models/base.model';
import { PartRequest } from '../partRequest/partRequest';
import { Status } from 'app/status/status';
import { Store } from 'app/store/store';

export class PartOrder extends BaseModel {
	progress: number;
	totalPrice: number;
	description: string;
	store: Store;
	statusPartOrder: Status;
	partRequests: PartRequest[] = new Array<PartRequest>();
	totalQuantity: number;
	partRequestsLength: number;
}
