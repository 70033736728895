import { HttpParams } from '@angular/common/http';
import { ServiceRequest } from 'app/serviceRequest/serviceRequest';
import { UserEmployee } from '../userEmployee/userEmployee';

export class ServiceTaskFilter {
	dateTimeFrom: string;
	dateTimeTo: string;
	userEmployee: UserEmployee;
	serviceRequest: ServiceRequest;

	constructor(filter?: ServiceTaskFilter) {
		if (filter) {
			this.dateTimeFrom = filter.dateTimeFrom;
			this.dateTimeTo = filter.dateTimeTo;
			this.userEmployee = filter.userEmployee;
			this.serviceRequest = filter.serviceRequest;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.dateTimeFrom) {
			httpParams = httpParams.set('dateTime.from', this.dateTimeFrom);
		}
		if (this.dateTimeTo) {
			httpParams = httpParams.set('dateTime.to', this.dateTimeTo);
		}
		if (this.userEmployee && this.userEmployee.id) {
			httpParams = httpParams.set('userEmployeeId', this.userEmployee.id.toString());
		}
		if (this.serviceRequest && this.serviceRequest.id) {
			httpParams = httpParams.set('serviceRequestId', this.serviceRequest.id.toString());
		}
		return httpParams;
	}
}
