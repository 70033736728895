import { HttpParams } from '@angular/common/http';
import { ServiceOrder } from 'app/serviceOrder/serviceOrder';

export class ServiceRequestFilter {
	serviceOrder: ServiceOrder;

	constructor(filter?: ServiceRequestFilter) {
		if (filter) {
			this.serviceOrder = filter.serviceOrder;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.serviceOrder && this.serviceOrder.id) {
			httpParams = httpParams.set('serviceOrderId', this.serviceOrder.id.toString());
		}
		return httpParams;
	}
}
