import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { ContextMenuModule } from 'ngx-contextmenu';
import { dashboardRouting } from 'app/dashboard/dashboard.routes';
import { StatusShipmentService } from 'app/status/statusShipment.service';
import { ShipmentListComponent } from './shipment-list.component';
import { ShipmentService } from './shipment.service';
import { ShipmentItemModule } from 'app/shipmentItem/shipmentItem.module';
import { ShipmentCreateComponent } from './shipment-create.component';
import { ShipmentStageModule } from 'app/shipmentStage/shipmentStage.module';
import { ShipmentReadComponent } from './shipment-read.component';
import { MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule } from '@angular/material';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		dashboardRouting,
		ReactiveFormsModule,
		ShipmentItemModule,
		ShipmentStageModule,
		ContextMenuModule,
		MatTableModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule
	],
	declarations: [
		ShipmentListComponent,
		ShipmentCreateComponent,
		ShipmentReadComponent
	],
	exports: [
		ShipmentListComponent,
		ShipmentCreateComponent
	],
	providers: [
		ShipmentService,
		StatusShipmentService
	]
})
export class ShipmentModule {}
