
		<h3 class="title" i18n="@@detail-partRequest">Part Detail</h3>

		<div class="tool-bar-wrap both-side">
			<div class="right row">
				<button class="btn-icon" i18n-title="@@option-update" title="Update" type="button" (click)="update()">
					<i class="material-icons">edit</i>
				</button>
				<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false">
					<i class="material-icons">delete</i>
				</button>
			</div>
		</div>

		<div class="fieldset-wrap">
			<div class="fieldset">
				<div class="legend" i18n="@@data-partRequest">Part data</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@id">Id</span>
						<span>{{partRequest.id || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@quantity">Quantity</span>
						<span>{{partRequest.quantity || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@price">Price</span>
						<span>{{partRequest.price || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@totalPrice">Total price</span>
						<span>{{partRequest.totalPrice || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@updatedAt">Updated date</span>
						<span>{{partRequest.updatedAt || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@description">Description</span>
						<span>{{partRequest.description || '-'}}</span>
					</div>
				</div>
			</div>

			<div class="fieldset">
				<div class="legend" i18n="@@data-partModel">Part Model data</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@code">Code</span>
						<span>{{partRequest.partModel?.code || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@name">Name</span>
						<span>{{partRequest.partModel?.name || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@price">Price</span>
						<span>{{partRequest.partModel?.price || '-'}}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@price2">Second price</span>
						<span>{{partRequest.partModel?.price2 || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@stock">Stock</span>
						<span>{{partRequest.partModel?.stock || '-'}}</span>
					</div>
				</div>
			</div>

			<div class="fieldset" *ngIf="partRequest.machine && !partRequest.serviceRequest">
				<div class="legend" i18n="@@data-machine">Machine data</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@machine-serial">Machine - serial</span>
						<span>{{partRequest.machine.serial || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@machineModel-code">Machine Model - code</span>
						<span>{{partRequest.machine.machineModel?.name || '-'}}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@machineModel-name">Machine Model - name</span>
						<span>{{partRequest.machine.machineModel?.comercialName || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@machineType">Machine Type</span>
						<span>{{partRequest.machine.machineModel?.machineType?.name || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@machineBrand">Machine Brand</span>
						<span>{{partRequest.machine.machineModel?.machineBrand?.name || '-'}}</span>
					</div>
				</div>
			</div>

			<div class="fieldset" *ngIf="partRequest.serviceRequest">
				<div class="legend" i18n="@@data-serviceRequest">Service data</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@serviceOrder-id">Order Service - id</span>
						<span>{{partRequest.serviceRequest?.serviceOrder.id || '-'}}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@serviceRequest-id">Service - id</span>
						<span>{{partRequest.serviceRequest?.id || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@serviceType">Service Type</span>
						<span>{{partRequest.serviceRequest?.serviceType?.name || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@machine-serial">Machine - serial</span>
						<span>{{partRequest.serviceRequest?.machine.serial || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@machineModel-code">Machine Model - code</span>
						<span>{{partRequest.serviceRequest?.machine?.machineModel.name || '-'}}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@machineModel-name">Machine Model - name</span>
						<span>{{partRequest.serviceRequest?.machine?.machineModel.comercialName || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@machineType">Machine Type</span>
						<span>{{partRequest.serviceRequest?.machine?.machineModel?.machineType?.name || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@machineBrand">Machine Brand</span>
						<span>{{partRequest.serviceRequest?.machine?.machineModel?.machineBrand?.name || '-'}}</span>
					</div>
				</div>
			</div>

			<div class="fieldset">
				<div class="legend" i18n="@@data-shipment">Shipment data</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@shipment">Shipment</span>
						<span translate>{{partRequest.statusShipmentId || '-'}}</span>
					</div>
				</div>
			</div>
		</div>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">Are you sure you want to delete the record?</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	