import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { MachineComponent } from './machine.component';
import { MachineListComponent } from './machine-list.component';
import { MachineReadComponent } from './machine-read.component';
import { MachineCreateComponent } from './machine-create.component';
import { MachineUpdateComponent } from './machine-update.component';
import { MachineService } from './machine.service';
import { StatusMachineService } from 'app/status/statusMachine.service';
import { dashboardRouting } from 'app/dashboard/dashboard.routes';
import { ContextMenuModule } from 'ngx-contextmenu';
import { MachineReadOnlyComponent } from 'app/machine/machine-read-only.component';
import { MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule, MatDatepickerModule, MatNativeDateModule, MatExpansionModule, MatSelectModule } from '@angular/material';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		dashboardRouting,
		FormsModule,
		ReactiveFormsModule,
		ContextMenuModule,
		MatTableModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatExpansionModule,
		MatSelectModule
	],
	declarations: [
		MachineComponent,
		MachineListComponent,
		MachineReadComponent,
		MachineReadOnlyComponent,
		MachineCreateComponent,
		MachineUpdateComponent
	],
	providers: [
		MachineService,
		StatusMachineService
	],
})
export class MachineModule { }
