import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CompanyContract } from './companyContract';
import { CompanyContractService } from './companyContract.service';
import { NotificationService} from 'app/shared/notification/notification.service';
import { MediaService } from 'app/media/media.service';
import { Media } from 'app/media/media';

@Component({
	template: `
		<h3 class="title" i18n="@@detail-companyContract">Company Contract Detail</h3>

		<div class="tool-bar-wrap both-side">
			<div class="right row">
				<ng-container *ngIf="!companyContract.mediaContract else deletePDF">
					<ng-template [hasPermission]="['MediaController.createCompanyContract']">
						<input id="file-upload" type="file" accept="application/pdf" (change)="updatePdf($event)">
						<label class="btn-file" for="file-upload">
							<i class="material-icons">picture_as_pdf</i>
						</label>
					</ng-template>
				</ng-container>

				<ng-template #deletePDF>
					<ng-template [hasPermission]="['MediaController.delete']">
						<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDeleteMediaContract = false" *ngIf="companyContract.mediaContract">
							<i class="material-icons">delete</i>
						</button>
					</ng-template>
				</ng-template>

				<button class="btn-icon" i18n-title="@@option-update" title="Update" type="button" (click)="update()">
					<i class="material-icons">edit</i>
				</button>

				<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false">
					<i class="material-icons">delete</i>
				</button>
			</div>
		</div>

		<div class="fieldset-wrap">
			<div class="fieldset">
				<div class="legend" i18n="@@data-companyContract">Company Contract data</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@name">Name</span>
						<span>{{companyContract.name || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@description">Description</span>
						<span>{{companyContract.description || '-'}}</span>
					</div>
				</div>
			</div>

			<div class="fieldset">
				<div class="legend" i18n="@@data-company">Company data</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@name">Name</span>
						<span>{{companyContract.company?.name || '-'}}</span>
					</div>
				</div>
			</div>
			<div class="fieldset">
				<div class="legend" i18n="@@pdf">PDF document</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@name">Name</span>
						<gallery-pdf-preview-unique [item]="companyContract.mediaContract"></gallery-pdf-preview-unique>
					<div>
				</div>
			</div>
		</div>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">Are you sure you want to delete the item?</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>

		<modal-ns [(closed)]="confirmDeleteMediaContract">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">Are you sure you want to delete the item?</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDeleteMediaContract = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="deleteCompanyContract(); confirmDeleteMediaContract = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	`,
	providers: [MediaService]
})
export class CompanyContractReadComponent implements OnInit {
	confirmDelete = true;
	confirmDeleteMediaContract = true;
	companyContract = new CompanyContract();

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private location: Location,
		private companyContractService: CompanyContractService,
		private mediaService: MediaService,
		private notificationService: NotificationService,
	) {}

	ngOnInit() {
		this.activatedRoute.params.subscribe(params => {
			this.companyContractService.getById(params['companyContractId']).subscribe(companyContract => {
				this.companyContract = companyContract;
			});
		});
	}

	update() {
		this.router.navigate(['./update'], {relativeTo: this.activatedRoute});
	}

	updatePdf(event) {
		if (event.target.files && event.target.files.length > 0) {
			let file: File = event.target.files.item(0);

			MediaService.getBase64(file).then(data => {
				let media = new Media();
				media.name = file.name.substring(0, file.name.lastIndexOf('.'));
				media.multimediaCDN.mediaBase64 = data.toString();

				this.companyContractService.createMedia(this.companyContract.id, media).subscribe(mediaContract => {
					this.notificationService.sucessInsert();
					this.companyContract.mediaContract = mediaContract;
				}, err => this.notificationService.error(err));
			});
		}
	}

	delete(this) {
		this.companyContractService.delete(this.companyContract.id).subscribe(data => {
			this.notificationService.sucessDelete();
			let url = this.location.path();
			this.router.navigate([url.substring(0, url.lastIndexOf('/'))]);
		}, err => this.notificationService.error(err));
	}

	deleteCompanyContract() {
		this.mediaService.delete(this.companyContract.mediaContract.id).subscribe(data => {
			this.notificationService.sucessDelete();
			this.companyContract.mediaContract = undefined;
		}, err => this.notificationService.error(err));
	}
}
