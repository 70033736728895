import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BaseService } from 'app/core/base.service';
import { ServiceOrder } from '../serviceOrder/serviceOrder';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class RequestServiceService {
	private static readonly BASE_URL: string = BaseService.HOST + '/requestServices';

	constructor(
		private http: HttpClient,
		private fb: FormBuilder
	) {}

	create(serviceOrder: ServiceOrder): Observable<ServiceOrder> {
		return this.http.post(RequestServiceService.BASE_URL, serviceOrder)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	update(serviceOrder: ServiceOrder): Observable<ServiceOrder> {
		return this.http.put(RequestServiceService.BASE_URL + '/' + serviceOrder.id, serviceOrder)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	getById(id: number): Observable<ServiceOrder> {
		return this.http.get(RequestServiceService.BASE_URL + '/' + id)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	getAll(params: HttpParams): Observable<any> {
		return this.http.get(RequestServiceService.BASE_URL, {params: params})
			.pipe(catchError(BaseService.handleError));
	}

	updateStatusServiceOrder(serviceOrder: ServiceOrder): Observable<ServiceOrder> {
		return this.http.patch(RequestServiceService.BASE_URL + '/' + serviceOrder.id + '/statusServiceOrder', serviceOrder)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	delete(id: number): Observable<any> {
		return this.http.delete(RequestServiceService.BASE_URL + '/' + id)
			.pipe(catchError(BaseService.handleError));
	}

	deletes(requestServices: ServiceOrder[]): Observable<any> {
		return this.http.post(RequestServiceService.BASE_URL + '/delete', requestServices)
			.pipe(catchError(BaseService.handleError));
	}

	toFormGroup(serviceOrder: ServiceOrder): FormGroup {
		return this.fb.group({
			id: new FormControl(serviceOrder.id),
			description: new FormControl(serviceOrder.description),
			store: new FormControl(serviceOrder.store, Validators.required),
			statusRequestService: new FormControl(
				serviceOrder.statusServiceOrder ? {id: serviceOrder.statusServiceOrder.id} : undefined,
				Validators.required
			)
		});
	}
}
