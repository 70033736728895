import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule }  from '@angular/forms';
import { ModalComponent } from './modal-ns/modal-ns.component';
import { ModalContentDirective } from './modal-ns/modalContentDirective';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { NotificationComponent } from './notification/notification.component';
import { DropdownComponent } from './form/dropdown-ns/dropdown-ns.component';
import { DropdownMultiComponent } from './form/dropdown-ns/dropdown-multi-ns.component';
import { DropdownOptionDirective } from './form/dropdown-ns/dropdownOptionDirective';
import { ClickOutsideDirective } from './directives/clickOutside';
import { ValidatorComponent } from './form/validator.component';
import { GalleryImageComponent } from './gallery/gallery-image.component';
import { GalleryImagePreviewComponent } from './gallery/gallery-image-preview.component';
import { GalleryImagePreviewUniqueComponent } from './gallery/gallery-image-preview-unique.component';
import { GalleryPdfComponent } from './gallery/gallery-pdf.component';
import { GalleryPdfPreviewUniqueComponent } from './gallery/gallery-pdf-preview-unique.component';
import { TranslateModule } from '@ngx-translate/core';
import { HasPermissionDirective } from '../core/auth/hasPermissionDirective';
import { MatCheckboxModule } from '@angular/material';

@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FormsModule,
		SimpleNotificationsModule.forRoot(),
		TranslateModule,
		MatCheckboxModule
	],
	declarations: [
		ModalComponent,
		ModalContentDirective,
		NotificationComponent,
		DropdownComponent,
		DropdownMultiComponent,
		DropdownOptionDirective,
		ClickOutsideDirective,
		ValidatorComponent,
		GalleryImagePreviewComponent,
		GalleryImagePreviewUniqueComponent,
		GalleryImageComponent,
		GalleryPdfComponent ,
		GalleryPdfPreviewUniqueComponent,
		HasPermissionDirective
	],
	exports: [
		ModalComponent,
		ModalContentDirective,
		NotificationComponent,
		ClickOutsideDirective,
		DropdownComponent,
		DropdownMultiComponent,
		DropdownOptionDirective,
		ValidatorComponent,
		GalleryImagePreviewComponent,
		GalleryImagePreviewUniqueComponent,
		GalleryImageComponent,
		GalleryPdfComponent,
		GalleryPdfPreviewUniqueComponent,
		HasPermissionDirective,
		TranslateModule
	]
})
export class SharedModule { }
