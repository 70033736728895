import { HttpParams, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';

/*
reference httpClient: http://blog.enriqueoriol.com/2017/11/httpclient-vs-http-angular.html
*/
export class BaseService {
	public static readonly HOST: string = environment.backendUrl;

	public static extractData(res: any) {
		return res.result;
	}

	public static handleError (error: HttpErrorResponse | any) {
		if (error instanceof Error) {
			// A client-side or network error occurred. Handle it accordingly.
			console.log('An error occurred: ', error.message ? error.message : error.toString());
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.log(`Backend returned code: ${error.status} - ${error.statusText}, body was: ${error.message}`);
		}
		console.log('Raw error:', error);
		return throwError(error);
	}
}
