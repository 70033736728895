import { HttpParams } from '@angular/common/http';
import { ServiceType } from 'app/serviceType/serviceType';

export class MachineTypeFilter {
	name: string;
	serviceType: ServiceType;

	constructor(filter?: MachineTypeFilter) {
		if (filter) {
			this.name = filter.name;
			this.serviceType = filter.serviceType;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.name) {
			httpParams = httpParams.set('name', this.name);
		}
		if (this.serviceType && this.serviceType.id) {
			httpParams = httpParams.set('serviceTypeId', this.serviceType.id.toString());
		}
		return httpParams;
	}
}
