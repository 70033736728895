import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'app/shared/form/custom.validators';
import { BaseService } from 'app/core/base.service';
import { User } from '../user/user';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class UserService {
	private static readonly BASE_URL: string = BaseService.HOST + '/users';

	constructor(
		private http: HttpClient,
		private fb: FormBuilder
	) {}

	getById(id: number): Observable<User> {
		return this.http.get(UserService.BASE_URL + '/' + id)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	getByAuthUserId(authUserId: number, params?: HttpParams): Observable<User> {
		return this.http.get(`${UserService.BASE_URL}/authUser/${authUserId}`, {params: params})
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	getByEmail(email: string): Observable<User> {
		return this.http.get(`${UserService.BASE_URL}/email/${email}`)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	updatePassword(user: User): Observable<User> {
		return this.http.patch(`${UserService.BASE_URL}/${user.id}/password`, user)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	resetPassword(userId: number, request: {currentPassword: string, newPassword: string}): Observable<any> {
		return this.http.post(`${UserService.BASE_URL}/${userId}/resetPassword`, request)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	recoverPassforgot(request: {email: string}): Observable<any> {
		return this.http.post(UserService.BASE_URL + '/recoverPassword', request)
			.pipe(catchError(BaseService.handleError));
	}

	recoverPassReset(request: {pin: string, newPassword: string}): Observable<any> {
		return this.http.post(UserService.BASE_URL + '/recoverPassword', request)
			.pipe(catchError(BaseService.handleError));
	}

	toFormGroup(user: User): FormGroup {
		return this.fb.group({
			id: new FormControl(user.id),
			firstName: new FormControl(user.firstName, [Validators.required, Validators.maxLength(100)]),
			lastName: new FormControl(user.lastName, [Validators.required, Validators.maxLength(100)]),
			description: new FormControl(user.description),
			authUser: this.fb.group({
				email: new FormControl(user.authUser.email, [Validators.required, CustomValidators.emailRegex]),
				password: new FormControl(user.authUser.password, Validators.required),
				passwordConfirm: new FormControl('', [Validators.required, CustomValidators.match('password')]),
				authGroup: new FormControl(user.authUser.authGroup),
				authRoles: new FormControl(user.authUser.authRoles)
			}),
			contact: this.fb.group({
				phone: new FormControl(user.contact.phone, Validators.maxLength(100)),
				phone2: new FormControl(user.contact.phone2, Validators.maxLength(100)),
				latitude: new FormControl(user.contact.latitude, CustomValidators.numberRegex),
				longitude: new FormControl(user.contact.longitude, CustomValidators.numberRegex),
				address: new FormControl(user.contact.address)
			})
		});
	}

	toFormGroupUpdate(user: User): FormGroup {
		return this.fb.group({
			id: new FormControl(user.id),
			firstName: new FormControl(user.firstName, [Validators.required, Validators.maxLength(100)]),
			lastName: new FormControl(user.lastName, [Validators.required, Validators.maxLength(100)]),
			description: new FormControl(user.description),
			authUser: this.fb.group({
				email: new FormControl(user.authUser.email, [Validators.required, CustomValidators.emailRegex]),
				authGroup: new FormControl(user.authUser.authGroup),
				authRoles: new FormControl(user.authUser.authRoles)
			}),
			contact: this.fb.group({
				phone: new FormControl(user.contact.phone, Validators.maxLength(100)),
				phone2: new FormControl(user.contact.phone2, Validators.maxLength(100)),
				latitude: new FormControl(user.contact.latitude, CustomValidators.numberRegex),
				longitude: new FormControl(user.contact.longitude, CustomValidators.numberRegex),
				address: new FormControl(user.contact.address)
			})
		});
	}

	toFormGroupUpdatePassword(user: User): FormGroup {
		return this.fb.group({
			id: new FormControl(user.id),
			password: new FormControl('', Validators.required),
			passwordConfirm: new FormControl('', [Validators.required, CustomValidators.match('password')])
		});
	}
}
