import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { PartRequest } from './partRequest';
import { PartRequestService } from './partRequest.service';
import { PartModelService } from '../partModel/partModel.service';
import { NotificationService} from 'app/shared/notification/notification.service';
import { PartModel } from 'app/partModel/partModel';
import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { ServiceOrder } from 'app/serviceOrder/serviceOrder';
import { ServiceOrderService } from 'app/serviceOrder/serviceOrder.service';
import { ServiceRequest } from 'app/serviceRequest/serviceRequest';
import { ServiceRequestService } from 'app/serviceRequest/serviceRequest.service';
import { Machine } from 'app/machine/machine';
import { MachineService } from 'app/machine/machine.service';
import { Store } from 'app/store/store';

@Component({
	template: `
		<h3 class="title" i18n="@@update-partRequest">Update Part</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="update()">
			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-partRequest">Part data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="partModel" i18n="@@partModel">Part Model</label>
						<dropdown-ns [(control)]="form.controls['partModel']">
							<ng-template *ngFor="let object of partModels" dropdownOption [value]="{id: object.id}">
								<div class="extend">
									<div class="extend-main">{{object.name || '-'}}</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@code">Code</span>
											<span>{{object.code || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@price">Price</span>
											<span class="clickable-item" (click)="setPrice(object.price)">{{object.price || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@price2">Second price</span>
											<span class="clickable-item" (click)="setPrice(object.price2)">{{object.price2 || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@stock">Stock</span>
											<span>{{object.stock || '-'}}</span>
										</div>	
									</div>
								</div>

							</ng-template>
						</dropdown-ns>
						<validator [control]="form.controls['partModel']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="quantity" i18n="@@price">Price</label>
						<input formControlName="price" type="text" autocomplete="off">
						<validator [control]="form.controls['price']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="quantity" i18n="@@quantity">Quantity</label>
						<input formControlName="quantity" type="text" autocomplete="off">
						<validator [control]="form.controls['quantity']"></validator>
					</div>
				</div>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" i18n="@@description">Description</label>
						<textarea formControlName="description"></textarea>
					</div>
				</div>
			</fieldset>

			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-machine">Machine</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="machine" i18n="@@machine">Machine</label>
						<dropdown-ns [(control)]="form.controls['machine']" (changed)="changeMachine($event)">
							<ng-template dropdownOption [value]="null">
								<span i18n="@@select-none">None</span>
							</ng-template>
							<ng-template *ngFor="let object of machines" dropdownOption [value]="{id: object.id}">
								<div class="extend">
									<div class="extend-main">{{object.serial || '-'}}</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@machineModel-code">Machine Model - code</span>
											<span>{{object.machineModel?.name || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@machineModel-name">Machine Model - name</span>
											<span>{{object.machineModel?.comercialName || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@machineBrand">Machine Brand</span>
											<span>{{object.machineModel?.machineBrand?.name || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@machineType">Machine Type</span>
											<span>{{object.machineModel?.machineType?.name || '-'}}</span>
										</div>
									</div>
								</div>
							</ng-template>
						</dropdown-ns>
					</div>
				</div>
			</fieldset>

			<fieldset class="fieldset" *ngIf="serviceOrders">
				<legend class="legend" i18n="@@data-serviceRequest">Service</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="serviceOrder" i18n="@@serviceOrder">Order Service</label>
						<dropdown-ns [(control)]="form.controls['serviceOrder']" (changed)="changeServiceOrder($event)">
							<ng-template dropdownOption [value]="null">
								<span i18n="@@select-none">None</span>
							</ng-template>
							<ng-template *ngFor="let object of serviceOrders" dropdownOption [value]="{id: object.id}">
								<div class="extend">
									<div class="extend-main">{{object.id || '-'}}</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@company">Company</span>
											<span>{{object.store.company.name || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@store">Store</span>
											<span>{{object.store.name || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@createdAt">created at</span>
											<span>{{object.createdAt || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@status">Status</span>
											<span translate>{{object.statusServiceOrder.name || '-'}}</span>
										</div>
									</div>
								</div>
							</ng-template>
						</dropdown-ns>
					</div>
				</div>

				<div class="field-row"  *ngIf="serviceRequests">
					<div class="field-row-item">
						<label class="label" for="serviceRequest" i18n="@@serviceRequest">Service</label>
						<dropdown-ns [(control)]="form.controls['serviceRequest']">
							<ng-template dropdownOption [value]="null">
								<span i18n="@@select-none">None</span>
							</ng-template>
							<ng-template *ngFor="let object of serviceRequests" dropdownOption [value]="{id: object.id}">
								<div class="extend">
									<div class="extend-main">{{object.id || '-'}}</div>
									<div class="field-row">
										<div class="field-row">
											<div class="field-row-item">
												<span class="label" i18n="@@serviceType">Service type</span>
												<span>{{object.serviceType?.name || '-'}}</span>
											</div>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@machine-serial">Machine - serial</span>
											<span>{{object.machine?.serial || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@machineModel-code">Machine Model - code</span>
											<span>{{object.machine?.machineModel?.name || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@machineModel-name">Machine Model - name</span>
											<span>{{object.machine?.machineModel?.comercialName || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@machineType">Machine Type</span>
											<span>{{object.machine?.machineModel?.machineType?.name || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@machineBrand">Machine Brand</span>
											<span>{{object.machine?.machineModel?.machineBrand?.name || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@partRequests">Parts</span>
											<span>{{object.partRequests?.length || 0}}</span>
										</div>
									</div>
								</div>
							</ng-template>
						</dropdown-ns>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	`
})
export class PartRequestUpdateComponent implements OnInit {
	form: FormGroup;
	partModels: PartModel[];
	serviceOrders: ServiceOrder[];
	serviceRequests: ServiceRequest[];
	machines: Machine[];
	partRequest: PartRequest;
	machine: Machine;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private location: Location,
		private partRequestService: PartRequestService,
		private partModelService: PartModelService,
		private serviceOrderService: ServiceOrderService,
		private serviceRequestService: ServiceRequestService,
		private machineService: MachineService,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		this.activatedRoute.parent.params.subscribe(params => {
			if (params.hasOwnProperty('partRequestId')) {
				this.partRequestService.getById(
					params['partRequestId'],
					new HttpParams().set('collection', `id, quantity, price, totalPrice, description,
					partOrder(id, store(id)),
					partModel(id, code, name, price, price2, stock),
					serviceRequest(id,
						serviceOrder(id),
						machine(id)
					),
					machine(id)`)
				).subscribe(partRequest => {
					this.partRequest = partRequest;
					this.form = this.partRequestService.toFormGroup(partRequest);

					if (partRequest.machine) {
						this.machine = partRequest.machine;
						this.getServiceOrders(partRequest.partOrder.store);

						if (partRequest.serviceRequest) {
							this.getServiceRequests(partRequest.serviceRequest.serviceOrder);
						}
					}

					this.machineService.getAll(
						new HttpParams()
							.set('sort', 'id')
							.set('collection', 'id, serial, machineModel(id, name, comercialName, machineType(id, name), machineBrand(id, name))')
							.set('storeId', partRequest.partOrder.store.id.toString())
					).subscribe(data => {
						this.machines = data['result'];
					});
				});
			}
		});

		this.partModelService.getAll(
			new HttpParams()
				.set('sort', 'name')
				.set('collection', 'id, name, code, stock, price, price2')
		).subscribe(data => {
			this.partModels = data['result'];
		});
	}

	update() { console.log(<PartRequest> this.form.value);
		this.partRequestService.update(<PartRequest> this.form.value).subscribe(partRequest => {
			this.notificationService.sucessUpdate();
			this.location.back();
		}, err => this.notificationService.error(err));
	}

	setPrice(price: number) {
		this.form.patchValue({price: price});
	}

	getServiceOrders(store: Store) {
		this.serviceOrderService.getAll(
			new HttpParams()
				.set('sort', 'id')
				.set('collection', 'id, , createdAt, store(id, name, company(id, name)), statusServiceOrder(id, name))')
				.set('storeId', store.id.toString())
		).subscribe(data => {
			this.serviceOrders = data['result'];
		});
	}

	getServiceRequests(serviceOrder: ServiceOrder) {
		this.serviceRequestService.getAll(
			new HttpParams()
				.set('sort', 'id')
				.set('collection', 'id, machine(id, serial, machineModel(id, name, comercialName, machineType(id, name), machineBrand(id, name))), serviceType(id, name), partRequests(id)')
				.set('serviceOrderId', serviceOrder.id.toString())
				.set('machineId', this.machine.id.toString())
		).subscribe(data => {
			this.serviceRequests = data['result'];
		});
	}

	changeMachine(machine: Machine) {
		this.machine = machine;
		this.form.patchValue({serviceOrder: null});
		this.form.patchValue({serviceRequest: null});

		delete this.serviceRequests;

		if (machine) {
			this.getServiceOrders(this.partRequest.partOrder.store);
		} else {
			delete this.serviceOrders;
		}
	}

	changeServiceOrder(serviceOrder: ServiceOrder) {
		this.form.patchValue({serviceRequest: null});
		if (serviceOrder) {
			this.getServiceRequests(serviceOrder);
		} else {
			delete this.serviceRequests;
		}
	}

	setServiceRequest(serviceRequest: ServiceRequest) {
		this.form.patchValue({serviceRequest: {id: serviceRequest.id}});
	}
}
