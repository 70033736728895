
		<h3 class="title" i18n="@@create-serviceTask">Create Job Order</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="create()">
			<fieldset class="fieldset">
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="userEmployee" i18n="@@userEmployee">Employee User</label>
						<mat-select [formControl]="form.controls['userEmployee']" [compareWith]="compareFn" disableRipple>
							<mat-option i18n="@@select-none">None</mat-option>
							<mat-option *ngFor="let opt of userEmployees" [value]="opt">{{opt.firstName}} {{opt.lastName}}</mat-option>
						</mat-select>
						<validator [control]="form.controls['userEmployee']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="startDateTime" i18n="@@startDateTime">Start</label>
						<div class="datepicker">
							<input matInput [matDatepicker]="picker" [formControl]="form.controls['startDateTime']">
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						</div>
						<mat-datepicker #picker></mat-datepicker>
						<validator [control]="form.controls['startDateTime']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="statusServiceTask" i18n="@@statusServiceTask">Job Order Status</label>
						<mat-select [formControl]="form.controls['statusServiceTask']" [compareWith]="compareFn" disableRipple>
							<mat-option i18n="@@select-none">None</mat-option>
							<mat-option [value]="{id:'st_draft'}"><span i18n="@@st_draft">st_draft</span></mat-option>
						</mat-select>
						<validator [control]="form.controls['statusServiceTask']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="observation" i18n="@@observation">Observation</label>
						<textarea formControlName="observation"></textarea>
					</div>
				</div>
			</fieldset>

			<div class="options" style="justify-content: flex-end;">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	