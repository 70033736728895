import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from './user';
import { AuthService } from 'app/core/auth/auth.service';
import { UserService } from './user.service';

@Component({
	template: `
		<h3 class="title" i18n="@@detail-user">User Detail</h3>

		<div class="tool-bar-wrap both-side">
			<div class="right row">
				<button class="btn-icon" i18n-title="@@option-update-password" title="Update password" type="button" (click)="updatePassword()">
					<i class="material-icons">vpn_key</i>
				</button>
				<!--<button class="btn-icon" i18n-title="@@option-update" title="Update" type="button" (click)="update()">
					<i class="material-icons">edit</i>
				</button>-->
			</div>
		</div>

		<div class="fieldset-wrap">
			<div class="fieldset">
				<div class="legend" i18n="@@data-user">User data</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@email">Email</span>
						<span>{{user.authUser?.email || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@firstName">First name</span>
						<span>{{user.firstName || '-'}}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@lastName">Last name</span>
						<span>{{user.lastName || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@description">Description</span>
						<span>{{user.description || '-'}}</span>
					</div>
				</div>
			</div>
			<div class="fieldset">
				<div class="legend" i18n="@@data-contact">Contact data</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@phone">Phone</span>
						<span>{{user.contact?.phone || '-'}}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@phone2">Second phone</span>
						<span>{{user.contact?.phone2 || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@latitude">Latitude</span>
						<span>{{user.contact?.latitude || '-'}}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@longitude">Longitude</span>
						<span>{{user.contact?.longitude || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@address">Address</span>
						<span>{{user.contact?.address || '-'}}</span>
					</div>
				</div>
			</div>
		</div>
	`
})
export class UserReadComponent implements OnInit {
	user = new User();

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private authService: AuthService,
		private userService: UserService
	) {}

	ngOnInit() {
		const tokenPayload = this.authService.getDecodeToken(this.authService.getAccessToken());

		if (tokenPayload) {
			this.userService.getByAuthUserId(tokenPayload.id).subscribe(user => {
				this.user = user;
			});
		}
	}

	/*update() {
		this.router.navigate(['./password'], {relativeTo: this.activatedRoute});
	}*/

	updatePassword() {
		this.router.navigate([`./${this.user.id}/password`], {relativeTo: this.activatedRoute});
	}
}
