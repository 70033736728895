import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { NotificationService } from 'app/shared/notification/notification.service';

// refrence: https://medium.com/@ryanchenkie_40935/angular-authentication-using-route-guards-bf7a4ca13ae3
@Injectable()
export class AuthGuardService implements CanActivate {

	constructor(
		public authService: AuthService,
		public router: Router,
		private notificationService: NotificationService,
	) {}

	canActivate(route: ActivatedRouteSnapshot): boolean {
		if (this.authService.isAuthenticated()) {
			// this will be passed from the route config on the data property
			const expectedRoles = route.data.expectedRoles;

			if (expectedRoles) {
				// decode the token to get its payload
				const tokenPayload = this.authService.getDecodeToken(this.authService.getAccessToken());

				if ( !expectedRoles.some(expectedRole => { return expectedRole === tokenPayload.authGroup; }) ) {
					this.notificationService.errorGuard();
					return false;
				}
			}
			return true;
		} else {
			this.notificationService.errorGuard();
			this.authService.clearToken();
			return false;
		}
	}
}
