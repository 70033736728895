import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MachineModelService } from './machineModel.service';
import { Media } from 'app/media/media';
import { MediaService } from 'app/media/media.service';
import { MachineModel } from 'app/machineModel/machineModel';
import { NotificationService} from 'app/shared/notification/notification.service';
import { Global } from 'app/core/global';
import { HttpParams } from '@angular/common/http';
import { TableService } from 'app/core/models/pager';
import { PageEvent } from '@angular/material';

@Component({
	template: `
		<h3 class="title" i18n="@@update-machineModel-images">Update Machine Model images</h3>

		<div class="tool-bar-wrap both-side">
			<div class="right row">
				<ng-template [hasPermission]="['MediaController.createMachineModels']">
					<input id="file-upload" type="file" accept="image/png, image/jpeg" (change)="create($event)" multiple>
					<label class="btn-file" for="file-upload">
						<i class="material-icons">add</i>
					</label>
				</ng-template>

				<ng-template [hasPermission]="['MediaController.delete']">
					<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false" *ngIf="tableService.selection.hasValue()">
						<i class="material-icons">delete</i>
					</button>
				</ng-template>
			</div>
		</div>

		<gallery-image [items]="items"></gallery-image>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-deletes">Are you sure you want to delete the selected items?</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="deletes(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	`,
	providers: [
		TableService,
		MediaService
	]
})
export class MachineModelImageComponent implements OnInit {
	items: Media[] = new Array();
	machineModel: MachineModel;
	confirmDelete = true;

	constructor(
		private activatedRoute: ActivatedRoute,
		private machineModelService: MachineModelService,
		private mediaService: MediaService,
		public tableService: TableService<Media>,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		this.activatedRoute.parent.params.subscribe(params => {
			this.machineModel = new MachineModel();
			this.machineModel.id = params['machineModelId'];
			this.list();
		});
	}

	list(event?: PageEvent) {
		this.machineModelService.getById(
			this.machineModel.id,
			new HttpParams().set('collection', 'id, medias(id, multimediaCDN(*))')
		).subscribe(machineModel => {
			this.machineModel = machineModel;
			this.items = this.machineModel.medias.filter(item => item.multimediaCDN.mimeType.match(/^image.*/));
			this.tableService.selection.clear();
		});
	}

	create(event) {
		if (event.target.files && event.target.files.length > 0) {
			let files: FileList = event.target.files;
			let promises = [];
			// let observables = [];

			for (let i = 0; i < files.length; ++i) {
				let file = files.item(i);
				/*promises.push(new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = () => {
						let media = new Media();
						media.name = file.name.substring(0, file.name.lastIndexOf('.'));
						media.multimediaCDN.mediaBase64 = reader.result;
						resolve(media);
					};
					reader.onerror = error => reject(error);
				}));*/
				promises.push(new Promise((resolve, reject) => {
					MediaService.getBase64(file).then(data => {
						let media = new Media();
						media.name = file.name.substring(0, file.name.lastIndexOf('.'));
						media.multimediaCDN.mediaBase64 = data.toString();
						resolve(media);
					});
				}));

				/*observables.push(Observable.create(observer => {
					MediaService.getBase64(file).then(data => {
						let media = new Media();
						media.name = file.name.substring(0, file.name.lastIndexOf('.'));
						media.multimediaCDN.mediaBase64 = data.toString();
						observer.next(media);
						observer.complete();
					});
				}));*/
			}

			Promise.all(promises).then(items => {
				this.machineModelService.createMedias(this.machineModel.id, items).subscribe(medias => {
					this.notificationService.sucessInsert();
					this.items.push(...medias);
				}, err => this.notificationService.error(err));
			});
			/*forkJoin(promises).subscribe((results: Media[]) => {
				this.machineModelService.createMedias(this.machineModel.id, results).subscribe(medias => {
					this.items.push(...medias);
				});
			});*/
		}
	}

	deletes() {
		if (this.tableService.selection.selected.length === 1) {
			let item = this.tableService.selection.selected[0];
			this.mediaService.delete(item.id).subscribe(data => {
				this.notificationService.sucessDelete();
				this.list();
			}, err => this.notificationService.error(err));

		} else if (this.tableService.selection.selected.length > 1) {
			let keys = Global.getProperties(this.tableService.selection.selected, 'id');
			this.mediaService.deletes({'ids': keys}).subscribe(data => {
				this.notificationService.sucessDelete();
				this.list();
			}, err => this.notificationService.error(err));
		}
	}
}
