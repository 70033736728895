import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; 
import { SharedModule } from 'app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceRecordComponent } from './serviceRecord.component';
import { ServiceRecordListComponent } from './serviceRecord-list.component';
import { ServiceRecordCreateComponent } from './serviceRecord-create.component';
import { ServiceRecordUpdateComponent } from './serviceRecord-update.component';
import { ServiceRecordReadComponent } from './serviceRecord-read.component';
import { ServiceRecordService } from './serviceRecord.service';
import { dashboardRouting } from '../dashboard/dashboard.routes';
import { ContextMenuModule } from 'ngx-contextmenu';
import { MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule, MatDatepickerModule, MatNativeDateModule } from '@angular/material';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		dashboardRouting,
		FormsModule,
		ReactiveFormsModule,
		ContextMenuModule,
		MatTableModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule,
		MatDatepickerModule,
		MatNativeDateModule
	],
	declarations: [
		ServiceRecordComponent,
		ServiceRecordListComponent,
		ServiceRecordCreateComponent,
		ServiceRecordUpdateComponent,
		ServiceRecordReadComponent
	],
	providers: [
		ServiceRecordService
	],
})
export class ServiceRecordModule { }
