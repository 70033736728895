import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { RequestServiceComponent } from './requestService.component';
import { RequestServiceListComponent } from './requestService-list.component';
import { RequestServiceReadComponent } from './requestService-read.component';
import { RequestServiceService } from './requestService.service';
import { dashboardRouting } from '../dashboard/dashboard.routes';
import { ContextMenuModule } from 'ngx-contextmenu';
import { RequestServiceItemModule } from 'app/requestServiceItem/requestServiceItem.module';
import { MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule, MatExpansionModule, MatSelectModule } from '@angular/material';
import { RequestServiceCreateComponent } from './requestService-create.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		dashboardRouting,
		ReactiveFormsModule,
		RequestServiceItemModule,
		ContextMenuModule,
		MatTableModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule,
		MatExpansionModule,
		MatSelectModule
	],
	declarations: [
		RequestServiceComponent,
		RequestServiceCreateComponent,
		RequestServiceListComponent,
		RequestServiceReadComponent
	],
	providers: [
		RequestServiceService
	],
})
export class RequestServiceModule { }
