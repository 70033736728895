import { BaseModel } from 'app/core/models/base.model';
import { Company } from 'app/company/company';
import { Media } from 'app/media/media';

export class CompanyContract extends BaseModel {
	name: string;
	description: string;
	mediaContract: Media;
	company: Company;
}
