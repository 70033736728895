
		<div class="dd" [ngClass]="{'disabled': disabled}" (clickOutside)="hidden = true">
			<div class="select dd-select" (click)="this.hidden = !this.hidden">
				<div class="container-data">
					<ng-template *ngIf="control" [ngTemplateOutlet]="getOptionByValue(control.value)?.template"></ng-template>
					<ng-template *ngIf="!control && value" [ngTemplateOutlet]="getOptionByValue(value)?.template"></ng-template>
				</div>
			</div>

			<div class="dd-options" *ngIf="!disabled" [hidden]="hidden">
				<!--<div class="header">
				</div>-->
				<div class="body">
					<ul>
						<li *ngFor="let option of options" class="dd-options-item" [ngClass]="{'selected': isSelected(option.value)}" (click)="onSelect(option.value); hidden = true">
							<div class="container-data">
								<ng-template [ngTemplateOutlet]="option.template"></ng-template>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	