
		<h3 class="title" i18n="@@create-serviceRequest">Create Service</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="create()">
			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-serviceRequest">Service data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="serviceType" i18n="@@serviceType">Service Type</label>
						<mat-select [formControl]="form.controls['serviceType']" [compareWith]="compareFn" (selectionChange)="getMachines($event.value)" disableRipple>
							<mat-option i18n="@@select-none">None</mat-option>
							<mat-option *ngFor="let opt of serviceTypes" [value]="opt">{{opt.name}}</mat-option>
						</mat-select>
						<validator [control]="form.controls['serviceType']"></validator>
					</div>
				</div>
				<div class="field-row" *ngIf="machines">
					<div class="field-row-item">
						<label class="label" for="machines" i18n="@@machines">Machines</label>
						<dropdown-multi-ns [(control)]="form.controls['machines']">
							<ng-template *ngFor="let object of machines" dropdownOption [value]="{id: object.id}">
								<div class="extend">
									<div class="extend-main">
										<span class="clickable-item" (click)="getMachine(object)">{{object.serial || '-'}}</span>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@status">Status</span>
											<span translate>{{object.statusMachine?.name || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@usedDistance">Used distance (kms)</span>
											<span>{{object.usedDistance || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@maintenenceFrecuency">Maintenence frecuency</span>
											<span>{{object.maintenenceFrecuency || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@maintenencePerDistance">Maintenence per distance (kms)</span>
											<span>{{object.maintenencePerDistance || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@machineType">Machine Type</span>
											<span>{{object.machineModel?.machineType?.name || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@machineBrand">Machine Brand</span>
											<span>{{object.machineModel?.machineBrand?.name || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@machineModel-name">Machine Model - name</span>
											<span>{{object.machineModel?.comercialName || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@machineModel-code">Machine Model - code</span>
											<span>{{object.machineModel?.name || '-'}}</span>
										</div>
									</div>
								</div>
							</ng-template>
						</dropdown-multi-ns>
						<validator [control]="form.controls['machines']"></validator>
					</div>
				</div>

				<!--<div class="field-row" *ngIf="partRequests">
					<div class="field-row-item">
						<label class="label" for="partRequest" i18n="@@partRequest">Part Request</label>
						<dropdown-multi-ns [(control)]="form.controls['partRequests']">
							<ng-template *ngFor="let object of partRequests" dropdownOption [value]="{id: object.id}">
								<div class="extend">
									<div class="extend-main">{{object.id || '-'}}</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@partModel-code">Part Model - code</span>
											<span>{{object.partModel.code || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@partModel-name">Part Model - name</span>
											<span>{{object.partModel.name || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@quantity">Quantity</span>
											<span>{{object.quantity || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@price">Price</span>
											<span>{{object.price || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@totalPrice">Total price</span>
											<span>{{object.totalPrice || '-'}}</span>
										</div>
									</div>
									<div class="field-row">
										<div class="field-row-item">
											<span class="label" i18n="@@machineType">Machine Type</span>
											<span>{{object.machine.machineModel.machineType.name || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@machineBrand">Machine Brand</span>
											<span>{{object.machine.machineModel.machineBrand.name || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@machineModel-name">Machine Model - name</span>
											<span>{{object.machine.machineModel.comercialName || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@machineModel-code">Machine Model - code</span>
											<span>{{object.machine.machineModel.name || '-'}}</span>
										</div>
										<div class="field-row-item">
											<span class="label" i18n="@@machineType">Machine - serial</span>
											<span>{{object.machine.serial || '-'}}</span>
										</div>
									</div>
								</div>
							</ng-template>
						</dropdown-multi-ns>
					</div>
				</div>-->

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="observation" i18n="@@observation">Observation</label>
						<textarea formControlName="observation"></textarea>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	