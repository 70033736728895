import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { MachineBrand } from './machineBrand';
import { MachineBrandService } from './machineBrand.service';
import { NotificationService} from 'app/shared/notification/notification.service';

@Component({
	template: `
		<h3 class="title" i18n="@@create-machineBrand">Create Machine Brand</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="create()">
			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-machine">Machine data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="name" i18n="@@name">Name</label>
						<input formControlName="name" type="text" autocomplete="off">
						<validator [control]="form.controls['name']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" i18n="@@description">Description</label>
						<textarea formControlName="description"></textarea>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	`
})
export class MachineBrandCreateComponent {
	form: FormGroup;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private machineBrandService: MachineBrandService,
		private notificationService: NotificationService,
	) {
		this.form = this.machineBrandService.toFormGroup(new MachineBrand());
	}

	create() {
		this.machineBrandService.create(this.form.value).subscribe(machineBrand => {
			this.notificationService.sucessInsert(machineBrand.name);
			this.router.navigate([`../${machineBrand.id}`], {relativeTo: this.activatedRoute});
		}, err => this.notificationService.error(err));
	}
}
