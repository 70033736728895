
		<div i18n="@@signin">
			<div class="dialog-title">Sign in</div>
			<div class="dialog-message">Enter your details below</div>
		</div>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="login()">
			<fieldset class="fieldset">
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="email" i18n="@@email">Email</label>
						<input type="text" formControlName="email" class="form-control">
						<validator [control]="form.controls['email']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="password" i18n="@@password">Password</label>
						<input type="password" formControlName="password" class="form-control">
						<validator [control]="form.controls['password']"></validator>
						<div class="clickable-item" style="text-align: right" routerLink="/recoverPass" i18n="@@forgot-password">Forgot your password?</div>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@login">Sign in</span>
				</button>
			</div>
		</form>
	