import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { MachineModel } from './machineModel';
import { MachineModelService } from './machineModel.service';
import { NotificationService} from 'app/shared/notification/notification.service';
import { MachineType } from 'app/machineType/machineType';
import { MachineBrand } from 'app/machineBrand/machineBrand';
import { MachineTypeService } from 'app/machineType/machineType.service';
import { MachineBrandService } from 'app/machineBrand/machineBrand.service';
import { HttpParams } from '@angular/common/http';

@Component({
	template: `
		<h3 class="title" i18n="@@create-machineModel">Create Machine Model</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="create()">
			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-machineModel">Machine Model data</legend>
				
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="name" i18n="@@code">Code</label>
						<input formControlName="name" type="text" autocomplete="off">
						<validator [control]="form.controls['name']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="comercialName" i18n="@@name">Name</label>
						<input formControlName="comercialName" type="text" autocomplete="off">
						<validator [control]="form.controls['comercialName']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="price" i18n="@@price">Price</label>
						<input formControlName="price" type="text" autocomplete="off">
						<validator [control]="form.controls['price']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="machineType" i18n="@@machineType">Machine Type</label>
						<mat-select [formControl]="form.controls['machineType']" [compareWith]="compareFn" disableRipple>
							<mat-option i18n="@@select-none">None</mat-option>
							<mat-option *ngFor="let opt of machineTypes" [value]="opt">{{opt.name}}</mat-option>
						</mat-select>
						<validator [control]="form.controls['machineType']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="machineBrand" i18n="@@machineBrand">Machine Brand</label>
						<mat-select [formControl]="form.controls['machineBrand']" [compareWith]="compareFn" disableRipple>
							<mat-option i18n="@@select-none">None</mat-option>
							<mat-option *ngFor="let opt of machineBrands" [value]="opt">{{opt.name}}</mat-option>
						</mat-select>
						<validator [control]="form.controls['machineBrand']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" i18n="@@description">Description</label>
						<textarea formControlName="description"></textarea>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	`
})
export class MachineModelCreateComponent implements OnInit {
	form: FormGroup;
	machineTypes: MachineType[];
	machineBrands: MachineBrand[];

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private machineModelService: MachineModelService,
		private machineTypeService: MachineTypeService,
		private machineBrandService: MachineBrandService,
		private notificationService: NotificationService,
	) {
		this.form = this.machineModelService.toFormGroup(new MachineModel());
	}

	ngOnInit() {
		let httpParams = new HttpParams().set('sort', 'name').set('collection', 'id, name');
		this.machineTypeService.getAll(httpParams).subscribe(data => {
			this.machineTypes = data['result'];
		});

		this.machineBrandService.getAll(httpParams).subscribe(data => {
			this.machineBrands = data['result'];
		});
	}

	create() {
		this.machineModelService.create(this.form.value).subscribe(machineModel => {
			this.notificationService.sucessInsert(machineModel.name);
			this.router.navigate([`../${machineModel.id}`], {relativeTo: this.activatedRoute});
		}, err => this.notificationService.error(err));
	}

	compareFn(c1: any, c2: any): boolean {
		return c1 && c2 ? c1.id === c2.id : c1 === c2;
	}
}
