
		<h3 class="title" i18n="@@userClients">Client Users</h3>

		<mat-accordion>
			<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
				<mat-expansion-panel-header>
					<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
						<div class="field-row">
							<div class="field-row-item" *ngIf="tableService.filter.email">
								<span class="label" i18n="@@email">Email</span>
								<span>{{tableService.filter.email}}</span>
							</div>
							<div class="field-row-item" *ngIf="tableService.filter.firstName">
								<span class="label" i18n="@@firstName">firstName</span>
								<span>{{tableService.filter.firstName}}</span>
							</div>
							<div class="field-row-item" *ngIf="tableService.filter.lastName">
								<span class="label" i18n="@@lastName">lastName</span>
								<span>{{tableService.filter.lastName}}</span>
							</div>
							<div class="field-row-item" *ngIf="tableService.filter.company">
								<span class="label" i18n="@@company">company</span>
								<span>{{tableService.filter.company.name}}</span>
							</div>
						</div>
					</div>
					<button class="btn-icon" i18n-title="@@option-search" title="Search" type="button">
						<i class="material-icons">search</i>
					</button>
				</mat-expansion-panel-header>

				<form>
					<fieldset class="fieldset">
						<div class="field-row">
							<div class="field-row-item">
								<label class="label" for="email" i18n="@@email">Email</label>
								<input type="text" name="email" placeholder="None" i18n-placeholder="None" [(ngModel)]="filter.email">
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<label class="label" for="firstName" i18n="@@firstName">First name</label>
								<input type="text" name="firstName" placeholder="None" i18n-placeholder="None" [(ngModel)]="filter.firstName">
							</div>
							<div class="field-row-item">
								<label class="label" for="lastName" i18n="@@lastName">Last name</label>
								<input type="text" name="lastName" placeholder="None" i18n-placeholder="None" [(ngModel)]="filter.lastName">
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<label class="label" for="company" i18n="@@company">Company</label>
								<mat-select placeholder="None" i18n-placeholder="None" [(value)]="filter.company" [compareWith]="compareFn" disableRipple>
									<mat-option i18n="@@select-none">None</mat-option>
									<mat-option *ngFor="let opt of companies" [value]="opt">{{opt.name}}</mat-option>
								</mat-select>
							</div>
						</div>
					</fieldset>
					<div class="options">
						<button class="btn-text gray" type="button" (click)="reset()">
							<span i18n="@@option-reset">Reset</span>
						</button>
						<button class="btn-text blue" type="button" (click)="search()">
							<span i18n="@@option-search">Search</span>
						</button>
					</div>
				</form>
			</mat-expansion-panel>
		</mat-accordion>

		<div class="tool-bar-wrap both-side">
			<div class="right row">
				<button class="btn-icon" i18n-title="@@option-create" type="button" (click)="create()" *hasPermission="['UserClients.create']">
					<i class="material-icons">add</i>
				</button>
				<ng-template [hasPermission]="['UserClients.delete']">
					<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false" *ngIf="tableService.selection.hasValue()">
						<i class="material-icons">delete</i>
					</button>
				</ng-template>
			</div>
		</div>

		<div class="mat-elevation-z8e" *ngIf="dataSource">
			<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
				<ng-container matColumnDef="select">
					<th mat-header-cell *matHeaderCellDef>
						<mat-checkbox (change)="tableService.masterToggle(dataSource.data)"
							[checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSource.data.length)"
							[indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSource.data.length)"
							*hasPermission="['UserClients.delete']">
						</mat-checkbox>
					</th>
					<td mat-cell *matCellDef="let row">
						<mat-checkbox (click)="$event.stopPropagation()"
							(change)="tableService.selection.toggle(row)"
							[checked]="tableService.selection.isSelected(row)"
							*hasPermission="['UserClients.delete']">
						</mat-checkbox>
					</td>
				</ng-container>

				<ng-container matColumnDef="email">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@email">Email</th>
					<td mat-cell *matCellDef="let element">{{element.authUser?.email}}</td>
				</ng-container>

				<ng-container matColumnDef="firstName">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@firstName">First name</th>
					<td mat-cell *matCellDef="let element">{{element.firstName}}</td>
				</ng-container>

				<ng-container matColumnDef="lastName">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@lastName">Last name</th>
					<td mat-cell *matCellDef="let element">{{element.lastName}}</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"
					(contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row"
					(click)="read(row)">
				</tr>
			</table>

			<mat-paginator showFirstLastButtons
				[length]="tableService.pager.totalEntities"
				[pageIndex]="tableService.pager.pageIndex"
                [pageSize]="tableService.pager.pageSize"
				[pageSizeOptions]="[20, 50, 100]"
				(page)="list($event)">
			</mat-paginator>
		</div>

		<context-menu #basicMenu>
			<ng-template [hasPermission]="['Companies.findAll']">
				<ng-template contextMenuItem (execute)="getCompanies($event.item)">
					<div class="item">
						<span i18n="@@companies">Companies</span>
					</div>
				</ng-template>
			</ng-template>

			<ng-template [hasPermission]="['UserClients.update']">
				<ng-template contextMenuItem divider="true"></ng-template>
				<ng-template contextMenuItem (execute)="update($event.item)">
					<div class="item">
						<i class="material-icons">edit</i>
						<span i18n="@@option-update">Update</span>
					</div>
				</ng-template>
				<ng-template contextMenuItem (execute)="updatePassword($event.item)">
					<div class="item">
						<i class="material-icons">vpn_key</i>
						<span i18n="@@option-update-password">Update password</span>
					</div>
				</ng-template>
			</ng-template>

			<ng-template [hasPermission]="['UserClients.delete']">
				<ng-template contextMenuItem divider="true"></ng-template>
				<ng-template contextMenuItem (execute)="confirmDelete = false">
					<div class="item">
						<i class="material-icons">delete</i>
						<span i18n="@@option-delete">Delete</span>
					</div>
				</ng-template>
			</ng-template>
		</context-menu>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-deletes">Are you sure you want to delete the selected items?</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="deletes(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	