import { BaseModel } from 'app/core/models/base.model';
import { Store } from '../store/store';
import { ServiceRequest } from '../serviceRequest/serviceRequest';
import { Media } from '../media/media';
import { Status } from 'app/status/status';
import { User } from 'app/user/user';

export class ServiceOrder extends BaseModel {
	totalPrice: number;
	progress: number;
	description: string;
	mediaSignature: Media;
	store: Store;
	statusServiceOrder: Status;
	updatedBy: User;
	serviceRequests: ServiceRequest[];
	serviceRequestsLength: number;
	serviceTasksLength: number;
	serviceTasksDraftLength: number;
	serviceTasksApprovedLength: number;
	serviceTasksDoneLength: number;
	serviceTasksCancelledLength: number;
	serviceTasksMissedLength: number;
	serviceTasksExpiredLength: number;
}
