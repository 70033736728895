import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from 'app/core/base.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthGroupService {
	private static readonly BASE_URL: string = BaseService.HOST + '/authGroups';

	constructor(private http: HttpClient) {}

	getAll(params?: HttpParams): Observable<any> {
		return this.http.get(AuthGroupService.BASE_URL, {params: params})
			.pipe(catchError(BaseService.handleError));
	}
}
