
		<h3 class="title" i18n="@@detail-partOrder">Part Order Detail</h3>

		<ng-container *ngIf="partOrder">
			<div class="tool-bar-wrap both-side">
				<div class="right row">
					<ng-template [hasPermission]="['RequestParts.delete']">
						<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false">
							<i class="material-icons">delete</i>
						</button>
					</ng-template>
				</div>
			</div>

			<div class="fieldset-wrap" style="margin-bottom: 30px;">
				<div class="fieldset both-side">
					<div>
						<div class="legend" i18n="@@data-partOrder">Part Order data</div>
						<div class="field-row">
							<div class="field-row-item">
								<span class="label" i18n="@@id">Id</span>
								<span>{{partOrder.id || '-'}}</span>
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<span class="label" i18n="@@type">Type</span>
								<span>{{partOrder.dtype || '-'}}</span>
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<span class="label" i18n="@@company">Company</span>
								<span>{{partOrder.store?.company?.name || '-'}}</span>
							</div>
							<div class="field-row-item">
								<span class="label" i18n="@@store">Branch</span>
								<span>{{partOrder.store?.name || '-'}}</span>
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<span class="label" i18n="@@createdAt">Created date</span>
								<span>{{partOrder.createdAt | date:'medium' || '-'}}</span>
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<span class="label" i18n="@@description">Description</span>
								<span>{{partOrder.description || '-'}}</span>
							</div>
						</div>
					</div>

					<div class="right row" *hasPermission="['RequestParts.updateStatusPartOrder']">
						<dropdown-ns [value]="{id: partOrder.statusPartOrder.id}" (changed)="updateStatusPartOrder($event)">
							<ng-template *ngIf="po_draft()" dropdownOption [value]="{id: 'po_draft'}">
								<span i18n="@@po_draft">Draft</span>
							</ng-template>
							<ng-template *ngIf="po_requested('selector')" dropdownOption [value]="{id: 'po_requested'}">
								<span i18n="@@po_requested">Requested</span>
							</ng-template>
							<ng-template *ngIf="po_revision('selector')" dropdownOption [value]="{id: 'po_revision'}">
								<span i18n="@@po_revision">Revision</span>
							</ng-template>
							<ng-template *ngIf="po_rejected('selector')" dropdownOption [value]="{id: 'po_rejected'}">
								<span i18n="@@po_rejected">Rejected</span>
							</ng-template>
						</dropdown-ns>

						<button *ngIf="po_requested('button')"
							class="btn-status requested"
							(click)="updateStatusPartOrder({id: 'po_requested'})"
							i18n="@@po_request">Request</button>
						<button *ngIf="po_rejected('button')"
							class="btn-status rejected"
							(click)="updateStatusPartOrder({id: 'po_rejected'})"
							i18n="@@po_reject">Reject</button>
						<button *ngIf="po_revision('button')"
							class="btn-status approved"
							(click)="updateStatusPartOrder({id: 'po_revision'})"
							i18n="@@po_revision">Revision</button>
					</div>
				</div>
			</div>
		</ng-container>

		<requestPartItem-list *ngIf="partOrder" [partOrder]="partOrder"></requestPartItem-list>
	