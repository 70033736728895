
		<ng-container *ngIf="items?.length > 0 else empty">
			<div class="container-checkbox row">
				<mat-checkbox (change)="tableService.masterToggle(items)"
					[checked]="tableService.selection.hasValue() && tableService.isAllSelected(items.length)"
					[indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(items.length)">
				</mat-checkbox>
			</div>

			<ul class="container-items">
				<li *ngFor="let item of items" [ngClass]="{'selected': tableService.selection.isSelected(item)}">
					<div>
						<img class="thumbnail" [src]="item.multimediaCDN.url" alt="{{item.name}}" height="150" (click)="tableService.selection.toggle(item)">
					</div>
				</li>
			</ul>
		</ng-container>
		<ng-template #empty>
			<div class="table-empty" i18n="@@no-records">Records not found</div>
		</ng-template>

	