import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BaseService } from 'app/core/base.service';
import { PartOrder } from '../partOrder/partOrder';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class RequestPartService {
	private static readonly BASE_URL: string = BaseService.HOST + '/requestParts';

	constructor(
		private http: HttpClient,
		private fb: FormBuilder
	) {}

	getById(id: number): Observable<PartOrder> {
		return this.http.get(RequestPartService.BASE_URL + '/' + id)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	getAll(params: HttpParams): Observable<any> {
		return this.http.get(RequestPartService.BASE_URL, {params: params})
			.pipe(catchError(BaseService.handleError));
	}

	create(partOrder: PartOrder): Observable<PartOrder> {
		return this.http.post(RequestPartService.BASE_URL, partOrder)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	update(partOrder: PartOrder): Observable<PartOrder> {
		return this.http.put(RequestPartService.BASE_URL + '/' + partOrder.id, partOrder)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	updateStatusPartOrder(partOrder: PartOrder): Observable<PartOrder> {
		return this.http.patch(RequestPartService.BASE_URL + '/' + partOrder.id + '/statusPartOrder', partOrder)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	delete(id: number): Observable<any> {
		return this.http.delete(RequestPartService.BASE_URL + '/' + id)
			.pipe(catchError(BaseService.handleError));
	}

	deletes(requestParts: PartOrder[]): Observable<any> {
		return this.http.post(RequestPartService.BASE_URL + '/delete', requestParts)
			.pipe(catchError(BaseService.handleError));
	}

	toFormGroup(partOrder: PartOrder): FormGroup {
		return this.fb.group({
			id: new FormControl(partOrder.id),
			description: new FormControl(partOrder.description),
			store: new FormControl(partOrder.store, Validators.required),
			statusPartOrder: new FormControl(
				partOrder.statusPartOrder ? {id: partOrder.statusPartOrder.id} : undefined,
				Validators.required
			)
		});
	}
}
