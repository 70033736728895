import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './core/auth/auth.service';
import { UserService } from './user/user.service';
import { AuthPermissionService } from './auth/authPermission.service';
import { HttpParams } from '@angular/common/http';
import { AuthPermission } from './auth/authPermission';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

	constructor(
		private authService: AuthService,
		private userService: UserService,
		private authPermissionService: AuthPermissionService,
		@Inject(LOCALE_ID) locale: string,
		translate: TranslateService
	) {
		// console.log('locale', locale); // result => locale es

		// this language will be used as a fallback when a translation isn't found in the current language
		translate.setDefaultLang('en');

		// the lang to use, if the lang isn't available, it will use the current loader to get them
		translate.use(locale);
	}

	ngOnInit() {
		if (this.authService.getRefreshToken() && this.authService.getAccessToken()) {
			const tokenPayload = this.authService.getDecodeToken(this.authService.getAccessToken());

			if (tokenPayload && tokenPayload.id) {
				let httpParams = new HttpParams().set('collection', '*, authUser(*, authGroup(id, name))');

				this.userService.getByAuthUserId(tokenPayload.id, httpParams).subscribe(user => {
					httpParams = new HttpParams()
						.set('authUserId', user.authUser.id.toString())
						.set('collection', 'name');

					this.authPermissionService.getAll(httpParams).subscribe(data => {
						let authPermissions: AuthPermission[] = data.result;
						user.permissions = authPermissions.map(authPermission => authPermission.name);
						this.authService.setCurrentUser(user);
					}, () => this.authService.clearToken());
				}, () => this.authService.clearToken());

			} else {
				this.authService.clearToken();
			}
		} else {
			this.authService.clearToken();
		}
	}
}
