import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { RequestPartItemComponent } from './requestPartItem.component';
import { RequestPartItemListComponent } from './requestPartItem-list.component';
import { RequestPartItemCreateComponent } from './requestPartItem-create.component';
import { RequestPartItemService } from './requestPartItem.service';
import { dashboardRouting } from '../dashboard/dashboard.routes';
import { ContextMenuModule } from 'ngx-contextmenu';
import { MatTableModule, MatSortModule, MatCheckboxModule } from '@angular/material';
import { RequestPartItemReadComponent } from './requestPartItem-read.component';
import { RequestPartItemUpdateComponent } from './requestPartItem-update.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		dashboardRouting,
		FormsModule,
		ReactiveFormsModule,
		ContextMenuModule,
		MatTableModule,
		MatSortModule,
		MatCheckboxModule
	],
	declarations: [
		RequestPartItemComponent,
		RequestPartItemListComponent,
		RequestPartItemCreateComponent,
		RequestPartItemReadComponent,
		RequestPartItemUpdateComponent
	],
	exports: [
		RequestPartItemListComponent
	],
	providers: [
		RequestPartItemService
	]
})
export class RequestPartItemModule { }
