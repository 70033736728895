import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { UserEmployeeComponent } from './userEmployee.component';
import { UserEmployeeListComponent } from './userEmployee-list.component';
import { UserEmployeeReadComponent } from './userEmployee-read.component';
import { UserEmployeeCreateComponent } from './userEmployee-create.component';
import { UserEmployeeUpdateComponent } from './userEmployee-update.component';
import { UserEmployeeUpdatePasswordComponent } from './userEmployee-update-password.component';
import { UserEmployeeService } from './userEmployee.service';
import { UserService } from '../user/user.service';
import { dashboardRouting } from '../dashboard/dashboard.routes';
import { AuthRoleService } from '../auth/authRole.service';
import { ContextMenuModule } from 'ngx-contextmenu';
import { MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule, MatExpansionModule } from '@angular/material';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		dashboardRouting,
		FormsModule,
		ReactiveFormsModule,
		ContextMenuModule,
		MatTableModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule,
		MatExpansionModule
	],
	declarations: [
		UserEmployeeComponent,
		UserEmployeeListComponent,
		UserEmployeeReadComponent,
		UserEmployeeCreateComponent,
		UserEmployeeUpdateComponent,
		UserEmployeeUpdatePasswordComponent
	],
	providers: [
		UserEmployeeService,
		UserService,
		AuthRoleService
	],
})
export class UserEmployeeModule { }
