import { BaseModel } from 'app/core/models/base.model';
import { MachineModel } from 'app/machineModel/machineModel';
import { Media } from 'app/media/media';

export class PartModel extends BaseModel {
	code: string;
	name: string;
	description: string;
	stock: number;
	price: number;
	price2: number;
	machineModel: MachineModel;
	medias: Array<Media>;
}
