import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from 'app/core/base.service';
import { MachineType } from './machineType';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class MachineTypeService {
	private static readonly BASE_URL: string = BaseService.HOST + '/machineTypes';

	constructor(
		private http: HttpClient,
		private fb: FormBuilder
	) {}

	getById(id: number): Observable<MachineType> {
		return this.http.get(MachineTypeService.BASE_URL + '/' + id)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	getAll(params: HttpParams): Observable<any> {
		return this.http.get(MachineTypeService.BASE_URL, {params: params})
			.pipe(catchError(BaseService.handleError));
	}

	create(machineType: MachineType): Observable<MachineType> {
		return this.http.post(MachineTypeService.BASE_URL, machineType)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	update(machineType: MachineType): Observable<MachineType> {
		return this.http.put(MachineTypeService.BASE_URL + '/' + machineType.id, machineType)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	delete(id: number): Observable<any> {
		return this.http.delete(MachineTypeService.BASE_URL + '/' + id)
			.pipe(catchError(BaseService.handleError));
	}

	deletes(machineTypes: MachineType[]): Observable<any> {
		return this.http.post(MachineTypeService.BASE_URL + '/delete', machineTypes)
			.pipe(catchError(BaseService.handleError));
	}

	toFormGroup(machineType: MachineType): FormGroup {
		return this.fb.group({
			id: new FormControl(machineType.id),
			name: new FormControl(machineType.name, [Validators.required, Validators.maxLength(100)]),
			description: new FormControl(machineType.description)
		});
	}
}
