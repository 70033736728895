import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { BaseService } from 'app/core/base.service';
import { UserGeneric } from './userGeneric';
import { UserService } from 'app/user/user.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class UserGenericService {
	private static readonly BASE_URL: string = BaseService.HOST + '/userGenerics';

	constructor(
		private http: HttpClient,
		private userService: UserService
	) {}

	getById(id: number): Observable<UserGeneric> {
		return this.http.get(UserGenericService.BASE_URL + '/' + id)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	getAll(params: HttpParams): Observable<any> {
		return this.http.get(UserGenericService.BASE_URL, {params: params})
			.pipe(catchError(BaseService.handleError));
	}

	create(userGeneric: UserGeneric): Observable<UserGeneric> {
		return this.http.post(UserGenericService.BASE_URL, userGeneric)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	update(userGeneric: UserGeneric): Observable<UserGeneric> {
		return this.http.put(UserGenericService.BASE_URL + '/' + userGeneric.id, userGeneric)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	delete(id: number): Observable<any> {
		return this.http.delete(UserGenericService.BASE_URL + '/' + id)
			.pipe(catchError(BaseService.handleError));
	}

	deletes(userGenerics: UserGeneric[]): Observable<any> {
		return this.http.post(UserGenericService.BASE_URL + '/delete', userGenerics)
			.pipe(catchError(BaseService.handleError));
	}

	toFormGroup(userGeneric: UserGeneric): FormGroup {
		return this.userService.toFormGroup(userGeneric);
	}

	toFormGroupUpdate(userGeneric: UserGeneric): FormGroup {
		return this.userService.toFormGroupUpdate(userGeneric);
	}

	toFormGroupUpdatePassword(userGeneric: UserGeneric): FormGroup {
		return this.userService.toFormGroupUpdatePassword(userGeneric);
	}
}
