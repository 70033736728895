export class Selector {
	private items: any[];

	constructor();
	constructor(items: any[]);
	constructor(items?: any[]) {
		if (items) {
			this.items = items;
		}
	}

	add(item: any) {
		if (item && !this.isSelected(item)) {
			if (!this.items) { this.items = []; }
			this.items.push(item);
		}
	}

	addMulti(items: any[]) {
		if (!items) { return; }
		items.forEach(item => this.add(item));
	}

	select(item: any) {
		if (!item) { return; }
		this.items = [item];
	}

	selectMulti(items: any[]) {
		if (!items) { return; }
		this.items = items.slice();
	}

	toggle(item: any) {
		if (!item) { return; }
		if (!this.items) { this.items = []; }
		const index = this.items.findIndex(obj => this.isEqual(obj, item));
		index > -1 ?
			this.items.splice(index, 1) :
			this.items.push(item);
	}

	masterToggle(items: any[]) {
		if (!items) { return; }
		if (!this.items) { this.items = []; }
		items.length === 0 || items.length === this.items.length ?
			this.items = [] :
			this.items = items.slice();
	}

	clear() {
		this.items = [];
	}

	isSelected(item: any): boolean {
		if (item && this.items && this.items.find(obj => this.isEqual(obj, item))) {
			return true;
		};
		return false;
	}

	isAllSelected(items: any[]): boolean {
		if (items && this.items && items.length > 0 && items.length === this.items.length) {
			return true;
		}
		return false;
	}

	hasValue(): boolean {
		return this.items && this.items.length > 0
	}

	getItems(): any[] {
		return this.items;
	}

	length(): number {
		if (!this.items) { return 0; }
		return this.items.length;
	}

	private isEqual(objA, objB) {
		return JSON.stringify(objA) === JSON.stringify(objB);
	}
}