import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { MachineModelComponent } from './machineModel.component';
import { MachineModelListComponent } from './machineModel-list.component';
import { MachineModelReadComponent } from './machineModel-read.component';
import { MachineModelCreateComponent } from './machineModel-create.component';
import { MachineModelUpdateComponent } from './machineModel-update.component';
import { MachineModelService } from './machineModel.service';
import { MachineModelImageComponent } from './machineModel-media-image.component';
import { MachineModelPdfComponent } from './machineModel-media-pdf.component';
import { dashboardRouting } from 'app/dashboard/dashboard.routes';
import { ContextMenuModule } from 'ngx-contextmenu';
import { MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule, MatExpansionModule, MatSelectModule } from '@angular/material';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		dashboardRouting,
		FormsModule,
		ReactiveFormsModule,
		ContextMenuModule,
		MatTableModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule,
		MatExpansionModule,
		MatSelectModule
	],
	declarations: [
		MachineModelComponent,
		MachineModelListComponent,
		MachineModelReadComponent,
		MachineModelCreateComponent,
		MachineModelUpdateComponent,
		MachineModelImageComponent,
		MachineModelPdfComponent
	],
	providers: [
		MachineModelService,
	],
})
export class MachineModelModule { }
