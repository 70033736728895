
		<h3 class="title" i18n="@@update-userGeneric">Update Generic User</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="update()">
			<fieldset class="fieldset" formGroupName="authUser">
				<legend class="legend" i18n="@@data-account">Account data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="email" i18n="@@email">Email</label>
						<input formControlName="email" type="text" autocomplete="off">
						<validator [control]="form.controls['authUser'].controls['email']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="authGroup" i18n="@@authGroup">authGroup</label>
						<mat-select [formControl]="form.controls['authUser'].controls['authGroup']" [compareWith]="compareFn" disableRipple>
							<mat-option i18n="@@select-none">None</mat-option>
							<mat-option *ngFor="let opt of authGroups" [value]="opt">{{opt.name}}</mat-option>
						</mat-select>
						<validator [control]="form.controls['authUser'].controls['authGroup']"></validator>
					</div>
				</div>
			</fieldset>

			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-userGeneric">Generic User data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="firstName" i18n="@@firstName">First name</label>
						<input formControlName="firstName" type="text" autocomplete="off">
						<validator [control]="form.controls['firstName']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="lastName" i18n="@@lastName">Last name</label>
						<input formControlName="lastName" type="text" autocomplete="off">
						<validator [control]="form.controls['lastName']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" i18n="@@description">Description</label>
						<textarea formControlName="description"></textarea>
					</div>
				</div>
			</fieldset>

			<fieldset class="fieldset" formGroupName="contact">
				<legend class="legend" i18n="@@data-contact">Contact data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="phone" i18n="@@phone">Phone</label>
						<input formControlName="phone" type="text" autocomplete="off">
						<validator [control]="form.controls['contact'].controls['phone']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="phone2" i18n="@@phone2">Second phone</label>
						<input formControlName="phone2" type="text" autocomplete="off">
						<validator [control]="form.controls['contact'].controls['phone2']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="latitude" i18n="@@latitude">Latitude</label>
						<input formControlName="latitude" type="text" autocomplete="off">
						<validator [control]="form.controls['contact'].controls['latitude']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="longitude" i18n="@@longitude">Longitude</label>
						<input formControlName="longitude" type="text" autocomplete="off">
						<validator [control]="form.controls['contact'].controls['longitude']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="address" i18n="@@address">Address</label>
						<textarea formControlName="address"></textarea>
					</div>
				</div>
			</fieldset>
			
			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	