
		<h3 class="title" i18n="@@machines">Machines</h3>

		<mat-accordion>
			<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
				<mat-expansion-panel-header>
					<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
						<div class="field-row">
							<div class="field-row-item" *ngIf="tableService.filter.serial">
								<span class="label" i18n="@@serial">Serial</span>
								<span>{{tableService.filter.serial}}</span>
							</div>
							<div class="field-row-item" *ngIf="tableService.filter.store">
								<span class="label" i18n="@@store">Branch</span>
								<span>{{tableService.filter.store.name}}</span>
							</div>
							<div class="field-row-item" *ngIf="tableService.filter.statusMachine">
								<span class="label" i18n="@@status">Status</span>
								<span>{{tableService.filter.statusMachine.name}}</span>
							</div>
							<div class="field-row-item" *ngIf="tableService.filter.machineModel">
								<span class="label" i18n="@@machineModel">Machine Model</span>
								<span>{{tableService.filter.machineModel.name}}</span>
							</div>
							<div class="field-row-item" *ngIf="tableService.filter.machineType">
								<span class="label" i18n="@@machineType">Machine type</span>
								<span>{{tableService.filter.machineType.name}}</span>
							</div>
							<div class="field-row-item" *ngIf="tableService.filter.machineBrand">
								<span class="label" i18n="@@machineBrand">Machine brand</span>
								<span>{{tableService.filter.machineBrand.name}}</span>
							</div>
						</div>
					</div>
					<button class="btn-icon" i18n-title="@@option-search" title="Search" type="button">
						<i class="material-icons">search</i>
					</button>
				</mat-expansion-panel-header>

				<form>
					<fieldset class="fieldset">
						<div class="field-row">
							<div class="field-row-item">
								<label class="label" for="serial" i18n="@@serial">Serial</label>
								<input type="text" name="serial" placeholder="None" i18n-placeholder="None" [(ngModel)]="filter.serial">
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<label class="label" for="statusMachine" i18n="@@status">Status</label>
								<mat-select placeholder="None" i18n-placeholder="None" [(value)]="filter.statusMachine" [compareWith]="compareFn" disableRipple>
									<mat-option i18n="@@select-none">None</mat-option>
									<mat-option *ngFor="let opt of statusMachines" [value]="opt">{{opt.name}}</mat-option>
								</mat-select>
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<label class="label" for="store" i18n="@@store">Branch</label>
								<mat-select placeholder="None" i18n-placeholder="None" [(value)]="filter.store" [compareWith]="compareFn" disableRipple>
									<mat-option i18n="@@select-none">None</mat-option>
									<mat-option *ngFor="let opt of stores" [value]="opt">{{opt.name}}</mat-option>
								</mat-select>
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<label class="label" for="machineType" i18n="@@machineType">Machine Type</label>
								<mat-select placeholder="None" i18n-placeholder="None" [(value)]="filter.machineType" [compareWith]="compareFn" (selectionChange)="changeMachineType($event.value)" disableRipple>
									<mat-option i18n="@@select-none">None</mat-option>
									<mat-option *ngFor="let opt of machineTypes" [value]="opt">{{opt.name}}</mat-option>
								</mat-select>
							</div>
							<div class="field-row-item">
								<label class="label" for="machineBrand" i18n="@@machineBrand">Machine Brand</label>
								<mat-select placeholder="None" i18n-placeholder="None" [(value)]="filter.machineBrand" [compareWith]="compareFn" (selectionChange)="changeMachineBrand($event.value)" disableRipple>
									<mat-option i18n="@@select-none">None</mat-option>
									<mat-option *ngFor="let opt of machineBrands" [value]="opt">{{opt.name}}</mat-option>
								</mat-select>
							</div>
							<div class="field-row-item">
								<label class="label" for="machineModel" i18n="@@machineModel">Machine Model</label>
								<mat-select placeholder="None" i18n-placeholder="None" [(value)]="filter.machineModel" [compareWith]="compareFn" disableRipple>
									<mat-option i18n="@@select-none">None</mat-option>
									<mat-option *ngFor="let opt of machineModels" [value]="opt">{{opt.name}} - {{opt.comercialName}}</mat-option>
								</mat-select>
							</div>
						</div>
					</fieldset>
					<div class="options">
						<button class="btn-text gray" type="button" (click)="reset()">
							<span i18n="@@option-reset">Reset</span>
						</button>
						<button class="btn-text blue" type="button" (click)="search()">
							<span i18n="@@option-search">Search</span>
						</button>
					</div>
				</form>
			</mat-expansion-panel>
		</mat-accordion>

		<div class="tool-bar-wrap both-side">
			<div class="right row">
				<button class="btn-icon" i18n-title="@@option-create" type="button" (click)="create()">
					<i class="material-icons">add</i>
				</button>
				<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false" *ngIf="tableService.selection.hasValue()">
					<i class="material-icons">delete</i>
				</button>
			</div>
		</div>

		<div class="mat-elevation-z8e" *ngIf="dataSource">
			<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
				<ng-container matColumnDef="select">
					<th mat-header-cell *matHeaderCellDef>
						<mat-checkbox (change)="tableService.masterToggle(dataSource.data)"
							[checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSource.data.length)"
							[indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSource.data.length)"
							*hasPermission="['Machines.delete']">
						</mat-checkbox>
					</th>
					<td mat-cell *matCellDef="let row">
						<mat-checkbox (click)="$event.stopPropagation()"
							(change)="tableService.selection.toggle(row)"
							[checked]="tableService.selection.isSelected(row)"
							*hasPermission="['Machines.delete']">
						</mat-checkbox>
					</td>
				</ng-container>

				<ng-container matColumnDef="serial">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@serial">Serial</th>
					<td mat-cell *matCellDef="let element">{{element.serial}}</td>
				</ng-container>

				<ng-container matColumnDef="machineModel.name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@machineModel">Machine Model</th>
					<td mat-cell *matCellDef="let element">{{element.machineModel?.name}}</td>
				</ng-container>

				<ng-container matColumnDef="machineModel.machineType.name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@machineType">Machine Type</th>
					<td mat-cell *matCellDef="let element">{{element.machineModel?.machineType?.name}}</td>
				</ng-container>

				<ng-container matColumnDef="machineModel.machineBrand.name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@machineBrand">Machine Brand</th>
					<td mat-cell *matCellDef="let element">{{element.machineModel?.machineBrand?.name}}</td>
				</ng-container>

				<ng-container matColumnDef="statusMachine.name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@status">Status</th>
					<td mat-cell *matCellDef="let element">{{element.statusMachine?.name}}</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"
					(contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row"
					(click)="read(row)">
				</tr>
			</table>

			<mat-paginator showFirstLastButtons
				[length]="tableService.pager.totalEntities"
				[pageIndex]="tableService.pager.pageIndex"
                [pageSize]="tableService.pager.pageSize"
				[pageSizeOptions]="[20, 50, 100]"
				(page)="list($event)">
			</mat-paginator>
		</div>

		<context-menu #basicMenu>
			<ng-template [hasPermission]="['Machines.update']">
				<ng-template contextMenuItem (execute)="update($event.item)">
					<div class="item">
						<i class="material-icons">edit</i>
						<span i18n="@@option-update">Update</span>
					</div>
				</ng-template>
			</ng-template>

			<ng-template [hasPermission]="['Machines.delete']">
				<ng-template contextMenuItem divider="true"></ng-template>
				<ng-template contextMenuItem (execute)="confirmDelete = false">
					<div class="item">
						<i class="material-icons">delete</i>
						<span i18n="@@option-delete">Delete</span>
					</div>
				</ng-template>
			</ng-template>
		</context-menu>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-deletes">
						Are you sure you want to delete the selected records?
					</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="deletes(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	