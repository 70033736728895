import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { CompanyContractModule } from '../companyContract/companyContract.module';
import { CompanyComponent } from './company.component';
import { CompanyListComponent } from './company-list.component';
import { CompanyReadComponent } from './company-read.component';
import { CompanyCreateComponent } from './company-create.component';
import { CompanyUpdateComponent } from './company-update.component';
import { CompanyService } from './company.service';
import { StatusFinancialService } from 'app/status/statusFinancial.service';
import { dashboardRouting } from 'app/dashboard/dashboard.routes';
import { ContextMenuModule } from 'ngx-contextmenu';
import { MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule, MatExpansionModule, MatSelectModule } from '@angular/material';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		CompanyContractModule,
		dashboardRouting,
		FormsModule,
		ReactiveFormsModule,
		ContextMenuModule,
		MatTableModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule,
		MatExpansionModule,
		MatSelectModule
	],
	declarations: [
		CompanyComponent,
		CompanyListComponent,
		CompanyReadComponent,
		CompanyCreateComponent,
		CompanyUpdateComponent
	],
	providers: [
		CompanyService,
		StatusFinancialService
	],
})
export class CompanyModule { }
