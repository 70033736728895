import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'app/shared/form/custom.validators';
import { BaseService } from 'app/core/base.service';
import { Company } from './company';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class CompanyService {
	private static readonly BASE_URL: string = BaseService.HOST + '/companies';

	constructor(
		private http: HttpClient,
		private fb: FormBuilder
	) {}

	getById(id: number): Observable<Company> {
		return this.http.get(CompanyService.BASE_URL + '/' + id)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	getByName(name: string): Observable<Company> {
		return this.http.get(CompanyService.BASE_URL + '/name/' + name)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	getAll(params: HttpParams): Observable<any> {
		return this.http.get(CompanyService.BASE_URL, {params: params})
		.pipe(catchError(BaseService.handleError));
	}

	create(company: Company): Observable<Company> {
		return this.http.post(CompanyService.BASE_URL, company)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	update(company: Company): Observable<Company> {
		return this.http.put(CompanyService.BASE_URL + '/' + company.id, company)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	delete(id: number): Observable<any> {
		return this.http.delete(CompanyService.BASE_URL + '/' + id)
			.pipe(catchError(BaseService.handleError));
	}

	deletes(companies: Company[]): Observable<any> {
		return this.http.post(CompanyService.BASE_URL + '/delete', companies)
			.pipe(catchError(BaseService.handleError));
	}

	toFormGroup(company: Company): FormGroup {
		return this.fb.group({
			id: new FormControl(company.id),
			name: new FormControl(company.name, [Validators.required, Validators.maxLength(100)]),
			description: new FormControl(company.description),
			contact: this.fb.group({
				phone: new FormControl(company.contact.phone, Validators.maxLength(100)),
				phone2: new FormControl(company.contact.phone2, Validators.maxLength(100)),
				latitude: new FormControl(company.contact.latitude, CustomValidators.numberRegex),
				longitude: new FormControl(company.contact.longitude, CustomValidators.numberRegex),
				address: new FormControl(company.contact.address)
			}),
			statusFinancial: new FormControl(company.statusFinancial)
		});
	}
}
