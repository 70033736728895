import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { MachineType } from './machineType';
import { MachineTypeService } from './machineType.service';
import { NotificationService} from 'app/shared/notification/notification.service';

@Component({
	template: `
		<h3 class="title" i18n="@@create-machineType">Create Machine Type</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="create()">
			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-machine">Machine data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="name" i18n="@@name">Name</label>
						<input formControlName="name" type="text" autocomplete="off">
						<validator [control]="form.controls['name']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" i18n="@@description">Description</label>
						<textarea formControlName="description"></textarea>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	`
})
export class MachineTypeCreateComponent {
	form: FormGroup;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private machineTypeService: MachineTypeService,
		private notificationService: NotificationService,
	) {
		this.form = this.machineTypeService.toFormGroup(new MachineType());
	}

	create() {
		this.machineTypeService.create(this.form.value).subscribe(machineType => {
			this.notificationService.sucessInsert(machineType.name);
			this.router.navigate([`../${machineType.id}`], {relativeTo: this.activatedRoute});
		}, err => this.notificationService.error(err));
	}
}
