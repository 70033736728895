import { BaseModel } from 'app/core/models/base.model';
import { Status } from 'app/status/status';
import { Store } from 'app/store/store';
import { MachineModel } from 'app/machineModel/machineModel';
import { ServiceTask } from 'app/serviceTask/serviceTask';

export class Machine extends BaseModel {
	serial: string;
	swVersion: string;
	purchaseDate: string;
	factoryWarrantyExpiration: string;
	localWarrantyTime: number;
	usedDistance: number;
	maintenenceFrecuency: number;
	maintenencePerDistance: number;
	lastMaintenenceFrecuency: string;
	lastMaintenenceDistance: number;
	statusMachine: Status;
	store: Store;
	machineModel: MachineModel;
	serviceTasks: ServiceTask[];
}
