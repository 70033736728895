import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BaseService } from 'app/core/base.service';
import { CompanyContract } from './companyContract';
import { Media } from 'app/media/media';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class CompanyContractService {
	private static readonly BASE_URL: string = BaseService.HOST + '/companyContracts';

	constructor(
		private http: HttpClient,
		private fb: FormBuilder
	) {}

	getById(id: number): Observable<CompanyContract> {
		return this.http.get(CompanyContractService.BASE_URL + '/' + id)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	getAll(params: HttpParams): Observable<any> {
		return this.http.get(CompanyContractService.BASE_URL, {params: params})
			.pipe(catchError(BaseService.handleError));
	}

	create(companyContract: CompanyContract): Observable<CompanyContract> {
		return this.http.post(CompanyContractService.BASE_URL, companyContract)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	createMedia(id: number, media: Media): Observable<Media> {
		return this.http.post(`${CompanyContractService.BASE_URL}/${id}/mediaContract`, media)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	update(companyContract: CompanyContract): Observable<CompanyContract> {
		return this.http.put(`${CompanyContractService.BASE_URL}/${companyContract.id}`, companyContract)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	delete(id: number): Observable<any> {
		return this.http.delete(CompanyContractService.BASE_URL + '/' + id)
			.pipe(catchError(BaseService.handleError));
	}

	deletes(companyContracts: CompanyContract[]): Observable<any> {
		return this.http.post(CompanyContractService.BASE_URL + '/delete', companyContracts)
			.pipe(catchError(BaseService.handleError));
	}

	toFormGroup(companyContract: CompanyContract): FormGroup {
		return this.fb.group({
			id: new FormControl(companyContract.id),
			name: new FormControl(companyContract.name, [Validators.required, Validators.maxLength(100)]),
			description: new FormControl(companyContract.description),
			company: new FormControl(companyContract.company, Validators.required)
		});
	}
}
