import { Component, Input, Output, EventEmitter, ContentChildren, QueryList } from '@angular/core';
import { DropdownOptionDirective } from 'app/shared/form/dropdown-ns/dropdownOptionDirective';
import { AbstractControl } from '@angular/forms';

// reference: https://github.com/CuppaLabs/angular2-multiselect-dropdown
@Component({
	selector: 'dropdown-ns',
	template: `
		<div class="dd" [ngClass]="{'disabled': disabled}" (clickOutside)="hidden = true">
			<div class="select dd-select" (click)="this.hidden = !this.hidden">
				<div class="container-data">
					<ng-template *ngIf="control" [ngTemplateOutlet]="getOptionByValue(control.value)?.template"></ng-template>
					<ng-template *ngIf="!control && value" [ngTemplateOutlet]="getOptionByValue(value)?.template"></ng-template>
				</div>
			</div>

			<div class="dd-options" *ngIf="!disabled" [hidden]="hidden">
				<!--<div class="header">
				</div>-->
				<div class="body">
					<ul>
						<li *ngFor="let option of options" class="dd-options-item" [ngClass]="{'selected': isSelected(option.value)}" (click)="onSelect(option.value); hidden = true">
							<div class="container-data">
								<ng-template [ngTemplateOutlet]="option.template"></ng-template>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	`,
	styleUrls: ['dropdown-ns.component.css'],
})
export class DropdownComponent {
	@Input() disabled = false;
	@Input() value: any;
	@Input() control: AbstractControl;
	@Output() controlChange = new EventEmitter();
	@Output() changed = new EventEmitter();
	@ContentChildren(DropdownOptionDirective) public options: QueryList<DropdownOptionDirective>;
	hidden = true;

	onSelect(value: any) {
		if (this.control) {
			this.control.setValue(value);
			this.controlChange.emit(this.control);
		} else {
			this.value = value;
		}
		this.changed.emit(value);
	}

	isSelected(value): boolean {
		if ((this.control && this.isEqual(this.control.value, value)) || this.value && this.isEqual(this.value, value)) {
			return true;
		}
		return false;
	}

	getOptionByValue(value): any {
		return this.options.find(option => this.isEqual(option.value, value));
	}

	private isEqual(objA, objB) {
		return JSON.stringify(objA) === JSON.stringify(objB);
	}
}
