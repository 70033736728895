import { BaseModel } from 'app/core/models/base.model';
import { ShipmentItem } from 'app/shipmentItem/shipmentItem';
import { ShipmentStage } from '../shipmentStage/shipmentStage';
import { PartOrder } from 'app/partOrder/partOrder';
import { Status } from '../status/status';

export class Shipment extends BaseModel {
	tracking: string;
	estimatedDelivery: string;
	description: string;
	partOrder: PartOrder;
	shipmentItems: ShipmentItem[];
	shipmentStages: ShipmentStage[];
	statusShipment: Status;
	statusShipmentId: string;
	current: boolean;
}
