import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { RequestPartComponent } from './requestPart.component';
import { RequestPartListComponent } from './requestPart-list.component';
import { RequestPartService } from './requestPart.service';
import { dashboardRouting } from '../dashboard/dashboard.routes';
import { ContextMenuModule } from 'ngx-contextmenu';
import { RequestPartItemModule } from 'app/requestPartItem/requestPartItem.module';
import { RequestPartCreateComponent } from './requestPart-create.component';
import { RequestPartReadComponent } from './requestPart-read.component';
import { MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule, MatExpansionModule, MatSelectModule } from '@angular/material';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		dashboardRouting,
		ReactiveFormsModule,
		RequestPartItemModule,
		ContextMenuModule,
		MatTableModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule,
		MatExpansionModule,
		MatSelectModule
	],
	declarations: [
		RequestPartComponent,
		RequestPartListComponent,
		RequestPartReadComponent,
		RequestPartCreateComponent
	],
	providers: [
		RequestPartService
	],
})
export class RequestPartModule { }
