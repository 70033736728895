import { Component } from '@angular/core';
import { TableService } from 'app/core/models/pager';

@Component({
	template: '<router-outlet></router-outlet>',
	providers: [
		TableService,
	]
})
export class StoreComponent {
}
