import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'app/shared/notification/notification.service';
import { AuthService } from '../auth/auth.service';
import { User } from 'app/user/user';

@Component({
	selector: 'topbar',
	template: `
		<div class="top-bar">
			<span *ngIf="user" i18n="@@welcome">Welcome: <b class="clickable-item" routerLink="/dashboard/users">{{user.firstName}}</b></span>
			<span *ngIf="!user || !user.firstName" i18n="@@welcome-unknow">Welcome: <b>unknow</b></span>
			<a style="margin-left: 10px;" (click)="logout()" i18n="@@logout">Logout</a>
		</div>
	`,
	styleUrls: ['./topbar.component.css'],
})

export class TopbarComponent implements OnInit {
	user: User;

	constructor(
		private notificationService: NotificationService,
		private authService: AuthService
	) {}

	ngOnInit() {
		this.authService.currentUser.subscribe((user: User) => {
			this.user = user;
		});
	}

	public logout() {
		this.authService.revokeToken().subscribe(() => {
			this.authService.clearToken();
			this.notificationService.sucessLogout();
		}, err => {
			this.authService.clearToken();
			this.notificationService.error(err);
		});
	}
}
