import { BaseModel } from 'app/core/models/base.model';
import { AuthRole } from './authRole';
import { AuthGroup } from './authGroup';

export class AuthUser extends BaseModel {
	email: string;
	password: string;
	archived: boolean;
	lastLogin: string;
	authGroup: AuthGroup;
	authRoles: AuthRole[];
}
