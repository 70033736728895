import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { BaseService } from 'app/core/base.service';
import { UserEmployee } from './userEmployee';
import { UserService } from 'app/user/user.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class UserEmployeeService {
	private static readonly BASE_URL: string = BaseService.HOST + '/userEmployees';

	constructor(
		private http: HttpClient,
		private userService: UserService
	) {}

	getById(id: number): Observable<UserEmployee> {
		return this.http.get(UserEmployeeService.BASE_URL + '/' + id)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	getAll(params: HttpParams): Observable<any> {
		return this.http.get(UserEmployeeService.BASE_URL, {params: params})
		.pipe(catchError(BaseService.handleError));
	}

	create(userEmployee: UserEmployee): Observable<UserEmployee> {
		return this.http.post(UserEmployeeService.BASE_URL, userEmployee)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	update(userEmployee: UserEmployee): Observable<UserEmployee> {
		return this.http.put(UserEmployeeService.BASE_URL + '/' + userEmployee.id, userEmployee)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	delete(id: number): Observable<any> {
		return this.http.delete(UserEmployeeService.BASE_URL + '/' + id)
			.pipe(catchError(BaseService.handleError));
	}

	deletes(userEmployees: UserEmployee[]): Observable<any> {
		return this.http.post(UserEmployeeService.BASE_URL + '/delete', userEmployees)
			.pipe(catchError(BaseService.handleError));
	}

	toFormGroup(userEmployee: UserEmployee): FormGroup {
		return this.userService.toFormGroup(userEmployee);
	}

	toFormGroupUpdate(userEmployee: UserEmployee): FormGroup {
		return this.userService.toFormGroupUpdate(userEmployee);
	}

	toFormGroupUpdatePassword(userEmployee: UserEmployee): FormGroup {
		return this.userService.toFormGroupUpdatePassword(userEmployee);
	}
}
