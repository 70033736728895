
		<div class="dd" [ngClass]="{'disabled': disabled}" (clickOutside)="hidden = true">
			<div class="select dd-select" (click)="hidden = !hidden">
				<div *ngIf="selector.length() === 1" class="container-data">
					<ng-template [ngTemplateOutlet]="getOptionByValue(selector.getItems()[0])?.template"></ng-template>
				</div>
				<div *ngIf="selector.length() > 1" class="container-data" i18n="@@select-multiple">Multiple</div>
			</div>

			<div class="dd-options" *ngIf="!disabled" [hidden]="hidden">
				<div class="header" *ngIf="options.length > 0">
					<div class="dd-options-item">
						<div class="container-checkbox">
							<mat-checkbox (change)="onMasterToggle()"
								[checked]="selector.hasValue() && selector.isAllSelected(getValuesFromOptions())"
								[indeterminate]="selector.hasValue() && !selector.isAllSelected(getValuesFromOptions())"
								*hasPermission="['ServiceTypes.delete']">
							</mat-checkbox>
						</div>
						<div class="container-data" (click)="onMasterToggle()" i18n="@@select-selectAll">Select all</div>
					</div>
				</div>
				<div class="body">
					<ul>
						<li *ngFor="let option of options" class="dd-options-item" [ngClass]="{'selected': selector.isSelected(option.value)}" (click)="onToggle(option.value)">
							<div class="container-checkbox">
								<mat-checkbox (click)="$event.stopPropagation()"
									(change)="onToggle(option.value)"
									[checked]="selector.isSelected(option.value)"
									*hasPermission="['ServiceTypes.delete']">
								</mat-checkbox>
							</div>
							<div class="container-data">
								<ng-template [ngTemplateOutlet]="option.template"></ng-template>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	