
		<h3 class="title" i18n="@@update-partModel-images">Update Part Model images</h3>

		<div class="tool-bar-wrap both-side">
			<div class="row">
				<div><span i18n="@@total">Total</span> {{items.length}}</div>
				<div><span i18n="@@total">Total</span> selecteds {{tableService.selection.selected.length}}</div>
			</div>
			<div class="right row">
				<ng-template [hasPermission]="['MediaController.createPartModels']">
					<input id="file-upload" type="file" accept="image/png, image/jpeg" (change)="create($event)" multiple>
					<label class="btn-file" for="file-upload">
						<i class="material-icons">add</i>
					</label>
				</ng-template>

				<ng-template [hasPermission]="['MediaController.delete']">
					<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false" *ngIf="tableService.selection.hasValue()">
						<i class="material-icons">delete</i>
					</button>
				</ng-template>
			</div>
		</div>

		<gallery-image [items]="items"></gallery-image>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-deletes">Are you sure you want to delete the selected items?</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="deletes(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	