import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ServiceTaskService } from './serviceTask.service';
import { UserEmployee } from '../userEmployee/userEmployee';
import { UserEmployeeService } from '../userEmployee/userEmployee.service';
import { Status } from '../status/status';
import { NotificationService} from 'app/shared/notification/notification.service';
import { ServiceRequest } from 'app/serviceRequest/serviceRequest';
import { ServiceTask } from 'app/serviceTask/serviceTask';
import { HttpParams } from '@angular/common/http';
import { ServiceOrder } from '../serviceOrder/serviceOrder';
import { TableService } from 'app/core/models/pager';

@Component({
	selector: 'serviceTask-create',
	template: `
		<h3 class="title" i18n="@@create-serviceTask">Create Job Order</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="create()">
			<fieldset class="fieldset">
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="userEmployee" i18n="@@userEmployee">Employee User</label>
						<mat-select [formControl]="form.controls['userEmployee']" [compareWith]="compareFn" disableRipple>
							<mat-option i18n="@@select-none">None</mat-option>
							<mat-option *ngFor="let opt of userEmployees" [value]="opt">{{opt.firstName}} {{opt.lastName}}</mat-option>
						</mat-select>
						<validator [control]="form.controls['userEmployee']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="startDateTime" i18n="@@startDateTime">Start</label>
						<div class="datepicker">
							<input matInput [matDatepicker]="picker" [formControl]="form.controls['startDateTime']">
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						</div>
						<mat-datepicker #picker></mat-datepicker>
						<validator [control]="form.controls['startDateTime']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="statusServiceTask" i18n="@@statusServiceTask">Job Order Status</label>
						<mat-select [formControl]="form.controls['statusServiceTask']" [compareWith]="compareFn" disableRipple>
							<mat-option i18n="@@select-none">None</mat-option>
							<mat-option [value]="{id:'st_draft'}"><span i18n="@@st_draft">st_draft</span></mat-option>
						</mat-select>
						<validator [control]="form.controls['statusServiceTask']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="observation" i18n="@@observation">Observation</label>
						<textarea formControlName="observation"></textarea>
					</div>
				</div>
			</fieldset>

			<div class="options" style="justify-content: flex-end;">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	`,
})
export class ServiceTaskCreateComponent implements OnInit {
	@Input() serviceOrder: ServiceOrder;
	@Output() listServiceRequest = new EventEmitter();
	form: FormGroup;
	userEmployees: UserEmployee[];

	constructor(
		private serviceTaskService: ServiceTaskService,
		private userEmployeeService: UserEmployeeService,
		public tableService: TableService<ServiceTask>,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		let serviceTask = new ServiceTask();
		serviceTask.statusServiceTask = new Status('st_draft');
		this.form = this.serviceTaskService.toFormGroup(serviceTask);

		this.userEmployeeService.getAll(
			new HttpParams().set('sort', 'firstName').set('collection', 'id, firstName, lastName')
		).subscribe(data => {
			this.userEmployees = data['result'];
		});
	}

	create() {
		let serviceTasks = this.tableService.selection.selected.map(item => {
			let serviceTask = new ServiceTask();
			serviceTask.userEmployee = this.form.value['userEmployee'];
			serviceTask.startDateTime = this.form.value['startDateTime'].utc().format();
			serviceTask.statusServiceTask = this.form.value['statusServiceTask'];
			serviceTask.observation = this.form.value['observation'];
			serviceTask.serviceRequest = new ServiceRequest();
			serviceTask.serviceRequest.id = item.id;
			return serviceTask;
		});
		this.serviceTaskService.creates(serviceTasks).subscribe(() => {
			this.notificationService.sucessInsert('serviceTasks');
			this.listServiceRequest.emit();
		}, err => this.notificationService.error(err));
	}

	compareFn(c1: any, c2: any): boolean {
		return c1 && c2 ? c1.id === c2.id : c1 === c2;
	}
}
