import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { MachineModule } from '../machine/machine.module';
import { StoreComponent } from './store.component';
import { StoreListComponent } from './store-list.component';
import { StoreReadComponent } from './store-read.component';
import { StoreCreateComponent } from './store-create.component';
import { StoreUpdateComponent } from './store-update.component';
import { StoreService } from './store.service';
import { dashboardRouting } from '../dashboard/dashboard.routes';
import { ContextMenuModule } from 'ngx-contextmenu';
import { MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule, MatExpansionModule, MatSelectModule } from '@angular/material';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		MachineModule,
		dashboardRouting,
		FormsModule,
		ReactiveFormsModule,
		ContextMenuModule,
		MatTableModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule,
		MatExpansionModule,
		MatSelectModule
	],
	declarations: [
		StoreComponent,
		StoreListComponent,
		StoreReadComponent,
		StoreCreateComponent,
		StoreUpdateComponent
	],
	providers: [
		StoreService
	],
})
export class StoreModule { }
