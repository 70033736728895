import { Injectable, Injector } from '@angular/core';
import { throwError, BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import { AccessTokenResponse } from './oauth/accessTokenResponse';
import { switchMap, filter, take, catchError, finalize } from 'rxjs/operators';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

	isRefreshingToken = false;
	tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	constructor(
		public inj: Injector
	) {}

	addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
		return req.clone({ setHeaders: { Authorization: 'Bearer ' + token }});
	}

	// reference:
	// https://medium.com/@ryanchenkie_40935/angular-authentication-using-the-http-client-and-http-interceptors-2f9d1540eb8
	// https://alligator.io/angular/httpclient-interceptors/
	// https://www.intertech.com/Blog/angular-4-tutorial-handling-refresh-token-with-new-httpinterceptor/
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		// refrence: https://stackoverflow.com/questions/45654351/injector-error-provider-parse-errors-cannot-instantiate-cyclic-dependency?rq=1
		const authService = this.inj.get(AuthService);

		return next.handle(this.addToken(req, authService.getAccessToken())).pipe(
			catchError(err => {
				if (err instanceof HttpErrorResponse) {
					console.log('Interceptor error: ', (<HttpErrorResponse> err).status);
					switch ((<HttpErrorResponse> err).status) {
						case 400:
							return this.handle400Error(err, authService);
						case 401:
							return this.handle401Error(req, next, authService);
					}
				}
				return throwError(err);
			})
		);
	}

	private handle400Error(err, authService: AuthService) {
		if (/*error && error.status === 400 ||*/ err.error && err.error.error === 'invalid_grant') {
			// If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
			authService.clearToken();
		}
		return throwError(err);
	}

	private handle401Error(req: HttpRequest<any>, next: HttpHandler, authService: AuthService) {
		if (!this.isRefreshingToken) {
			this.isRefreshingToken = true;

			// Reset here so that the following reqs wait until the token
			// comes back from the refreshToken call.
			this.tokenSubject.next(null);

			return authService.refreshToken().pipe(
				switchMap((accessTokenResponse: AccessTokenResponse) => {
					if (accessTokenResponse.access_token) {
						console.log('Refreshed access_token by interceptor');
						authService.setToken(accessTokenResponse);
						this.tokenSubject.next(accessTokenResponse.access_token);
						return next.handle(this.addToken(req, accessTokenResponse.access_token));
					}
					authService.clearToken();
					return EMPTY;
				}),
				catchError(err => {
					// If there is an exception calling 'refreshToken', bad news so logout.
					authService.clearToken();
					return throwError(err);
				}),
				finalize(() => {
					this.isRefreshingToken = false;
				})
			);
		} else {
			return this.tokenSubject.pipe(
				filter(token => token != null),
				take(1),
				switchMap(token => {
					return next.handle(this.addToken(req, token));
				})
			);
		}
	}
}
