import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from 'app/core/base.service';
import { PartModel } from './partModel';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'app/shared/form/custom.validators';
import { Media } from 'app/media/media';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class PartModelService {
	private static readonly BASE_URL: string = BaseService.HOST + '/partModels';

	constructor(
		private http: HttpClient,
		private fb: FormBuilder
	) {}

	getById(id: number, params?: HttpParams): Observable<PartModel> {
		return this.http.get(PartModelService.BASE_URL + '/' + id, {params: params})
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	getAll(params: HttpParams): Observable<any> {
		return this.http.get(PartModelService.BASE_URL, {params: params})
			.pipe(catchError(BaseService.handleError));
	}

	create(partModel: PartModel): Observable<PartModel> {
		return this.http.post(PartModelService.BASE_URL, partModel)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	createMedias(id: number, medias: Media[]): Observable<Media[]> {
		return this.http.post(PartModelService.BASE_URL + '/' + id + '/medias', medias)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	update(partModel: PartModel): Observable<PartModel> {
		return this.http.put(PartModelService.BASE_URL + '/' + partModel.id, partModel)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	delete(id: number): Observable<any> {
		return this.http.delete(PartModelService.BASE_URL + '/' + id)
			.pipe(catchError(BaseService.handleError));
	}

	deletes(partModels: PartModel[]): Observable<any> {
		return this.http.post(PartModelService.BASE_URL + '/delete', partModels)
			.pipe(catchError(BaseService.handleError));
	}

	toFormGroup(partModel: PartModel): FormGroup {
		return this.fb.group({
			id: new FormControl(partModel.id),
			name: new FormControl(partModel.name, Validators.maxLength(100)),
			code: new FormControl(partModel.code, [Validators.required, Validators.maxLength(100)]),
			stock: new FormControl(partModel.stock, [CustomValidators.integerRegex, CustomValidators.min(0)]),
			price: new FormControl(partModel.price, [CustomValidators.numberRegex, CustomValidators.min(0)]),
			price2: new FormControl(partModel.price2, [CustomValidators.numberRegex, CustomValidators.min(0)]),
			description: new FormControl(partModel.description)
		});
	}
}
