
		<div class="grid-v" style="height: 100%">
			<div class="options" style="margin-bottom: 10px;">
				<div class="options-current">
					{{monthNames[current.getMonth()]}} {{current.getFullYear()}}
				</div>
				<div class="options-change-date">
					<div style="padding: 5px; cursor: pointer;" (click)="setToday()" i18n="@@calendar-today">TODAY</div>
					<button class="btn-icon" i18n-title="@@calendar-previous" title="Previous month" type="button" (click)="previousMonth()">
						<i class="material-icons">keyboard_arrow_left</i>
					</button>
					<button class="btn-icon" i18n-title="@@calendar-next" title="Next month" type="button" (click)="nextMonth()">
						<i class="material-icons">keyboard_arrow_right</i>
					</button>
				</div>
			</div>
			<div class="calendar grid-v">
				<div class="calendar-header calendar-row">
					<div *ngFor="let dayName of dayNames" class="calendar-row-item">{{dayName}}</div>
				</div>
				<div class="calendar-body grid-v">
					<div *ngFor="let row of calendar" class="calendar-row">
						<div *ngFor="let cell of row" class="calendar-row-item" [id]="cell.date.getTime()" [ngClass]="{pasive: cell.date.getMonth() != current.getMonth(), today: cell.date.getTime() === today.getTime()}">
							<div class="container-cell">
								<div class="dayOfMonth">{{cell.date.getDate()}}</div>
								<div class="events">
									<ng-template *ngIf="eventsMap[cell.date.getTime()]" [ngTemplateOutlet]="eventsMap[cell.date.getTime()].template"></ng-template>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	