import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Machine } from './machine';
import { StoreService } from 'app/store/store.service';
import { StatusMachineService } from 'app/status/statusMachine.service';
import { MachineModelService } from 'app/machineModel/machineModel.service';
import { MachineService } from './machine.service';
import { NotificationService} from 'app/shared/notification/notification.service';
import { Status } from 'app/status/status';
import { Store } from 'app/store/store';
import { MachineModel } from 'app/machineModel/machineModel';
import { HttpParams } from '@angular/common/http';

@Component({
	template: `
	<h3 class="title" i18n="@@create-machine">Create Machine</h3>

	<form *ngIf="form" [formGroup]="form" (ngSubmit)="create()">
		<fieldset class="fieldset">
			<legend class="legend" i18n="@@data-machine">Machine data</legend>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="store" i18n="@@store">Branch</label>
					<mat-select [formControl]="form.controls['store']" [compareWith]="compareFn" disableRipple>
						<mat-option i18n="@@select-none">None</mat-option>
						<mat-option *ngFor="let opt of stores" [value]="opt">{{opt.name}}</mat-option>
					</mat-select>
					<validator [control]="form.controls['store']"></validator>
				</div>
			</div>
			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="machineModel" i18n="@@machineModel">Machine Model</label>
					<dropdown-ns [(control)]="form.controls['machineModel']">
						<ng-template *ngFor="let object of machineModels" dropdownOption [value]="{id: object.id}">
							<div class="extend">
								<div class="extend-main">{{object.name || '-'}}</div>
								<div class="field-row">
									<div class="field-row-item">
										<span class="label" i18n="@@name">name</span>
										<span>{{object.comercialName || '-'}}</span>
									</div>
								</div>
								<div class="field-row">
									<div class="field-row-item">
										<span class="label" i18n="@@machineType">Machine Type</span>
										<span>{{object.machineType?.name || '-'}}</span>
									</div>
								</div>
								<div class="field-row">
									<div class="field-row-item">
										<span class="label" i18n="@@machineBrand">Machine Brand</span>
										<span>{{object.machineBrand?.name || '-'}}</span>
									</div>
								</div>
							</div>
						</ng-template>
					</dropdown-ns>
					<validator [control]="form.controls['machineModel']"></validator>
				</div>
			</div>
			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="serial" i18n="@@serial">Serial</label>
					<input formControlName="serial" type="text" autocomplete="off">
					<validator [control]="form.controls['serial']"></validator>
				</div>
			</div>
			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="statusMachine" i18n="@@status">Status</label>
					<mat-select [formControl]="form.controls['statusMachine']" [compareWith]="compareFn" disableRipple>
						<mat-option i18n="@@select-none">None</mat-option>
						<mat-option *ngFor="let opt of statusMachines" [value]="opt">{{opt.name}}</mat-option>
					</mat-select>
					<validator [control]="form.controls['statusMachine']"></validator>
				</div>
			</div>
			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="swVersion" i18n="@@swVersion">Sotfware version</label>
					<input formControlName="swVersion" type="text" autocomplete="off">
					<validator [control]="form.controls['swVersion']"></validator>
				</div>
			</div>
			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="purchaseDate" i18n="@@purchaseDate">Purchase date</label>
					<div class="datepicker">
						<input matInput [matDatepicker]="picker" [formControl]="form.controls['purchaseDate']">
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					</div>
					<mat-datepicker #picker></mat-datepicker>
					<validator [control]="form.controls['purchaseDate']"></validator>
				</div>
				<div class="field-row-item">
					<label class="label" for="factoryWarrantyExpiration" i18n="@@factoryWarrantyExpiration">Factory warranty expiration</label>
					<div class="datepicker">
						<input matInput [matDatepicker]="picker2" [formControl]="form.controls['factoryWarrantyExpiration']">
						<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
					</div>
					<mat-datepicker #picker2></mat-datepicker>
					<validator [control]="form.controls['factoryWarrantyExpiration']"></validator>
				</div>
			</div>
			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="localWarrantyTime" i18n="@@localWarrantyTime">Local warranty time</label>
					<input formControlName="localWarrantyTime" type="text" autocomplete="off">
					<validator [control]="form.controls['localWarrantyTime']"></validator>
				</div>
			</div>
		</fieldset>

		<fieldset class="fieldset">
			<legend class="legend" i18n="@@data-maintenence">Maintenence data</legend>

			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="usedDistance" i18n="@@usedDistance">Used distance</label>
					<input formControlName="usedDistance" type="text" autocomplete="off">
					<validator [control]="form.controls['usedDistance']"></validator>
				</div>
			</div>
			<div class="field-row">
				<div class="field-row-item">
					<label class="label" for="maintenenceFrecuency" i18n="@@maintenenceFrecuency">Maintenence frecuency</label>
					<input formControlName="maintenenceFrecuency" type="text" autocomplete="off">
					<validator [control]="form.controls['maintenenceFrecuency']"></validator>
				</div>
				<div class="field-row-item">
					<label class="label" for="maintenencePerDistance" i18n="@@maintenencePerDistance">Maintenence per distance</label>
					<input formControlName="maintenencePerDistance" type="text" autocomplete="off">
					<validator [control]="form.controls['maintenencePerDistance']"></validator>
				</div>
			</div>
		</fieldset>

		<div class="options">
			<button class="btn-text" type="submit" [disabled]="!form.valid">
				<span i18n="@@option-save">Save</span>
			</button>
		</div>
	</form>
	`,
})
export class MachineCreateComponent implements OnInit {
	form: FormGroup;
	stores: Store[];
	statusMachines: Status[];
	machineModels: MachineModel[];

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private machineService: MachineService,
		private statusMachineService: StatusMachineService,
		private storeService: StoreService,
		private machineModelService: MachineModelService,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		this.activatedRoute.parent.parent.params.subscribe(params => {
			if (params.hasOwnProperty('storeId')) {
				this.storeService.getById(params['storeId']).subscribe(store => {
					let machine = new Machine();
					machine.store = store;
					machine.statusMachine = new Status('machine_active');
					this.form = this.machineService.toFormGroup(machine);
				});
			}
		});

		let httpParams = new HttpParams().set('sort', 'name').set('collection', 'id, name');
		this.storeService.getAll(httpParams).subscribe(data => {
			this.stores = data['result'];
		});

		this.statusMachineService.getAll(httpParams).subscribe(data => {
			this.statusMachines = data['result'];
		});

		httpParams = new HttpParams()
			.set('sort', 'name')
			.set('collection', 'id, name, comercialName, machineType(id, name), machineBrand(id, name)');
		this.machineModelService.getAll(httpParams).subscribe(data => {
			this.machineModels = data['result'];
		});
	}

	create() {
		let obj = this.form.value;
		if (obj.purchaseDate) {
			obj.purchaseDate = obj.purchaseDate.format('YYYY-MM-DD');
		}
		if (obj.factoryWarrantyExpiration) {
			obj.factoryWarrantyExpiration = obj.factoryWarrantyExpiration.format('YYYY-MM-DD');
		}
		this.machineService.create(obj).subscribe(machine => {
			this.notificationService.sucessInsert(machine.serial);
			this.router.navigate([`../${machine.id}`], {relativeTo: this.activatedRoute});
		}, err => this.notificationService.error(err));
	}

	compareFn(c1: any, c2: any): boolean {
		return c1 && c2 ? c1.id === c2.id : c1 === c2;
	}
}
