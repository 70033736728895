
		<h3 class="title" i18n="@@create-machineModel">Create Machine Model</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="create()">
			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-machineModel">Machine Model data</legend>
				
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="name" i18n="@@code">Code</label>
						<input formControlName="name" type="text" autocomplete="off">
						<validator [control]="form.controls['name']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="comercialName" i18n="@@name">Name</label>
						<input formControlName="comercialName" type="text" autocomplete="off">
						<validator [control]="form.controls['comercialName']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="price" i18n="@@price">Price</label>
						<input formControlName="price" type="text" autocomplete="off">
						<validator [control]="form.controls['price']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="machineType" i18n="@@machineType">Machine Type</label>
						<mat-select [formControl]="form.controls['machineType']" [compareWith]="compareFn" disableRipple>
							<mat-option i18n="@@select-none">None</mat-option>
							<mat-option *ngFor="let opt of machineTypes" [value]="opt">{{opt.name}}</mat-option>
						</mat-select>
						<validator [control]="form.controls['machineType']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="machineBrand" i18n="@@machineBrand">Machine Brand</label>
						<mat-select [formControl]="form.controls['machineBrand']" [compareWith]="compareFn" disableRipple>
							<mat-option i18n="@@select-none">None</mat-option>
							<mat-option *ngFor="let opt of machineBrands" [value]="opt">{{opt.name}}</mat-option>
						</mat-select>
						<validator [control]="form.controls['machineBrand']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" i18n="@@description">Description</label>
						<textarea formControlName="description"></textarea>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	