import { ActivatedRoute } from '@angular/router';

export class Global {

	public static readonly EMAIL_PATTERN = '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$';
	public static readonly URL_PATTERN = '';

	public static isEmpty(obj: Object): boolean {
		return Object.keys(obj).length === 0 && obj.constructor === Object;
	}

	public static mmssToSec(value) {
		value = parseInt(value, 10);
		let minutes = Math.floor(value / 60) % 60;
		let seconds = value % 60;
		return [minutes, seconds]
			.map(t => t < 10 ? '0' + t : t)
			.join(':');
	}

	static toFormatDate(date: Date) {
		const YYYY = date.getFullYear();
		const MM = ('0' + (date.getMonth() + 1)).slice(-2);
		const dd = ('0' + date.getDate()).slice(-2);
		const HH = ('0' + date.getHours()).slice(-2);
		const mm = ('0' + date.getMinutes()).slice(-2);
		const ss = ('0' + date.getSeconds()).slice(-2);
		return `${YYYY}-${MM}-${dd}T${HH}:${mm}:${ss}Z`;
	}

	static toFormatDateFromTime(time: number) {
		let date = new Date();
		date.setTime(time);
		const YYYY = date.getFullYear();
		const MM = ('0' + (date.getMonth() + 1)).slice(-2);
		const dd = ('0' + date.getDate()).slice(-2);
		const HH = ('0' + date.getHours()).slice(-2);
		const mm = ('0' + date.getMinutes()).slice(-2);
		const ss = ('0' + date.getSeconds()).slice(-2);
		return `${YYYY}-${MM}-${dd}T${HH}:${mm}:${ss}Z`;
	}

	static isDate(obj: any): boolean {
		return !/Invalid|NaN/.test(new Date(obj).toString());
	}

	public static getParamFromRoot(activatedRoute: ActivatedRoute, key: string) {
		let activatedRoutes =  activatedRoute.pathFromRoot;
		for (let i = 0; i < activatedRoutes.length; i++) {
			if (activatedRoutes[i].snapshot.params.hasOwnProperty(key)) {
				return activatedRoutes[i].snapshot.params[key];
			}
		}
		return null;
	}

	public static getProperties(items: any[], property: string) {
		return items.map(item => {
			if (item.hasOwnProperty(property)) {
				return item[property];
			}
		});
	}
}
