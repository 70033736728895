import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { PartModelComponent } from './partModel.component';
import { PartModelListComponent } from './partModel-list.component';
import { PartModelReadComponent } from './partModel-read.component';
import { PartModelCreateComponent } from './partModel-create.component';
import { PartModelUpdateComponent } from './partModel-update.component';
import { PartModelMediaImageComponent } from './partModel-media-image.component';
import { PartModelPdfComponent } from './partModel-media-pdf.component';
import { PartModelService } from './partModel.service';
import { ContextMenuModule } from 'ngx-contextmenu';
import { dashboardRouting } from 'app/dashboard/dashboard.routes';
import { MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule, MatSelectModule, MatExpansionModule } from '@angular/material';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		dashboardRouting,
		FormsModule,
		ReactiveFormsModule,
		ContextMenuModule,
		MatTableModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule,
		MatExpansionModule,
		MatSelectModule
	],
	declarations: [
		PartModelComponent,
		PartModelListComponent,
		PartModelReadComponent,
		PartModelCreateComponent,
		PartModelUpdateComponent,
		PartModelMediaImageComponent,
		PartModelPdfComponent,
	],
	providers: [
		PartModelService,
	],
})
export class PartModelModule { }
