import { HttpParams } from '@angular/common/http';
import { MachineType } from 'app/machineType/machineType';

export class ServiceTypeFilter {
	name: string;
	machineType: MachineType;

	constructor(filter?: ServiceTypeFilter) {
		if (filter) {
			this.name = filter.name;
			this.machineType = filter.machineType;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.name) {
			httpParams = httpParams.set('name', this.name);
		}
		if (this.machineType && this.machineType.id) {
			httpParams = httpParams.set('machineTypeId', this.machineType.id.toString());
		}
		return httpParams;
	}
}
