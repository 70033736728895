import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { UserClientService } from './userClient.service';
import { UserService } from '../user/user.service';
import { CompanyService } from '../company/company.service';
import { Company } from 'app/company/company';
import { NotificationService} from 'app/shared/notification/notification.service';
import { Contact } from 'app/core/models/contact';
import { HttpParams } from '@angular/common/http';

@Component({
	template: `
		<h3 class="title" i18n="@@update-userClient">Update Client User</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="update()">
			<fieldset class="fieldset" formGroupName="authUser">
				<legend class="legend" i18n="@@data-account">Account data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="email" i18n="@@email">Email</label>
						<input formControlName="email" type="text" autocomplete="off">
						<validator [control]="form.controls['authUser'].controls['email']"></validator>
					</div>
				</div>
			</fieldset>

			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-userClient">Client User data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="firstName" i18n="@@firstName">First name</label>
						<input formControlName="firstName" type="text" autocomplete="off">
						<validator [control]="form.controls['firstName']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="lastName" i18n="@@lastName">Last name</label>
						<input formControlName="lastName" type="text" autocomplete="off">
						<validator [control]="form.controls['lastName']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="companies" i18n="@@companies">Companies</label>
						<dropdown-multi-ns [(control)]="form.controls['companies']">
							<ng-template *ngFor="let object of companies" dropdownOption [value]="{id: object.id}">{{object.name}}</ng-template>
						</dropdown-multi-ns>
						<validator [control]="form.controls['companies']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" i18n="@@description">Description</label>
						<textarea formControlName="description"></textarea>
					</div>
				</div>
			</fieldset>

			<fieldset class="fieldset" formGroupName="contact">
				<legend class="legend" i18n="@@data-contact">Contact data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="phone" i18n="@@phone">Phone</label>
						<input formControlName="phone" type="text" autocomplete="off">
						<validator [control]="form.controls['contact'].controls['phone']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="phone2" i18n="@@phone2">Second phone</label>
						<input formControlName="phone2" type="text" autocomplete="off">
						<validator [control]="form.controls['contact'].controls['phone2']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="latitude" i18n="@@latitude">Latitude</label>
						<input formControlName="latitude" type="text" autocomplete="off">
						<validator [control]="form.controls['contact'].controls['latitude']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="longitude" i18n="@@longitude">Longitude</label>
						<input formControlName="longitude" type="text" autocomplete="off">
						<validator [control]="form.controls['contact'].controls['longitude']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="address" i18n="@@address">Address</label>
						<textarea formControlName="address"></textarea>
					</div>
				</div>
			</fieldset>
			
			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	`
})
export class UserClientUpdateComponent implements OnInit {
	form: FormGroup;
	companies: Company[] = [];

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private userClientService: UserClientService,
		private userService: UserService,
		private companyService: CompanyService,
		private notificationService: NotificationService,
	) {}

	ngOnInit() {
		this.activatedRoute.parent.params.subscribe(params => {
			this.userClientService.getById(params['userClientId']).subscribe(userClient => {
				if (!userClient.contact) {
					userClient.contact = new Contact();
				}
				this.form = this.userClientService.toFormGroupUpdate(userClient);
			});
		});

		this.companyService.getAll(
			new HttpParams().set('sort', 'name').set('collection', 'id, name')
		).subscribe(data => {
			this.companies = data['result'];
		});
	}

	update() {
		this.userService.getByEmail(this.form.value['authUser'].email).subscribe(user => {
			if (user && user.id !== this.form.value['id']) {
				this.notificationService.errorDuplicated();
			} else {
				this.userClientService.update(this.form.value).subscribe(userClient => {
					this.notificationService.sucessUpdate(userClient.firstName + ' ' + userClient.lastName);
					this.router.navigate([`../`], {relativeTo: this.activatedRoute});
				}, err => this.notificationService.error(err));
			}
		}, err => this.notificationService.error(err));
	}
}
