import { Component } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationService } from 'app/shared/notification/notification.service';
import { CustomValidators } from 'app/shared/form/custom.validators';
import { UserService } from 'app/user/user.service';

@Component({
	template: `	
		<div i18n="@@recoverPass-forgot">
			<div class="dialog-title">Forgot your password?</div>
			<div class="dialog-message">Enter your email address and we'll email you a pin to create a new password. If you have the pin, enter <u class="clickable-item" routerLink="./reset">here</u></div>
		</div>

		<form [formGroup]="form" (ngSubmit)="requestPin()">
			<fieldset class="fieldset">
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="email">Email</label>
						<input formControlName="email" type="text" autocomplete="off">
						<validator [control]="form.controls['email']"></validator>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-send-pass">Send me reset password pin</span>
				</button>
			</div>
		</form>

		<a class="clickable-item link" routerLink="../login" i18n="@@back-signup">Back to <u>Sign in</u></a>
	`,
	styleUrls: ['../account.css']
})
export class RecoverPassForgotComponent {
	form: FormGroup;

	constructor(
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private userService: UserService,
		private notificationService: NotificationService,
		private fb: FormBuilder
	) {
		this.form = this.toForm();
	}

	requestPin(): void {
		this.userService.recoverPassforgot(this.form.value).subscribe(() => {
			this.router.navigate(['./reset'], {relativeTo: this.activatedRoute});
		}, err => this.notificationService.error(err));
	}

	private toForm(): FormGroup {
		return this.fb.group({
			email: new FormControl('', [Validators.required, CustomValidators.emailRegex, Validators.maxLength(100)])
		});
	}
}
