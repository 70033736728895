import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { PartOrder } from './partOrder';
import { PartOrderService } from './partOrder.service';
import { Store } from 'app/store/store';
import { Status } from 'app/status/status';
import { StoreService } from 'app/store/store.service';
import { HttpParams } from '@angular/common/http';

@Component({
	template: `
		<h3 class="title" i18n="@@create-partOrder">Create Part Order</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="create()">
			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-partOrder">Part Order data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="store" i18n="@@store">Branch</label>
						<mat-select [formControl]="form.controls['store']" [compareWith]="compareFn" disableRipple>
							<mat-option i18n="@@select-none">None</mat-option>
							<mat-option *ngFor="let opt of stores" [value]="opt">{{opt.name}}</mat-option>
						</mat-select>
						<validator [control]="form.controls['store']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" i18n="@@description">Description</label>
						<textarea formControlName="description"></textarea>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	`
})
export class PartOrderCreateComponent implements OnInit {
	form: FormGroup;
	stores: Store[];

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private partOrderService: PartOrderService,
		private storeService: StoreService
	) {
		let partOrder = new PartOrder();
		partOrder.statusPartOrder = new Status('po_draft');
		this.form = this.partOrderService.toFormGroup(partOrder);
	}

	ngOnInit() {
		this.storeService.getAll(
			new HttpParams().set('sort', 'name').set('collection', 'id, name')
		).subscribe(data => {
			this.stores = data['result'];
		});
	}

	create() {
		this.partOrderService.create(<PartOrder> this.form.value).subscribe(partOrder => {
			this.router.navigate([`../${partOrder.id}`], {relativeTo: this.activatedRoute});
		});
	}

	compareFn(c1: any, c2: any): boolean {
		return c1 && c2 ? c1.id === c2.id : c1 === c2;
	}
}
