import { HttpParams } from '@angular/common/http';
import { Store } from 'app/store/store';
import { Company } from '../company/company';
import { Status } from 'app/status/status';

export class ServiceOrderFilter {
	dtype: string;
	id: number;
	company: Company;
	store: Store;
	statusServiceOrder: Status;

	constructor(filter?: ServiceOrderFilter) {
		if (filter) {
			this.dtype = filter.dtype;
			this.id = filter.id;
			this.company = filter.company;
			this.store = filter.store;
			this.statusServiceOrder = filter.statusServiceOrder;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.dtype) {
			httpParams = httpParams.set('dtype', this.dtype);
		}
		if (this.id) {
			httpParams = httpParams.set('id', this.id.toString());
		}
		if (this.company && this.company.id) {
			httpParams = httpParams.set('companyId', this.company.id.toString());
		}
		if (this.store && this.store.id) {
			httpParams = httpParams.set('storeId', this.store.id.toString());
		}
		if (this.statusServiceOrder && this.statusServiceOrder.id) {
			httpParams = httpParams.set('statusServiceOrderId', this.statusServiceOrder.id.toString());
		}
		return httpParams;
	}
}
