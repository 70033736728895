
		<div *ngIf="items?.length > 0 else empty">
			<ul>
				<li *ngFor="let item of items">
					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@name">Name</span>
							<span><a [href]="item.multimediaCDN.url" target="_blank">{{item.name}}</a></span>
						</div>
					</div>
				</li>
			</ul>
		</div>
		<ng-template #empty>
			<div class="table-empty" i18n="@@no-records">Records not found</div>
		</ng-template>
	