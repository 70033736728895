import { Component, Input } from '@angular/core';
import { Media } from 'app/media/media';

@Component({
	selector: 'gallery-image-preview-unique',
	template: `
		<div *ngIf="item else empty">
			<img class="thumbnail" [src]="item.multimediaCDN.url" alt="{{item.name}}" (click)="viewImage = false;" height="100">
		</div>

		<ng-template #empty>
			<div class="table-empty" i18n="@@no-record">Record not found</div>
		</ng-template>

		<modal-ns [(closed)]="viewImage">
			<ng-template modalContentDirective>
				<div class="dialog-content" style="width:auto;">
					<img [src]="item.multimediaCDN.url" alt="{{item.name}}" width="600">
				</div>
			</ng-template>
		</modal-ns>
	`
})
export class GalleryImagePreviewUniqueComponent {
	@Input() item: Media;
	viewImage = true;
}
