import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BaseService } from 'app/core/base.service';
import { ServiceOrder } from './serviceOrder';
import { Media } from 'app/media/media';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ServiceOrderService {
	private static readonly BASE_URL: string = BaseService.HOST + '/serviceOrders';

	constructor(
		private http: HttpClient,
		private fb: FormBuilder
	) {}

	getById(id: number): Observable<ServiceOrder> {
		return this.http.get(ServiceOrderService.BASE_URL + '/' + id)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	getAll(params: HttpParams): Observable<any> {
		return this.http.get(ServiceOrderService.BASE_URL, {params: params})
			.pipe(catchError(BaseService.handleError));
	}

	create(serviceOrder: ServiceOrder): Observable<ServiceOrder> {
		return this.http.post(ServiceOrderService.BASE_URL, serviceOrder)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	createMedia(id: number, media: Media): Observable<Media> {
		return this.http.post(ServiceOrderService.BASE_URL + '/' + id + '/mediaSignature', media)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	update(serviceOrder: ServiceOrder): Observable<ServiceOrder> {
		return this.http.put(ServiceOrderService.BASE_URL + '/' + serviceOrder.id, serviceOrder)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	updateStatusServiceOrder(serviceOrder: ServiceOrder): Observable<ServiceOrder> {
		return this.http.patch(ServiceOrderService.BASE_URL + '/' + serviceOrder.id + '/statusServiceOrder', serviceOrder)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	delete(id: number): Observable<any> {
		return this.http.delete(ServiceOrderService.BASE_URL + '/' + id)
			.pipe(catchError(BaseService.handleError));
	}

	deletes(serviceOrders: ServiceOrder[]): Observable<any> {
		return this.http.post(ServiceOrderService.BASE_URL + '/delete', serviceOrders)
			.pipe(catchError(BaseService.handleError));
	}

	toFormGroup(serviceOrder: ServiceOrder): FormGroup {
		return this.fb.group({
			id: new FormControl(serviceOrder.id),
			description: new FormControl(serviceOrder.description),
			store: new FormControl(serviceOrder.store, Validators.required),
			statusServiceOrder: new FormControl(
				serviceOrder.statusServiceOrder ? {id: serviceOrder.statusServiceOrder.id} : undefined,
				Validators.required
			)
		});
	}
}
