import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Company } from './company';
import { CompanyService } from './company.service';
import { NotificationService} from 'app/shared/notification/notification.service';

@Component({
	template: `
		<h3 class="title" i18n="@@detail-company">Company Detail</h3>

		<div class="tool-bar-wrap both-side">
			<div class="right row">
				<button class="btn-icon-text" type="button" (click)="getCompanyContracts()" *hasPermission="['CompanyContracts.findAll']">
					<span i18n="@@companyContracts">Company Contracts</span>
				</button>
				<button class="btn-icon-text" type="button" (click)="getStores()" *hasPermission="['Stores.findAll']">
					<span i18n="@@stores">Branchs</span>
				</button>
				<button class="btn-icon" i18n-title="@@option-update" title="Update" type="button" (click)="update()" *hasPermission="['Companies.update']">
					<i class="material-icons">edit</i>
				</button>
				<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false" *hasPermission="['Companies.update']">
					<i class="material-icons">delete</i>
				</button>
			</div>
		</div>

		<div class="fieldset-wrap">
			<div class="fieldset">
				<div class="legend" i18n="@@data-company">Company data</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@name">Name</span>
						<span>{{company.name || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@statusFinancial">Financial Status</span>
						<span translate>{{company.statusFinancial?.name || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@description">Description</span>
						<span>{{company.description || '-'}}</span>
					</div>
				</div>
			</div>

			<div class="fieldset">
				<div class="legend" i18n="@@data-contact">Contact data</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@phone">Phone</span>
						<span>{{company.contact?.phone || '-'}}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@phone2">Second phone</span>
						<span>{{company.contact?.phone2 || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@latitude">Latitude</span>
						<span>{{company.contact?.latitude || '-'}}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@longitude">Longitude</span>
						<span>{{company.contact?.longitude || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@address">Address</span>
						<span>{{company.contact?.address || '-'}}</span>
					</div>
				</div>
			</div>
		</div>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">Are you sure you want to delete the item?</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	`
})
export class CompanyReadComponent implements OnInit {
	confirmDelete = true;
	company = new Company();

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private location: Location,
		private companyService: CompanyService,
		private notificationService: NotificationService,
	) {}

	ngOnInit() {
		this.activatedRoute.params.subscribe(params => {
			this.companyService.getById(params['companyId']).subscribe(company => {
				this.company = company;
			});
		});
	}

	getCompanyContracts() {
		this.router.navigate(['./companyContracts'], {relativeTo: this.activatedRoute});
	}

	getStores() {
		this.router.navigate(['./stores'], {relativeTo: this.activatedRoute});
	}

	update() {
		this.router.navigate(['./update'], {relativeTo: this.activatedRoute});
	}

	delete() {
		this.companyService.delete(this.company.id).subscribe(() =>  {
			this.notificationService.sucessDelete();
			let url = this.location.path();
			this.router.navigate([url.substring(0, url.lastIndexOf('/'))]);
		}, err => this.notificationService.error(err));

	}
}
