import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule }  from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { PolicyModule } from './policy/policy.module';
import { NotificationService } from 'app/shared/notification/notification.service';
import { routing } from './app.routes';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { AccountModule } from './account/account.module';
import { AuthInterceptorService } from './core/auth/auth-interceptor.service';
import { ContextMenuModule } from 'ngx-contextmenu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthPermissionService } from './auth/authPermission.service';
import { UserService } from './user/user.service';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/');
}

export const CUSTOM_DATE_FORMAT = {
	parse: {
		dateInput: 'LL',
	},
	display: {
		dateInput: 'LL',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@NgModule({
	imports: [
		BrowserModule,
		ReactiveFormsModule,
		FormsModule,
		HttpClientModule,
		BrowserAnimationsModule,
		routing,
		SharedModule,
		AccountModule,
		DashboardModule,
		PolicyModule,
		ContextMenuModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		})
	],
	providers: [
		AuthPermissionService,
		UserService,
		NotificationService,
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
		// i18n in JIT compiler: provide the LOCALE_ID in the main module
		{ provide: LOCALE_ID, useValue: 'en' },
		// https://material.angular.io/components/datepicker/overview
		{ provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
		{ provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT },
	],
	declarations: [ AppComponent ],
	bootstrap: [ AppComponent ]
})
export class AppModule { }
