import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { PartOrderComponent } from './partOrder.component';
import { PartOrderListComponent } from './partOrder-list.component';
import { PartOrderCreateComponent } from './partOrder-create.component';
import { PartOrderService } from './partOrder.service';
import { ContextMenuModule } from 'ngx-contextmenu';
import { PartOrderReadComponent } from './partOrder-read.component';
import { dashboardRouting } from 'app/dashboard/dashboard.routes';
import { PartRequestModule } from '../partRequest/partRequest.module';
import { StatusPartOrderService } from 'app/status/statusPartOrder.service';
import { ShipmentModule } from 'app/shipment/shipment.module';
import { MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule, MatExpansionModule, MatSelectModule } from '@angular/material';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		dashboardRouting,
		ReactiveFormsModule,
		PartRequestModule,
		ShipmentModule,
		ContextMenuModule,
		MatTableModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule,
		MatExpansionModule,
		MatSelectModule
	],
	declarations: [
		PartOrderComponent,
		PartOrderListComponent,
		PartOrderReadComponent,
		PartOrderCreateComponent
	],
	providers: [
		PartOrderService,
		StatusPartOrderService
	],
})
export class PartOrderModule { }
