import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MachineType } from './machineType';
import { MachineTypeService } from './machineType.service';
import { NotificationService} from 'app/shared/notification/notification.service';
import { MatTableDataSource, PageEvent } from '@angular/material';
import { TableService } from 'app/core/models/pager';
import { Global } from 'app/core/global';
import { ServiceType } from '../serviceType/serviceType';
import { ServiceTypeService } from '../serviceType/serviceType.service';
import { HttpParams } from '@angular/common/http';
import { MachineTypeFilter } from './machineType.filter';

@Component({
	template: `
		<h3 class="title" i18n="@@machineTypes">Machine Types</h3>

		<mat-accordion>
			<mat-expansion-panel [expanded]="isOpenSearchPanel" (opened)="openSearchPanel(true)" [hideToggle]="true">
				<mat-expansion-panel-header>
					<div *ngIf="tableService.filter" class="filter-panel-header fieldset-wrap">
						<div class="field-row">
							<div class="field-row-item" *ngIf="tableService.filter.name">
								<span class="label" i18n="@@name">Name</span>
								<span>{{tableService.filter.name}}</span>
							</div>
							<div class="field-row-item" *ngIf="tableService.filter.serviceType">
								<span class="label" i18n="@@serviceType">Service type</span>
								<span>{{tableService.filter.serviceType.name}}</span>
							</div>
						</div>
					</div>
					<button class="btn-icon" i18n-title="@@option-search" title="Search" type="button">
						<i class="material-icons">search</i>
					</button>
				</mat-expansion-panel-header>

				<form>
					<fieldset class="fieldset">
						<div class="field-row">
							<div class="field-row-item">
								<label class="label" for="serial" i18n="@@name">Name</label>
								<input type="text" name="serial" placeholder="None" i18n-placeholder="None" [(ngModel)]="filter.name">
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<label class="label" for="serviceType" i18n="@@serviceType">Service Type</label>
								<mat-select placeholder="None" i18n-placeholder="None" [(value)]="filter.serviceType" [compareWith]="compareFn" disableRipple>
									<mat-option i18n="@@select-none">None</mat-option>
									<mat-option *ngFor="let opt of serviceTypes" [value]="opt">{{opt.name}}</mat-option>
								</mat-select>
							</div>
						</div>
					</fieldset>
					<div class="options">
						<button class="btn-text gray" type="button" (click)="reset()">
							<span i18n="@@option-reset">Reset</span>
						</button>
						<button class="btn-text blue" type="button" (click)="search()">
							<span i18n="@@option-search">Search</span>
						</button>
					</div>
				</form>
			</mat-expansion-panel>
		</mat-accordion>

		<div class="tool-bar-wrap both-side">
			<div class="right row">
				<button class="btn-icon" i18n-title="@@option-create" type="button" (click)="create()" *hasPermission="['MachineTypes.create']">
					<i class="material-icons">add</i>
				</button>
				<ng-template [hasPermission]="['MachineTypes.delete']">
					<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false" *ngIf="tableService.selection.hasValue()">
						<i class="material-icons">delete</i>
					</button>
				</ng-template>
			</div>
		</div>

		<div class="mat-elevation-z8e" *ngIf="dataSource">
			<table mat-table [dataSource]="dataSource" matSort (matSortChange)="tableService.sortData($event); list();">
				<ng-container matColumnDef="select">
					<th mat-header-cell *matHeaderCellDef>
						<mat-checkbox (change)="tableService.masterToggle(dataSource.data)"
							[checked]="tableService.selection.hasValue() && tableService.isAllSelected(dataSource.data.length)"
							[indeterminate]="tableService.selection.hasValue() && !tableService.isAllSelected(dataSource.data.length)"
							*hasPermission="['MachineTypes.delete']">
						</mat-checkbox>
					</th>
					<td mat-cell *matCellDef="let row">
						<mat-checkbox (click)="$event.stopPropagation()"
							(change)="tableService.selection.toggle(row)"
							[checked]="tableService.selection.isSelected(row)"
							*hasPermission="['MachineTypes.delete']">
						</mat-checkbox>
					</td>
				</ng-container>

				<ng-container matColumnDef="name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@name">Name</th>
					<td mat-cell *matCellDef="let element">{{element.name}}</td>
				</ng-container>

				<ng-container matColumnDef="description">
					<th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@description">Description</th>
					<td mat-cell *matCellDef="let element">{{element.description}}</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns;"
					(contextmenu)="tableService.selection.clear(); tableService.selection.select(row)" [contextMenu]="basicMenu" [contextMenuSubject]="row"
					(click)="read(row)">
				</tr>
			</table>

			<mat-paginator showFirstLastButtons
				[length]="tableService.pager.totalEntities"
				[pageIndex]="tableService.pager.pageIndex"
                [pageSize]="tableService.pager.pageSize"
				[pageSizeOptions]="[20, 50, 100]"
				(page)="list($event)">
			</mat-paginator>
		</div>

		<context-menu #basicMenu>
			<ng-template [hasPermission]="['MachineModels.findAll']">
				<ng-template contextMenuItem (execute)="getMachineModels($event.item)">
					<div class="item">
						<span i18n="@@machineModel">machineModel</span>
					</div>
				</ng-template>
			</ng-template>

			<ng-template [hasPermission]="['MachineTypes.update']">
				<ng-template contextMenuItem divider="true"></ng-template>
				<ng-template contextMenuItem (execute)="update($event.item)">
					<div class="item">
						<i class="material-icons">edit</i>
						<span i18n="@@option-update">Update</span>
					</div>
				</ng-template>
			</ng-template>

			<ng-template [hasPermission]="['MachineTypes.delete']">
				<ng-template contextMenuItem divider="true"></ng-template>
				<ng-template contextMenuItem (execute)="confirmDelete = false">
					<div class="item">
						<i class="material-icons">delete</i>
						<span i18n="@@option-delete">Delete</span>
					</div>
				</ng-template>
			</ng-template>
		</context-menu>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-deletes">Are you sure you want to delete the selected items?</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="deletes(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	`
})
export class MachineTypeListComponent implements OnInit {
	dataSource: MatTableDataSource<MachineType>;
	displayedColumns: string[] = ['select', 'name', 'description'];
	serviceTypes: ServiceType[];
	confirmDelete = true;
	isOpenSearchPanel = false;
	filter;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private machineTypeService: MachineTypeService,
		private serviceTypeService: ServiceTypeService,
		public tableService: TableService<MachineType>,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		if (!this.tableService.filter) {
			this.filter = new MachineTypeFilter();
			this.tableService.filter = new MachineTypeFilter();

			let serviceTypeId = Global.getParamFromRoot(this.activatedRoute, 'serviceTypeId');
			if (serviceTypeId) {
				this.serviceTypeService.getById(serviceTypeId).subscribe(serviceType => {
					this.filter.serviceType = serviceType;
					this.tableService.filter.serviceType = serviceType;
					this.list();
				});
			} else {
				this.list();
			}
		} else {
			this.filter = new MachineTypeFilter(this.tableService.filter);
			this.list();
		}
	}

	list(event?: PageEvent) {
		let httpParams = new HttpParams()
			.set('collection', 'id, name, description')
			.set('pager.index', event ? event.pageIndex.toString() : this.tableService.pager.pageIndex.toString())
			.set('pager.size', event ? event.pageSize.toString() : this.tableService.pager.pageSize.toString());

		if (this.tableService.sort) { httpParams = httpParams.set('sort', this.tableService.sort) }

		httpParams = this.filter.getHttpParams(httpParams);

		this.machineTypeService.getAll(httpParams).subscribe(data => {
			this.dataSource = new MatTableDataSource<MachineType>(data['result']);
			this.tableService.pager = data['pager'];
			this.tableService.selection.clear();
		});
	}

	getMachineModels(item: MachineType) {
		this.router.navigate(['./' + item.id + '/machineModels'], {relativeTo: this.activatedRoute});
	}

	read(item: MachineType) {
		this.router.navigate(['./' + item.id], {relativeTo: this.activatedRoute});
	}

	create() {
		this.router.navigate(['./create'], {relativeTo: this.activatedRoute});
	}

	update(item: MachineType) {
		this.router.navigate(['./' + item.id + '/update'], {relativeTo: this.activatedRoute});
	}

	deletes() {
		const length = this.tableService.selection.selected.length;
		this.tableService.pager.pageIndex = (this.tableService.pager.pageIndex > 0 && this.tableService.pager.totalEntitiesPerPage === length) ?
			--this.tableService.pager.pageIndex : this.tableService.pager.pageIndex;

		if (length === 1) {
			this.machineTypeService.delete(this.tableService.selection.selected[0].id).subscribe(() => {
				this.notificationService.sucessDelete();
				this.list();
			}, err => this.notificationService.error(err));
		} else if (length > 1) {
			this.machineTypeService.deletes(this.tableService.selection.selected).subscribe(() => {
				this.notificationService.sucessDelete();
				this.list();
			}, err => this.notificationService.error(err));
		}
	}

	reset() {
		this.filter = new MachineTypeFilter();
	}

	search() {
		this.isOpenSearchPanel = false;
		this.tableService.pager.pageIndex = 0;
		this.tableService.filter = new MachineTypeFilter(this.filter);
		this.list();
	}

	openSearchPanel(value: boolean) {
		this.isOpenSearchPanel = value;
		this.filter = new MachineTypeFilter(this.tableService.filter);

		if (!this.serviceTypes) {
			let httpParams = new HttpParams().set('sort', 'name').set('collection', 'id, name');
			this.serviceTypeService.getAll(httpParams).subscribe(data => {
				this.serviceTypes = data['result'];
			});
		}
	}

	compareFn(c1: any, c2: any): boolean {
		return c1 && c2 ? c1.id === c2.id : c1 === c2;
	}
}
