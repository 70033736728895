import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { CompanyContractComponent } from './companyContract.component';
import { CompanyContractListComponent } from './companyContract-list.component';
import { CompanyContractReadComponent } from './companyContract-read.component';
import { CompanyContractCreateComponent } from './companyContract-create.component';
import { CompanyContractUpdateComponent } from './companyContract-update.component';
import { CompanyContractService } from './companyContract.service';
import { dashboardRouting } from 'app/dashboard/dashboard.routes';
import { ContextMenuModule } from 'ngx-contextmenu';
import { MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule, MatExpansionModule, MatSelectModule } from '@angular/material';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		dashboardRouting,
		FormsModule,
		ReactiveFormsModule,
		ContextMenuModule,
		MatTableModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule,
		MatExpansionModule,
		MatSelectModule
	],
	declarations: [
		CompanyContractComponent,
		CompanyContractListComponent,
		CompanyContractReadComponent,
		CompanyContractCreateComponent,
		CompanyContractUpdateComponent,
		CompanyContractListComponent
	],
	providers: [
		CompanyContractService
	],
})
export class CompanyContractModule { }
