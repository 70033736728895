import { HttpParams } from '@angular/common/http';
import { MachineBrand } from '../machineBrand/machineBrand';
import { MachineType } from '../machineType/machineType';

export class MachineModelFilter {
	name: string;
	comercialName: string;
	machineType: MachineType;
	machineBrand: MachineBrand;

	constructor(filter?: MachineModelFilter) {
		if (filter) {
			this.name = filter.name;
			this.comercialName = filter.comercialName;
			this.machineType = filter.machineType;
			this.machineBrand = filter.machineBrand;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.name) {
			httpParams = httpParams.set('name', this.name);
		}
		if (this.comercialName) {
			httpParams = httpParams.set('comercialName', this.comercialName);
		}
		if (this.machineType && this.machineType.id) {
			httpParams = httpParams.set('machineTypeId', this.machineType.id.toString());
		}
		if (this.machineBrand && this.machineBrand.id) {
			httpParams = httpParams.set('machineBrandId', this.machineBrand.id.toString());
		}
		return httpParams;
	}
}
