import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { dashboardRouting } from 'app/dashboard/dashboard.routes';
import { StatusShipmentService } from 'app/status/statusShipment.service';
import { ShipmentStageListComponent } from './shipmentStage-list.component';
import { ShipmentStageService } from './shipmentStage.service';
import { MatTableModule } from '@angular/material';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		dashboardRouting,
		MatTableModule
	],
	declarations: [
		ShipmentStageListComponent
	],
	exports: [
		ShipmentStageListComponent
	],
	providers: [
		ShipmentStageService,
		StatusShipmentService
	],
})
export class ShipmentStageModule { }
