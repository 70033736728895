import { HttpParams } from '@angular/common/http';

export class MachineBrandFilter {
	name: string;

	constructor(filter?: MachineBrandFilter) {
		if (filter) {
			this.name = filter.name;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.name) {
			httpParams = httpParams.set('name', this.name);
		}
		return httpParams;
	}
}
