import { Component, Input } from '@angular/core';
import { Media } from 'app/media/media';

@Component({
	selector: 'gallery-pdf-preview-unique',
	template: `
		<span *ngIf="item else empty">
			<a [href]="item.multimediaCDN.url" target="_blank">{{item.name}}</a>
		</span>

		<ng-template #empty>
			<div class="table-empty" i18n="@@no-record">record not found</div>
		</ng-template>
	`
})
export class GalleryPdfPreviewUniqueComponent {
	@Input() item: Media;
}
