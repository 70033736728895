	
		<div i18n="@@recoverPass-forgot">
			<div class="dialog-title">Forgot your password?</div>
			<div class="dialog-message">Enter your email address and we'll email you a pin to create a new password. If you have the pin, enter <u class="clickable-item" routerLink="./reset">here</u></div>
		</div>

		<form [formGroup]="form" (ngSubmit)="requestPin()">
			<fieldset class="fieldset">
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="email">Email</label>
						<input formControlName="email" type="text" autocomplete="off">
						<validator [control]="form.controls['email']"></validator>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-send-pass">Send me reset password pin</span>
				</button>
			</div>
		</form>

		<a class="clickable-item link" routerLink="../login" i18n="@@back-signup">Back to <u>Sign in</u></a>
	