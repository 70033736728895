import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from 'app/core/base.service';
import { UserClient } from './userClient';
import { FormControl, FormGroup } from '@angular/forms';
import { UserService } from 'app/user/user.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class UserClientService {
	private static readonly BASE_URL: string = BaseService.HOST + '/userClients';

	constructor(
		private http: HttpClient,
		private userService: UserService
	) {}

	getById(id: number): Observable<UserClient> {
		return this.http.get(UserClientService.BASE_URL + '/' + id)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	getAll(params: HttpParams): Observable<any> {
		return this.http.get(UserClientService.BASE_URL, {params: params})
			.pipe(catchError(BaseService.handleError));
	}

	create(userClient: UserClient): Observable<UserClient> {
		return this.http.post(UserClientService.BASE_URL, userClient)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	update(userClient: UserClient): Observable<UserClient> {
		return this.http.put(UserClientService.BASE_URL + '/' + userClient.id, userClient)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	delete(id: number): Observable<any> {
		return this.http.delete(UserClientService.BASE_URL + '/' + id)
			.pipe(catchError(BaseService.handleError));
	}

	deletes(userClients: UserClient[]): Observable<any> {
		return this.http.post(UserClientService.BASE_URL + '/delete', userClients)
			.pipe(catchError(BaseService.handleError));
	}

	toFormGroup(userClient: UserClient): FormGroup {
		let form = this.userService.toFormGroup(userClient);

		let companies;
		if (userClient.companies) {
			companies = userClient.companies.map(company => { return {id: company.id}; });
		}
		form.addControl('companies', new FormControl(companies));
		return form;
	}

	toFormGroupUpdate(userClient: UserClient): FormGroup {
		let form = this.userService.toFormGroupUpdate(userClient);

		let companies;
		if (userClient.companies) {
			companies = userClient.companies.map(company => { return {id: company.id}; });
		}
		form.addControl('companies', new FormControl(companies));
		return form;
	}

	toFormGroupUpdatePassword(userClient: UserClient): FormGroup {
		return this.userService.toFormGroupUpdatePassword(userClient);
	}
}
