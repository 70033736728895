import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { MachineBrandComponent } from './machineBrand.component';
import { MachineBrandListComponent } from './machineBrand-list.component';
import { MachineBrandReadComponent } from './machineBrand-read.component';
import { MachineBrandCreateComponent } from './machineBrand-create.component';
import { MachineBrandUpdateComponent } from './machineBrand-update.component';
import { MachineBrandService } from './machineBrand.service';
import { dashboardRouting } from 'app/dashboard/dashboard.routes';
import { ContextMenuModule } from 'ngx-contextmenu';
import { MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule, MatExpansionModule } from '@angular/material';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		dashboardRouting,
		FormsModule,
		ReactiveFormsModule,
		ContextMenuModule,
		MatTableModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule,
		MatExpansionModule
	],
	declarations: [
		MachineBrandComponent,
		MachineBrandListComponent,
		MachineBrandReadComponent,
		MachineBrandCreateComponent,
		MachineBrandUpdateComponent
	],
	providers: [
		MachineBrandService
	],
})
export class MachineBrandModule { }
