import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { ServiceTypeComponent } from './serviceType.component';
import { ServiceTypeListComponent } from './serviceType-list.component';
import { ServiceTypeReadComponent } from './serviceType-read.component';
import { ServiceTypeCreateComponent } from './serviceType-create.component';
import { ServiceTypeUpdateComponent } from './serviceType-update.component';
import { ServiceTypeService } from './serviceType.service';
import { dashboardRouting } from '../dashboard/dashboard.routes';
import { ContextMenuModule } from 'ngx-contextmenu';
import { MatTableModule, MatCheckboxModule, MatPaginatorModule, MatSortModule, MatSelectModule, MatExpansionModule } from '@angular/material';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		SharedModule,
		dashboardRouting,
		FormsModule,
		ReactiveFormsModule,
		ContextMenuModule,
		MatTableModule,
		MatCheckboxModule,
		MatPaginatorModule,
		MatSortModule,
		MatExpansionModule,
		MatSelectModule
	],
	declarations: [
		ServiceTypeComponent,
		ServiceTypeListComponent,
		ServiceTypeReadComponent,
		ServiceTypeCreateComponent,
		ServiceTypeUpdateComponent
	],
	providers: [
		ServiceTypeService
	]
})
export class ServiceTypeModule { }
