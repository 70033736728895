
		<h3 class="title" i18n="@@create-serviceRecord">Create Order Record</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="create()">
			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-serviceRecord">Order Record data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="startDateTime" i18n="@@startDateTime">Start Date Time</label>
						<div class="datepicker">
							<input matInput [matDatepicker]="picker" [formControl]="form.controls['startDateTime']">
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						</div>
						<mat-datepicker #picker></mat-datepicker>
						<validator [control]="form.controls['startDateTime']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="finishDateTime" i18n="@@finishDateTime">Finish Date Time</label>
						<div class="datepicker">
							<input matInput [matDatepicker]="picker2" [formControl]="form.controls['finishDateTime']">
							<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
						</div>
						<mat-datepicker #picker2></mat-datepicker>
						<validator [control]="form.controls['finishDateTime']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" i18n="@@description">Description</label>
						<textarea formControlName="description"></textarea>
						<validator [control]="form.controls['description']"></validator>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	