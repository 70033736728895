
		<h3 class="title" i18n="@@detail-serviceTask">Job Order Detail</h3>

		<div class="tool-bar-wrap both-side">
			<div class="right row">
				<button class="btn-icon-text" type="button" (click)="getServiceRecords()" *hasPermission="['ServiceRecords.findById']">
					<span i18n="@@serviceRecords">Order Records</span>
				</button>
				<button class="btn-icon" i18n-title="@@option-update" title="Update" type="button" (click)="updateImages()" *hasPermission="['MediaController.createServiceTasks']">
					<i class="material-icons">collections</i>
				</button>
				<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false" *hasPermission="['ServiceTasks.delete']">
					<i class="material-icons">delete</i>
				</button>
			</div>
		</div>

		<div class="fieldset-wrap">
			<div class="fieldset">
				<div class="legend" i18n="@@data-serviceTask">Job Order data</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@id">Id</span>
						<span>{{serviceTask.id || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@status">Status</span>
						<span translate>{{serviceTask.statusServiceTask?.name || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@startDateTime">Start date time</span>
						<!--<span id="startDateTime">{{serviceTask.startDateTime | date:'yyyy-MM-dd' || '-'}}</span>-->
						<span>{{serviceTask.startDateTime | date:'medium' || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@firstName">First name</span>
						<span>{{serviceTask.userEmployee?.firstName}}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@lastName">Last name</span>
						<span>{{serviceTask.userEmployee?.lastName}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@updatedAt">Updated date</span>
						<span>{{serviceTask.updatedAt | date:'medium' || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@observation">Observation</span>
						<span>{{serviceTask.observation || '-'}}</span>
					</div>
				</div>
			</div>

			<div class="fieldset" *ngIf="serviceTask.serviceRecord">
				<div class="legend" i18n="@@data-serviceRecord">Order Record data</div>

				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@startDateTime">startDateTime</span>
						<span>{{serviceTask.serviceRecord.startDateTime | date:'medium' }}</span>
					</div>
					<div class="field-row-item">
						<span class="label" i18n="@@finishDateTime">finishDateTime</span>
						<span>{{serviceTask.serviceRecord.finishDateTime | date:'medium' }}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@updatedAt">Updated date</span>
						<span>{{serviceTask.updatedAt | date:'medium' || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@description">Description</span>
						<span>{{serviceTask.serviceRecord.description}}</span>
					</div>
				</div>
			</div>

			<div class="fieldset">
				<div class="legend" i18n="@@images">Images</div>
				<div class="field-row">
					<gallery-image-preview [items]="images"></gallery-image-preview>
				</div>
			</div>
		</div>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">
						Are you sure you want to delete the record?
					</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	