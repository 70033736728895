import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from 'app/shared/notification/notification.service';
import { CustomValidators } from 'app/shared/form/custom.validators';
import { AuthService } from '../../core/auth/auth.service';
import { UserService } from '../../user/user.service';
import { AuthPermissionService } from '../../auth/authPermission.service';
import { HttpParams } from '@angular/common/http';
import { AuthPermission } from '../../auth/authPermission';

@Component({
	template: `
		<div i18n="@@signin">
			<div class="dialog-title">Sign in</div>
			<div class="dialog-message">Enter your details below</div>
		</div>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="login()">
			<fieldset class="fieldset">
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="email" i18n="@@email">Email</label>
						<input type="text" formControlName="email" class="form-control">
						<validator [control]="form.controls['email']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="password" i18n="@@password">Password</label>
						<input type="password" formControlName="password" class="form-control">
						<validator [control]="form.controls['password']"></validator>
						<div class="clickable-item" style="text-align: right" routerLink="/recoverPass" i18n="@@forgot-password">Forgot your password?</div>
					</div>
				</div>
			</fieldset>

			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@login">Sign in</span>
				</button>
			</div>
		</form>
	`,
	styleUrls: ['../account.css'],
})
export class SigninComponent implements OnInit {
	form: FormGroup;

	constructor(
		private router: Router,
		private authService: AuthService,
		private notificationService: NotificationService,
		private fb: FormBuilder,
		private userService: UserService,
		private authPermissionService: AuthPermissionService
	) {}

	ngOnInit() {
		this.form = this.toFormGroup();
	}

	login(): void {
		this.authService.token(this.form.value['email'], this.form.value['password']).subscribe(accessTokenResponse => {
			this.authService.setToken(accessTokenResponse);

			const tokenPayload = this.authService.getDecodeToken(this.authService.getAccessToken());
			if (tokenPayload && tokenPayload.id) {
				let httpParams = new HttpParams().set('collection', '*, authUser(*, authGroup(id, name))');

				this.userService.getByAuthUserId(tokenPayload.id, httpParams).subscribe(user => {
					httpParams = new HttpParams()
						.set('authUserId', user.authUser.id.toString())
						.set('collection', 'name');

					this.authPermissionService.getAll(httpParams).subscribe(data => {
						let authPermissions: AuthPermission[] = data.result;
						user.permissions = authPermissions.map(authPermission => authPermission.name);
						this.authService.setCurrentUser(user);

						this.router.navigate(['/dashboard']);
					});
				});
			} else {
				this.authService.clearToken();
			}
		}, err => this.notificationService.error(err));
	}

	private toFormGroup(): FormGroup {
		return this.fb.group({
			email: new FormControl('', [Validators.required, CustomValidators.emailRegex, Validators.maxLength(100)]),
			password: new FormControl('', Validators.required)
		});
	}
}
