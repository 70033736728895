import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from 'app/core/base.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class MediaService  {
	public static readonly BASE_URL: string = BaseService.HOST + '/medias';

	public static getBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = error => reject(error);
		});
	}

	constructor(private http: HttpClient) {
	}

	delete(id: number): Observable<any> {
		return this.http.delete(MediaService.BASE_URL + '/' + id)
			.pipe(catchError(BaseService.handleError));
	}

	deletes(ids: {'ids': number[]}): Observable<any> {
		return this.http.post(MediaService.BASE_URL + '/delete', ids)
			.pipe(catchError(BaseService.handleError));
	}

	public toKilobytes(value: number): number {
		return Math.round(value / Math.pow(2, 10));
	}
}
