import { HttpParams } from '@angular/common/http';

export class PartModelFilter {
	code: string;
	name: string;

	constructor(filter?: PartModelFilter) {
		if (filter) {
			this.code = filter.code;
			this.name = filter.name;
		}
	}

	getHttpParams(httpParams = new HttpParams()): HttpParams {
		if (this.code) {
			httpParams = httpParams.set('code', this.code);
		}
		if (this.name) {
			httpParams = httpParams.set('name', this.name);
		}
		return httpParams;
	}
}
