import { BaseModel } from 'app/core/models/base.model';
import { MachineBrand } from 'app/machineBrand/machineBrand';
import { MachineType } from 'app/machineType/machineType';
import { Media } from 'app/media/media';

export class MachineModel extends BaseModel {
	name: string;
	comercialName: string;
	description: string;
	price: string;
	machineBrand: MachineBrand;
	machineType: MachineType;
	medias: Media[];
}
