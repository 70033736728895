import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from 'app/core/base.service';
import { ShipmentStage } from './shipmentStage';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ShipmentStageService {
	private static readonly BASE_URL: string = BaseService.HOST + '/shipmentStages';

	constructor(
		private http: HttpClient
	) {}

	getById(id: number): Observable<ShipmentStage> {
		return this.http.get(ShipmentStageService.BASE_URL + '/' + id)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	getAll(params: HttpParams): Observable<any> {
		return this.http.get(ShipmentStageService.BASE_URL, {params: params})
			.pipe(catchError(BaseService.handleError));
	}

	create(shipmentStage: ShipmentStage): Observable<ShipmentStage> {
		return this.http.post(ShipmentStageService.BASE_URL, shipmentStage)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	delete(id: number): Observable<any> {
		return this.http.delete(ShipmentStageService .BASE_URL + '/' + id)
			.pipe(catchError(BaseService.handleError));
	}
}
