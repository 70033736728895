import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MachineType } from './machineType';
import { MachineTypeService } from './machineType.service';
import { NotificationService} from 'app/shared/notification/notification.service';

@Component({
	template: `
		<h3 class="title" i18n="@@detail-machineType">Machine Type Detail</h3>

		<div class="tool-bar-wrap both-side">
			<div class="right row">
				<button class="btn-icon-text" type="button" (click)="getMachineModels()" *hasPermission="['MachineModels.findAll']">
					<span i18n="@@machineModels">Machine Models</span>
				</button>
				<button class="btn-icon" i18n-title="@@option-update" title="Update" type="button" (click)="update()" *hasPermission="['MachineTypes.update']">
					<i class="material-icons">edit</i>
				</button>
				<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false" *hasPermission="['MachineTypes.update']">
					<i class="material-icons">delete</i>
				</button>
			</div>
		</div>

		<div class="fieldset-wrap">
			<div class="fieldset">
				<div class="legend" i18n="@@data-machineType">Machine Type data</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@name">Name</span>
						<span id="name">{{machineType.name || '-'}}</span>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<span class="label" i18n="@@description">Description</span>
						<span id="description">{{machineType.description || '-'}}</span>
					</div>
				</div>
			</div>
		</div>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">
						<span>Are you sure you want to delete the selected record?</span>
					</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	`
})
export class MachineTypeReadComponent implements OnInit {
	confirmDelete = true;
	machineType: MachineType = new MachineType();

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private location: Location,
		private machineTypeService: MachineTypeService,
		private notificationService: NotificationService,
	) {}

	ngOnInit() {
		this.activatedRoute
			.params
			.subscribe(param => {
				this.machineTypeService.getById(param['machineTypeId'])
					.subscribe(machineType => {
						this.machineType = machineType;
					});
			});
	}

	update() {
		this.router.navigate(['./update'], {relativeTo: this.activatedRoute});
	}

	getMachineModels() {
		this.router.navigate(['./machineModels'], {relativeTo: this.activatedRoute});
	}

	delete(this) {
		this.machineTypeService.delete(this.machineType.id).subscribe(data => {
			this.notificationService.sucessDelete();
			let url = this.location.path();
			this.router.navigate([url.substring(0, url.lastIndexOf('/'))]);
		}, err => this.notificationService.error(err));
	}
}
