import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { UserGeneric } from './userGeneric';
import { UserService } from '../user/user.service';
import { UserGenericService } from './userGeneric.service';
import { AuthUser } from '../auth/authUser';
import { NotificationService} from 'app/shared/notification/notification.service';
import { Contact } from 'app/core/models/contact';
import { AuthGroup } from 'app/auth/authGroup';
import { AuthGroupService } from '../auth/authGroup.service';
import { HttpParams } from '@angular/common/http';

@Component({
	template: `
		<h3 class="title" i18n="@@create-userGeneric">Create Generic User</h3>

		<form *ngIf="form" [formGroup]="form" (ngSubmit)="create()">
			<fieldset class="fieldset" formGroupName="authUser">
				<legend class="legend" i18n="@@data-account">Account data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="email" i18n="@@email">Email</label>
						<input formControlName="email" type="text" autocomplete="off">
						<validator [control]="form.controls['authUser'].controls['email']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="password" i18n="@@password">Password</label>
						<input formControlName="password" type="password" autocomplete="off">
						<validator [control]="form.controls['authUser'].controls['password']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="passwordConfirm" i18n="@@passwordConfirm">Password confirm</label>
						<input formControlName="passwordConfirm" type="password" autocomplete="off">
						<validator [control]="form.controls['authUser'].controls['passwordConfirm']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="authGroup" i18n="@@authGroup">authGroup</label>
						<mat-select [formControl]="form.controls['authUser'].controls['authGroup']" [compareWith]="compareFn" disableRipple>
							<mat-option i18n="@@select-none">None</mat-option>
							<mat-option *ngFor="let opt of authGroups" [value]="opt">{{opt.name}}</mat-option>
						</mat-select>
					</div>
				</div>
			</fieldset>

			<fieldset class="fieldset">
				<legend class="legend" i18n="@@data-userGeneric">Generic User data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="firstName" i18n="@@firstName">First name</label>
						<input formControlName="firstName" type="text" autocomplete="off">
						<validator [control]="form.controls['firstName']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="lastName" i18n="@@lastName">Last name</label>
						<input formControlName="lastName" type="text" autocomplete="off">
						<validator [control]="form.controls['lastName']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="description" i18n="@@description">Description</label>
						<textarea formControlName="description"></textarea>
					</div>
				</div>
			</fieldset>

			<fieldset class="fieldset" formGroupName="contact">
				<legend class="legend" i18n="@@data-contact">Contact data</legend>

				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="phone" i18n="@@phone">Phone</label>
						<input formControlName="phone" type="text" autocomplete="off">
						<validator [control]="form.controls['contact'].controls['phone']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="phone2" i18n="@@phone2">Second phone</label>
						<input formControlName="phone2" type="text" autocomplete="off">
						<validator [control]="form.controls['contact'].controls['phone2']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="latitude" i18n="@@latitude">Latitude</label>
						<input formControlName="latitude" type="text" autocomplete="off">
						<validator [control]="form.controls['contact'].controls['latitude']"></validator>
					</div>
					<div class="field-row-item">
						<label class="label" for="longitude" i18n="@@longitude">Longitude</label>
						<input formControlName="longitude" type="text" autocomplete="off">
						<validator [control]="form.controls['contact'].controls['longitude']"></validator>
					</div>
				</div>
				<div class="field-row">
					<div class="field-row-item">
						<label class="label" for="address" i18n="@@address">Address</label>
						<textarea formControlName="address"></textarea>
					</div>
				</div>
			</fieldset>
			
			<div class="options">
				<button class="btn-text" type="submit" [disabled]="!form.valid">
					<span i18n="@@option-save">Save</span>
				</button>
			</div>
		</form>
	`
})
export class UserGenericCreateComponent implements OnInit {
	form: FormGroup;
	authGroups: AuthGroup[] = [];

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private userService: UserService,
		private userGenericService: UserGenericService,
		private authGroupService: AuthGroupService,
		private notificationService: NotificationService,
	) {
		let userGeneric = new UserGeneric();
		userGeneric.contact = new Contact();
		userGeneric.authUser = new AuthUser();
		this.form = this.userGenericService.toFormGroup(userGeneric);
	}

	ngOnInit() {
		this.authGroupService.getAll(
			new HttpParams().set('sort', 'name').set('collection', 'id, name')
		).subscribe(data => {
			this.authGroups = data['result'];
		});
	}

	create() {
		this.userService.getByEmail(this.form.value['authUser'].email).subscribe(user => {
			if (user) {
				this.notificationService.errorDuplicated();
			} else {
				this.userGenericService.create(this.form.value).subscribe(userGeneric =>  {
					this.notificationService.sucessInsert(userGeneric.firstName + ' ' + userGeneric.lastName);
					this.router.navigate([`../${userGeneric.id}`], {relativeTo: this.activatedRoute});
				}, err => this.notificationService.error(err));
			}
		}, err => this.notificationService.error(err));
	}

	compareFn(c1: any, c2: any): boolean {
		return c1 && c2 ? c1.id === c2.id : c1 === c2;
	}
}
