import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule }  from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { AuthGuardService } from '../core/auth/auth-guard.service';
import { SigninComponent } from './signin/signin.component';
import { AuthService } from '../core/auth/auth.service';
import { RecoverPassForgotComponent } from './recoverPass/recoverPass-forgot.component';
import { RouterModule } from '@angular/router';
import { RecoverPassResetComponent } from './recoverPass/recoverPass-reset.component';
import { RecoverPassDoneComponent } from './recoverPass/recoverPass-done';
import { RecoverPassComponent } from './recoverPass/recoverPass.component';
import { AccountComponent } from './account.component';
import { UserService } from '../user/user.service';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';

@NgModule({
	imports: [
		RouterModule,
		CommonModule,
		SharedModule,
		FormsModule,
		ReactiveFormsModule,
		NgProgressModule.forRoot(),
		NgProgressHttpModule.forRoot()
	],
	declarations: [
		AccountComponent,
		SigninComponent,
		RecoverPassComponent,
		RecoverPassForgotComponent,
		RecoverPassResetComponent,
		RecoverPassDoneComponent
	],
	providers: [
		AuthService,
		UserService,
		AuthGuardService
	]
})

export class AccountModule { }
