import { Component, Input } from '@angular/core';
import { Media } from 'app/media/media';

@Component({
	selector: 'gallery-image-preview',
	template: `
		<div *ngIf="items?.length > 0 else empty">
			<ul class="container-items">
				<li *ngFor="let item of items">
					<div>
						<img class="thumbnail" [src]="item.multimediaCDN.url" alt="{{item.name}}" (click)="selected = item; viewImage = false;">
					</div>
				</li>
			</ul>
		</div>
		<ng-template #empty>
			<div class="table-empty" i18n="@@no-records">Records not found</div>
		</ng-template>

		<modal-ns [(closed)]="viewImage">
			<ng-template modalContentDirective>
				<div class="dialog-content" style="width:auto;">
					<img [src]="selected.multimediaCDN.url" alt="{{selected.name}}" width="600">
				</div>
			</ng-template>
		</modal-ns>
	`,
	styleUrls: ['./gallery-image.component.css']
})
export class GalleryImagePreviewComponent {
	@Input() items: Media[];
	viewImage = true;
	selected: Media;
}
