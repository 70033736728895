import { BaseModel } from 'app/core/models/base.model';
import { AuthUser } from '../auth/authUser';
import { Contact } from 'app/core/models/contact';
import { Media } from '../media/media';

export class User extends BaseModel {
	authUser: AuthUser;
	firstName: string;
	lastName: string;
	fullName: string;
	description: string;
	contact: Contact = new Contact();
	mediaPhoto: Media;

	permissions: string[]; // special for hasPermissionsDirective
}
