import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';
import { ServiceTaskModule } from '../serviceTask/serviceTask.module';
import { ServiceRequestComponent } from './serviceRequest.component';
import { ServiceRequestListComponent } from './serviceRequest-list.component';
import { ServiceRequestReadComponent } from './serviceRequest-read.component';
import { ServiceRequestCreateComponent } from './serviceRequest-create.component';
import { ServiceRequestService } from './serviceRequest.service';
import { StatusServiceOrderService } from '../status/statusServiceOrder.service';
import { dashboardRouting } from '../dashboard/dashboard.routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContextMenuModule } from 'ngx-contextmenu';
import { MatCheckboxModule, MatSelectModule } from '@angular/material';

@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		ServiceTaskModule,
		dashboardRouting,
		FormsModule,
		ReactiveFormsModule,
		ContextMenuModule,
		MatCheckboxModule,
		MatSelectModule
	],
	declarations: [
		ServiceRequestComponent,
		ServiceRequestListComponent,
		ServiceRequestReadComponent,
		ServiceRequestCreateComponent,
	],
	exports: [
		ServiceRequestListComponent
	],
	providers: [
		ServiceRequestService,
		StatusServiceOrderService
	],
})
export class ServiceRequestModule { }
