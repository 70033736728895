import { BaseModel } from 'app/core/models/base.model';
import { MachineType } from '../machineType/machineType';

export class ServiceType extends BaseModel {
	name: string;
	workedTime: number;
	price: number;
	description: number;
	machineTypes: MachineType[] = new Array<MachineType>();
}
