import { Component } from '@angular/core';

@Component({
	template: `
		<div i18n="@@recoverPass-done">
			<div class="dialog-title">Your password has been reset!</div>
			<div class="dialog-message">Now you can <u class="clickable-item" routerLink="../login">Sign in</u> with your new password</div>
		</div>
	`,
	styleUrls: ['../account.css']
})
export class RecoverPassDoneComponent {
}
