import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { NotificationService} from 'app/shared/notification/notification.service';
import { ServiceOrder } from '../serviceOrder/serviceOrder';
import { RequestServiceService } from './requestService.service';
import * as moment from 'moment';
import { Status } from 'app/status/status';
import { TableService } from 'app/core/models/pager';

@Component({
	template: `
		<h3 class="title" i18n="@@detail-serviceOrder">Service Order Detail</h3>

		<ng-container *ngIf="serviceOrder">
			<div class="tool-bar-wrap both-side">
				<div class="right row">
					<ng-template [hasPermission]="['RequestServices.delete']">
						<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false">
							<i class="material-icons">delete</i>
						</button>
					</ng-template>
				</div>
			</div>

			<div class="fieldset-wrap" style="margin-bottom: 30px;">
				<div class="fieldset both-side">
					<div>
						<div class="legend" i18n="@@data-serviceOrder">Service Order data</div>
						<div class="field-row">
							<div class="field-row-item">
								<span class="label" i18n="@@id">Id</span>
								<span>{{serviceOrder.id || '-'}}</span>
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<span class="label" i18n="@@type">Type</span>
								<span>{{serviceOrder.dtype || '-'}}</span>
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<span class="label" i18n="@@company">Company</span>
								<span>{{serviceOrder.store?.company?.name || '-'}}</span>
							</div>
							<div class="field-row-item">
								<span class="label" i18n="@@store">Branch</span>
								<span>{{serviceOrder.store?.name || '-'}}</span>
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<span class="label" i18n="@@createdAt">Created date</span>
								<span>{{serviceOrder.createdAt | date:'medium' || '-'}}</span>
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<span class="label" i18n="@@description">Description</span>
								<span>{{serviceOrder.description || '-'}}</span>
							</div>
						</div>
					</div>

					<div class="right row" *hasPermission="['RequestServices.updateStatusServiceOrder']">
						<dropdown-ns [value]="{id: serviceOrder.statusServiceOrder.id}" (changed)="updateStatusServiceOrder($event)">
							<ng-template *ngIf="so_draft()" dropdownOption [value]="{id: 'so_draft'}">
								<span i18n="@@so_draft">Draft</span>
							</ng-template>
							<ng-template *ngIf="so_requested('selector')" dropdownOption [value]="{id: 'so_requested'}">
								<span i18n="@@so_requested">Requested</span>
							</ng-template>
							<ng-template *ngIf="so_revision('selector')" dropdownOption [value]="{id: 'so_revision'}">
								<span i18n="@@so_revision">Revision</span>
							</ng-template>
							<ng-template *ngIf="so_rejected('selector')" dropdownOption [value]="{id: 'so_rejected'}">
								<span i18n="@@so_rejected">Rejected</span>
							</ng-template>
						</dropdown-ns>

						<button *ngIf="so_requested('button')"
							class="btn-status requested"
							(click)="updateStatusServiceOrder({id: 'so_requested'})"
							i18n="@@so_request">Request</button>
						<button *ngIf="so_rejected('button')"
							class="btn-status rejected"
							(click)="updateStatusServiceOrder({id: 'so_rejected'})"
							i18n="@@so_reject">Reject</button>
						<button *ngIf="so_revision('button')"
							class="btn-status approved"
							(click)="updateStatusServiceOrder({id: 'so_revision'})"
							i18n="@@so_revision">Revision</button>
					</div>
				</div>
			</div>
		</ng-container>

		<requestServiceItem-list *ngIf="serviceOrder"
			[serviceOrder]="serviceOrder"
			(readRequestService)="read()">
		</requestServiceItem-list>
	`
})
export class RequestServiceReadComponent implements OnInit {
	confirmDelete = true;
	serviceOrder: ServiceOrder;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private location: Location,
		private requestServiceService: RequestServiceService,
		public tableService: TableService<ServiceOrder>,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		this.activatedRoute.params.subscribe(params => {
			this.read(params['requestServiceId']);
		});
	}

	read(id: number = this.serviceOrder.id) {
		this.requestServiceService.getById(id).subscribe(serviceOrder => {
			this.serviceOrder = serviceOrder;
			this.serviceOrder.createdAt = moment(this.serviceOrder.createdAt).toISOString().toString(); // the angular pipe failed with the raw date, ie: 2018-11-07T17:00:17-04 (for the -04)
		});
	}

	updateStatusServiceOrder(status: Status) { console.log(this.serviceOrder.serviceRequestsLength)
		if (status.id !== this.serviceOrder.statusServiceOrder.id) {
			this.serviceOrder.statusServiceOrder = status;

			this.requestServiceService.updateStatusServiceOrder(this.serviceOrder).subscribe(serviceOrder => {
				if (serviceOrder.statusServiceOrder.id === 'so_revision') {
					this.router.navigate([`./dashboard/serviceOrders/${serviceOrder.id}`]);
				} else {
					this.serviceOrder = serviceOrder;
					this.serviceOrder.createdAt = moment(this.serviceOrder.createdAt).toISOString().toString(); // the angular pipe failed with the raw date, ie: 2018-11-07T17:00:17-04 (for the -04)
					// this.notificationService.sucessUpdate(this.serviceRequest.id.toString());
				}
			}, err => {
				this.notificationService.error(err);
				this.read(this.serviceOrder.id);
			});
		}
	}

	delete() {
		this.requestServiceService.delete(this.serviceOrder.id).subscribe(data => {
			this.notificationService.sucessDelete();
			this.location.back();
		}, err => this.notificationService.error(err));
	}

	so_draft() {
		return this.serviceOrder.statusServiceOrder.id === 'so_draft' ||
			this.serviceOrder.statusServiceOrder.id === 'so_approved' ||
			this.serviceOrder.statusServiceOrder.id === 'so_rejected';
	}

	so_requested(type: string) {
		if (type === 'selector') {
			return this.serviceOrder.statusServiceOrder.id === 'so_requested' ||
				this.serviceOrder.statusServiceOrder.id === 'so_draft'
		} else if (type === 'button') {
			return this.serviceOrder.statusServiceOrder.id === 'so_draft';
		}
	}

	so_revision(type: string) {
		if (type === 'selector') {
			return this.serviceOrder.statusServiceOrder.id === 'so_revision' ||
				this.serviceOrder.statusServiceOrder.id === 'so_requested'
		} else if (type === 'button') {
			return this.serviceOrder.statusServiceOrder.id === 'so_requested';
		}
	}

	so_rejected(type: string) {
		if (type === 'selector') {
			return this.serviceOrder.statusServiceOrder.id === 'so_rejected' ||
				this.serviceOrder.statusServiceOrder.id === 'so_requested'
		} else if (type === 'button') {
			return this.serviceOrder.statusServiceOrder.id === 'so_requested';
		}
	}
}
