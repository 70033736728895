import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseService } from 'app/core/base.service';
import { ServiceType } from './serviceType';
import { CustomValidators } from 'app/shared/form/custom.validators';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ServiceTypeService {
	private static readonly BASE_URL: string = BaseService.HOST + '/serviceTypes';

	constructor(
		private http: HttpClient,
		private fb: FormBuilder
	) {}

	getById(id: number): Observable<ServiceType> {
		return this.http.get(ServiceTypeService.BASE_URL + '/' + id)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	getAll(params?: HttpParams): Observable<any> {
		if (params) {
			return this.http.get(ServiceTypeService.BASE_URL, {params: params})
				.pipe(catchError(BaseService.handleError));
		} else {
			return this.http.get(ServiceTypeService.BASE_URL)
				.pipe(catchError(BaseService.handleError));
		}
	}

	create(serviceType: ServiceType): Observable<ServiceType> {
		return this.http.post(ServiceTypeService.BASE_URL, serviceType)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	update(serviceType: ServiceType): Observable<ServiceType> {
		return this.http.put(ServiceTypeService.BASE_URL + '/' + serviceType.id, serviceType)
			.pipe(
				map(BaseService.extractData),
				catchError(BaseService.handleError)
			);
	}

	delete(id: number): Observable<any> {
		return this.http.delete(ServiceTypeService.BASE_URL + '/' + id)
			.pipe(catchError(BaseService.handleError));
	}

	deletes(serviceTypes: ServiceType[]): Observable<any> {
		return this.http.post(ServiceTypeService.BASE_URL + '/delete', serviceTypes)
			.pipe(catchError(BaseService.handleError));
	}

	toFormGroup(serviceType: ServiceType): FormGroup {
		let machineTypes;
		if (serviceType.machineTypes) {
			machineTypes = serviceType.machineTypes.map(machineType => { return {id: machineType.id}; });
		}

		return this.fb.group({
			id: new FormControl(serviceType.id),
			name: new FormControl(serviceType.name, [Validators.required, Validators.maxLength(100)]),
			workedTime: new FormControl(serviceType.workedTime, [CustomValidators.integerRegex, CustomValidators.min(0)]),
			price: new FormControl(serviceType.price, [CustomValidators.numberRegex, CustomValidators.min(0)]),
			description: new FormControl(serviceType.description),
			machineTypes: new FormControl(machineTypes, Validators.required)
		});
	}
}
