import { Directive, ElementRef, Input, TemplateRef/*, OnChanges, SimpleChanges*/ } from '@angular/core';

@Directive({
	selector: '[dropdownOption]'
})
export class DropdownOptionDirective /*implements OnChanges*/ {
	@Input() value: any;
	//@Input() selected: boolean;

	constructor(public template: TemplateRef<any>, public elementRef: ElementRef) {
	}

	/*ngOnChanges(changes: SimpleChanges) {
		console.log(changes);
	}*/
}
