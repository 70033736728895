import { BaseModel } from 'app/core/models/base.model';
import { ServiceRequest } from '../serviceRequest/serviceRequest';
import { ServiceRecord } from '../serviceRecord/serviceRecord';
import { UserEmployee } from '../userEmployee/userEmployee';
import { Status } from '../status/status';
import { Media } from '../media/media';

export class ServiceTask extends BaseModel {
	price: number;
	startDateTime: string;
	observation: string;
	serviceRequest: ServiceRequest;
	serviceRecord: ServiceRecord;
	userEmployee: UserEmployee;
	statusServiceTask: Status;
	medias: Media[];
}
