import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ServiceOrder } from './serviceOrder';
import { ServiceOrderService } from './serviceOrder.service';
import { Media } from 'app/media/media';
import { NotificationService} from 'app/shared/notification/notification.service';
import { MediaService } from 'app/media/media.service';
import { Status } from 'app/status/status';
import * as moment from 'moment';

@Component({
	template: `
		<h3 class="title" i18n="@@detail-serviceOrder">Service Order Detail</h3>

		<ng-container *ngIf="serviceOrder">
			<div class="tool-bar-wrap both-side">
				<div class="right row">
					<ng-container *ngIf="serviceOrder.statusServiceOrder.id === 'so_closed' && !serviceOrder.mediaSignature">
						<ng-template [hasPermission]="['MediaController.createServiceOrderSignature']">
							<input id="file-upload" type="file" accept="image/png, image/jpeg" (change)="updateImage($event)">
							<label class="btn-file" for="file-upload">
								<i class="material-icons">add_photo_alternate</i>
							</label>
						</ng-template>
					</ng-container>

					<ng-template [hasPermission]="['MediaController.delete']">
						<button *ngIf="serviceOrder.mediaSignature" class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDeleteMediaSignature = false">
							<i class="material-icons">delete</i>
							<span i18n="@signature">Signature</span>
						</button>
					</ng-template>

					<ng-template [hasPermission]="['ServiceOrders.delete']">
						<button class="btn-icon" i18n-title="@@option-delete" title="Delete" type="button" (click)="confirmDelete = false">
							<i class="material-icons">delete</i>
						</button>
					</ng-template>
				</div>
			</div>

			<div class="fieldset-wrap" style="margin-bottom: 30px;">
				<div class="fieldset both-side">
					<div>
						<div class="legend" i18n="@@data-serviceOrder">Service Order data</div>
						<div class="field-row">
							<div class="field-row-item">
								<span class="label" i18n="@@id">Id</span>
								<span>{{serviceOrder.id || '-'}}</span>
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<span class="label" i18n="@@type">Type</span>
								<span>{{serviceOrder.dtype || '-'}}</span>
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<span class="label" i18n="@@company">Company</span>
								<span>{{serviceOrder.store?.company?.name || '-'}}</span>
							</div>
							<div class="field-row-item">
								<span class="label" i18n="@@store">Branch</span>
								<span>{{serviceOrder.store?.name || '-'}}</span>
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<span class="label" i18n="@@createdAt">Created date</span>
								<span>{{serviceOrder.createdAt | date:'medium' || '-'}}</span>
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<span class="label" i18n="@@progress">Progress</span>
								<span *ngIf="serviceOrder.progress != null && serviceOrder.progress >= 0 else noProgress">{{serviceOrder.progress}} %</span>
								<ng-template #noProgress>
									<span i18n="@@nothing-approved">Nothing approved</span>
								</ng-template>
							</div>
						</div>
						<div class="field-row">
							<div class="field-row-item">
								<span class="label" i18n="@@description">Description</span>
								<span>{{serviceOrder.description || '-'}}</span>
							</div>
						</div>
					</div>

					<div class="right row" *hasPermission="['ServiceOrders.updateStatusServiceOrder']">
						<dropdown-ns [value]="{id: serviceOrder.statusServiceOrder.id}" (changed)="updateStatusServiceOrder($event)">
							<ng-template *ngIf="so_requested()" dropdownOption [value]="{id: 'so_requested'}">
								<span i18n="@@so_requested">Requested</span>
							</ng-template>
							<ng-template *ngIf="so_revision()" dropdownOption [value]="{id: 'so_revision'}">
								<span i18n="@@so_revision">Revision</span>
							</ng-template>
							<ng-template *ngIf="so_approved('selector')" dropdownOption [value]="{id: 'so_approved'}">
								<span i18n="@@so_approved">Approved</span>
							</ng-template>
							<ng-template *ngIf="so_closed('selector')" dropdownOption [value]="{id: 'so_closed'}">
								<span i18n="@@so_closed">Closed</span>
							</ng-template>
							<ng-template *ngIf="so_cancelled()" dropdownOption [value]="{id: 'so_cancelled'}">
								<span i18n="@@so_cancelled">Cancelled</span>
							</ng-template>
						</dropdown-ns>

						<button *ngIf="so_approved('button')"
							class="btn-status approved"
							(click)="updateStatusServiceOrder({id: 'so_approved'})"
							i18n="@@so_approve">Approve</button>
						<button *ngIf="so_closed('button')"
							class="btn-status closed"
							(click)="updateStatusServiceOrder({id: 'so_closed'})"
							i18n="@@so_close">Close</button>
					</div>
				</div>

				<div class="fieldset" *ngIf="serviceOrder.mediaSignature">
					<div class="legend" i18n="@@signature-data">Signature data</div>
					<div class="field-row">
						<div class="field-row-item">
							<span class="label" i18n="@@author">Author</span>
							<span>{{serviceOrder.mediaSignature?.name || '-'}}</span>
						</div>
					</div>
					<div class="field-row">
						<gallery-image-preview-unique [item]="serviceOrder.mediaSignature"></gallery-image-preview-unique>
					</div>
				</div>
			</div>

			<serviceRequest-list
				[serviceOrder]="serviceOrder"
				(readServiceOrder)="read()">
			</serviceRequest-list>
		</ng-container>

		<modal-ns [(closed)]="confirmDelete">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">
						Are you sure you want to delete the record?
					</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDelete = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="delete(); confirmDelete = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>

		<modal-ns [(closed)]="confirmDeleteMediaSignature">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@confirm">Confirmation</div>
					<div class="dialog-message" i18n="@@confirm-delete">
						Are you sure you want to delete the record?
					</div>
					<div class="options">
						<button class="btn-text red" type="button" (click)="confirmDeleteMediaSignature = true">
							<span i18n="@@option-no">No</span>
						</button>
						<button class="btn-text green" type="button" (click)="deleteMediaSignature(); confirmDeleteMediaSignature = true">
							<span i18n="@@option-yes">Yes</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>

		<modal-ns [(closed)]="invalidDataCancelled">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@error-invalid">Invalid data</div>
					<div class="dialog-message" i18n="@@error-required-message">
						To cancell the order there must be no approved or done assignments by the technicians.
					</div>
					<div class="options">
						<button class="btn-text green" type="button" (click)="invalidDataCancelled = true">
							<span i18n="@@option-understood">Understood</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>

		<modal-ns [(closed)]="invalidDataClosed">
			<ng-template modalContentDirective>
				<div class="dialog-content">
					<div class="dialog-title" i18n="@@error-invalid">Invalid data</div>
					<div class="dialog-message" i18n="@@error-required-message">
						To cancell the order there must be no approved or done assignments by the technicians.
					</div>
					<div class="options">
						<button class="btn-text green" type="button" (click)="invalidDataClosed = true">
							<span i18n="@@option-understood">Understood</span>
						</button>
					</div>
				</div>
			</ng-template>
		</modal-ns>
	`,
	providers: [
		MediaService
	]
})
export class ServiceOrderReadComponent implements OnInit {
	confirmDelete = true;
	confirmDeleteMediaSignature = true;
	viewImage = true;
	serviceOrder: ServiceOrder;
	invalidDataCancelled = true;
	invalidDataClosed = true;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private location: Location,
		private serviceOrderService: ServiceOrderService,
		private mediaService: MediaService,
		private notificationService: NotificationService
	) {}

	ngOnInit() {
		this.activatedRoute.params.subscribe(params => {
			this.read(params['serviceOrderId']);
		});
	}

	read(id: number = this.serviceOrder.id) {
		this.serviceOrderService.getById(id).subscribe(serviceOrder => {
			this.serviceOrder = serviceOrder;
			this.serviceOrder.createdAt = moment(this.serviceOrder.createdAt).toISOString().toString(); // the angular pipe failed with the raw date, ie: 2018-11-07T17:00:17-04 (for the -04)
		});
	}

	updateStatusServiceOrder(status: Status) {
		if (status.id !== this.serviceOrder.statusServiceOrder.id) {
			switch (status.id) {
				case 'so_revision':
				case 'so_cancelled':
					if (this.serviceOrder.serviceTasksApprovedLength > 0 ||
						this.serviceOrder.serviceTasksDoneLength > 0) {
						this.invalidDataCancelled = false;
						return;
					}
					break;
				case 'so_closed':
					if (this.serviceOrder.serviceTasksApprovedLength > 0) {
						this.invalidDataClosed = false;
						return;
					}
					break;
			}

			this.serviceOrder.statusServiceOrder = status;

			this.serviceOrderService.updateStatusServiceOrder(this.serviceOrder).subscribe(serviceOrder => {
				if (serviceOrder.statusServiceOrder.id === 'so_requested') {
					this.router.navigate([`./dashboard/requestServices/${serviceOrder.id}`]);
				} else {
					this.serviceOrder = serviceOrder;
					this.serviceOrder.createdAt = moment(this.serviceOrder.createdAt).toISOString().toString(); // the angular pipe failed with the raw date, ie: 2018-11-07T17:00:17-04 (for the -04)
					// this.notificationService.sucessUpdate(this.serviceRequest.id.toString());
				}
			}, err => {
				this.notificationService.error(err);
				this.read(this.serviceOrder.id);
			});
		}
	}

	updateImage(event) {
		if (event.target.files && event.target.files.length > 0) {
			let file: File = event.target.files.item(0);

			MediaService.getBase64(file).then(data => {
				let media = new Media();
				media.name = file.name.substring(0, file.name.lastIndexOf('.'));
				media.multimediaCDN.mediaBase64 = data.toString();

				this.serviceOrderService.createMedia(this.serviceOrder.id, media).subscribe(mediaSignature => {
					this.notificationService.sucessInsert();
					this.serviceOrder.mediaSignature = mediaSignature;
				}, err => this.notificationService.error(err));
			});
		}
	}

	delete() {
		this.serviceOrderService.delete(this.serviceOrder.id).subscribe(data => {
			this.notificationService.sucessDelete();
			let url = this.location.path();
			this.router.navigate([url.substring(0, url.lastIndexOf('/'))]);
		}, err => this.notificationService.error(err));
	}

	deleteMediaSignature() {
		this.mediaService.delete(this.serviceOrder.mediaSignature.id).subscribe(data => {
			this.notificationService.sucessDelete();
			delete this.serviceOrder.mediaSignature;
		}, err => this.notificationService.error(err));
	}

	so_requested() {
		return this.serviceOrder.statusServiceOrder.id === 'so_requested' ||
			this.serviceOrder.statusServiceOrder.id === 'so_revision';
	}

	so_revision() {
		return this.serviceOrder.statusServiceOrder.id === 'so_revision' ||
			this.serviceOrder.statusServiceOrder.id === 'so_approved' ||
			this.serviceOrder.statusServiceOrder.id === 'so_cancelled';
	}

	so_approved(type: string) {
		if (type === 'selector') {
			return this.serviceOrder.statusServiceOrder.id === 'so_revision' ||
				this.serviceOrder.statusServiceOrder.id === 'so_approved' ||
				this.serviceOrder.statusServiceOrder.id === 'so_closed';
		} else if (type === 'button') {
			return this.serviceOrder.statusServiceOrder.id === 'so_revision';
		}
	}

	so_cancelled() {
		return this.serviceOrder.statusServiceOrder.id === 'so_cancelled' || this.serviceOrder.statusServiceOrder.id === 'so_approved';
	}

	so_closed(type: string) {
		if (type === 'selector') {
			return this.serviceOrder.statusServiceOrder.id === 'so_closed' || this.serviceOrder.statusServiceOrder.id === 'so_approved';
		} else if (type === 'button') {
			return this.serviceOrder.statusServiceOrder.id === 'so_approved';
		}
	}
}
